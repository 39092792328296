/* eslint-disable import/no-extraneous-dependencies */
import React, { useMemo } from 'react';
import { Marker } from '@react-google-maps/api';
import { Clusterer } from '@react-google-maps/marker-clusterer';
import Model from '@tripian/model';

interface IGMarkerProviderVideoBase {
  coordinate: Model.Coordinate;
  animation?: 1 | 2;
  clusterer?: Clusterer;
  color?: string;
  markerTourClicked: () => void;
}

const GMarkerProviderVideoBase: React.FC<IGMarkerProviderVideoBase> = ({ coordinate, animation, clusterer, color, markerTourClicked }) => {
  const svgIcon = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 88 88" width="88" height="88">
    <path
      d="M44 4.6q0.1 0 0.3 0c2.6 0 5.4 0.3 7.8 1.2q0.2 0.1 0.3 0.1 0.3 0.1 0.7 0.3c0.1 0 0.1 0 0.2 0.1 3.2 1.2 5.8 3 8.2 5.3q0.2 0.2 0.4 0.4 1 1 1.8 2.1 0.1 0.1 0.1 0.2c3 3.8 4.8 8.4 5.3 13.2q0 0.1 0 0.2c0.8 6.5-1.8 12.9-5.1 18.3q0 0.1-0.1 0.2-0.3 0.5-0.6 1-0.2 0.3-0.4 0.6-1 1.6-2.1 3.2-0.2 0.1-0.3 0.3-0.6 0.8-1.2 1.6-0.4 0.6-0.8 1.1-0.1 0.1-0.1 0.2-0.4 0.5-0.7 0.9-0.5 0.8-1.1 1.5-0.2 0.2-0.4 0.5c-1.6 1.9-3.2 3.8-5.1 5.4q-0.9 0.8-1.7 1.8-0.3 0.3-0.6 0.6-0.4 0.4-0.8 0.9-0.5 0.5-0.9 0.9-0.3 0.4-0.7 0.8c-0.5 0.6-1.4 1.4-2.1 1.5-0.8 0.1-1.2-0.3-1.8-0.7q-0.4-0.4-0.8-0.8-0.4-0.5-0.8-0.9-0.5-0.5-1-1.1-0.1-0.1-0.2-0.2-0.4-0.4-0.8-0.8-0.8-0.9-1.6-1.7-0.4-0.4-0.8-0.8-0.4-0.5-0.8-0.9-0.3-0.2-0.5-0.4-0.1-0.2-0.2-0.3-0.8-0.8-1.5-1.6-0.2-0.3-0.5-0.6-0.1-0.2-0.3-0.3c0-0.1 0-0.1-0.1-0.2q-1.5-1.7-2.8-3.5-0.5-0.7-1-1.3-1.1-1.5-2.1-3-0.2-0.3-0.4-0.7-1.9-2.7-3.4-5.6-0.1-0.2-0.2-0.3-1.1-2.1-2-4.4 0-0.1-0.1-0.2-0.3-0.7-0.5-1.4 0-0.1 0-0.2c-0.4-0.9-0.5-1.9-0.7-2.8q0-0.1 0-0.2c-0.3-1.4-0.3-2.6-0.3-4q0-0.4 0-0.8c0-0.9 0-1.9 0.2-2.8q0-0.1 0-0.2 0.1-0.6 0.2-1.2 0-0.1 0-0.2c0.2-0.9 0.4-1.8 0.7-2.6q0.1-0.2 0.1-0.4c0.7-2.2 1.8-4.2 3.1-6.1q0.1-0.1 0.2-0.3c1.2-1.9 2.7-3.6 4.5-5q0.3-0.3 0.5-0.5c3.1-2.7 7.5-4.6 11.5-5.2q0.3 0 0.5 0c1.1-0.2 2.2-0.2 3.4-0.2z"
      fill="#202020"
    />
    <path
      d="m49.9 8.6c0.1 0 0.1 0 0.1 0 2.1 0.8 3.8 1.7 5.5 3.2q0.1 0.1 0.2 0.1c2.2 1.9 4 4.4 5 7.1 0 0.1 0 0.1 0.1 0.2 0.2 0.7 0.4 1.5 0.6 2.2q0.1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0 0.1 0.1 0.3c0.2 1.8 0.2 3.8-0.1 5.7 0 0.1 0 0.1 0 0.2-0.5 2.9-1.8 5.7-3.7 8q0 0-0.1 0.1c-2.5 3-6.2 5.2-10 6.1q-0.1 0-0.2 0.1c-4.2 0.9-9-0.1-12.5-2.4-2-1.2-3.9-2.8-5.3-4.7q0-0.1-0.1-0.2c-1.2-1.7-2-3.6-2.6-5.7q-0.1-0.1-0.1-0.2c-1.3-4.3-0.3-9.1 1.8-12.9 0.6-1.1 0.6-1.1 1-1.5 0.1-0.2 0.1-0.2 0.3-0.4 0.9-1.3 2.1-2.5 3.4-3.4q0.2-0.2 0.4-0.3c0.6-0.6 1.3-1 2.1-1.4q0.1 0 0.2-0.1c4.2-2.1 9.2-2.5 13.7-0.9z"
      fill="#1f1f1f"
    />
    <path
      d="m49.9 8.6c0.1 0 0.1 0 0.1 0 2.1 0.8 3.8 1.7 5.5 3.2q0.1 0.1 0.2 0.1c2.2 1.9 4 4.4 5 7.1 0 0.1 0 0.1 0.1 0.2 0.2 0.7 0.4 1.5 0.6 2.2q0.1 0.1 0.1 0.2 0.1 0.3 0.1 0.6 0 0.1 0.1 0.3c0.2 1.8 0.2 3.8-0.1 5.7 0 0.1 0 0.1 0 0.2-0.5 2.9-1.8 5.7-3.7 8q0 0-0.1 0.1c-2.5 3-6.2 5.2-10 6.1q-0.1 0-0.2 0.1c-4.2 0.9-9-0.1-12.5-2.4-2-1.2-3.9-2.8-5.3-4.7q0-0.1-0.1-0.2c-1.2-1.7-2-3.6-2.6-5.7q-0.1-0.1-0.1-0.2c-1.3-4.3-0.3-9.1 1.8-12.9 0.6-1.1 0.6-1.1 1-1.5 0.1-0.2 0.1-0.2 0.3-0.4 0.9-1.3 2.1-2.5 3.4-3.4q0.2-0.2 0.4-0.3c0.6-0.6 1.3-1 2.1-1.4q0.1 0 0.2-0.1c4.2-2.1 9.2-2.5 13.7-0.9zm-14.8 5.5q-0.2 0.1-0.3 0.2-0.4 0.4-0.8 0.7 0 0.1-0.1 0.2c-2.5 2.4-4.3 5.8-4.4 9.3q0 0.5 0 1 0 0.1 0 0.2c0 1.1 0.1 2.1 0.4 3.2q0 0.1 0.1 0.2c0.6 2.3 1.8 4.2 3.3 6q0.2 0.2 0.3 0.4c0.5 0.5 0.9 0.9 1.5 1.3q0.1 0.1 0.2 0.2c1 0.7 2 1.2 3.1 1.7 0.1 0.1 0.1 0.1 0.2 0.1 0.8 0.4 1.6 0.6 2.5 0.8q0.1 0.1 0.3 0.1c3.6 0.8 7.4 0 10.5-1.9 1-0.7 2-1.5 2.8-2.4q0.1-0.1 0.2-0.1c0.4-0.4 0.7-0.8 1-1.2q0-0.1 0.1-0.2 0.4-0.6 0.8-1.2 0.1-0.2 0.2-0.4c1.8-2.9 2.3-6.7 1.6-9.9-0.9-3.5-3-7-6-9.1q-0.1 0-0.2-0.1c-1.7-1.2-3.7-1.8-5.8-2.3q-0.2 0-0.3-0.1c-3.9-0.6-8.3 0.7-11.2 3.3z"
      fill="#f8f8f9"
    />
    <path
      d="m41.2 16.3q0.7 0.4 1.4 0.8 1.2 0.6 2.2 1.3 0.8 0.5 1.5 0.9 0.9 0.5 1.8 1.1 0.6 0.4 1.2 0.8 0.5 0.3 1 0.6 0.1 0.1 0.2 0.2 0.7 0.4 1.4 0.9 0.3 0.2 0.7 0.5c1.7 1.1 1.7 1.1 1.9 2.1 0.1 0.6 0 1.2-0.3 1.8-0.4 0.3-0.8 0.5-1.2 0.8q-0.5 0.3-1 0.6-0.6 0.3-1.1 0.6-0.6 0.4-1.1 0.7-0.4 0.2-0.8 0.4c-1.5 1-1.5 1-3.1 1.9q-0.8 0.6-1.7 1.1-1 0.5-1.9 1.1-0.1 0.1-0.2 0.2-0.2 0.1-0.3 0.2c-2.1 1.3-2.1 1.3-3.1 1.2-0.8-0.2-1.3-0.5-1.7-1.2-0.2-0.5-0.2-0.9-0.2-1.4q0-0.1 0-0.2 0-0.4 0-0.7 0-0.3 0-0.5 0-0.8 0-1.7 0-0.2 0-0.5 0-1.4 0-2.7 0-1.5-0.1-3.1 0-1.2 0-2.3 0-0.8 0-1.5 0-0.6 0-1.3 0-0.2 0-0.5c0-1.7 0-1.7 0.6-2.4 1.3-1.1 2.6-0.7 3.9 0.2z"
      fill="#fafafb"
    />
    <path
      d="m40.7 19.9c0.2 0.1 0.2 0.1 0.4 0.2 0.1 0.1 0.1 0.1 0.3 0.1q0.1 0.1 0.2 0.2 0.1 0 0.3 0.1 1.1 0.6 2.3 1.3 0.8 0.5 1.6 1 1 0.5 1.9 1.1 0.7 0.4 1.3 0.8c0.4 0.2 0.6 0.3 0.8 0.7 0 0.3 0 0.3-0.1 0.6-0.5 0.4-1 0.7-1.5 1q-0.2 0.1-0.4 0.2c-0.1 0.1-0.1 0.1-0.2 0.1-1.6 0.8-3.1 1.8-4.5 2.8q-0.9 0.6-1.7 1.1-0.1 0.1-0.2 0.1c-0.7 0.5-0.7 0.5-1.2 0.5-0.2-0.5-0.2-1-0.2-1.6q0-0.2 0-0.3 0-0.5 0-0.9 0-0.3 0-0.5 0-1 0-2 0-0.9 0-1.8 0-0.7 0-1.5 0-0.5 0-0.9 0-0.5 0-1.1 0-0.1 0-0.3c0-0.6 0-0.6 0.3-0.9 0.3-0.2 0.4-0.2 0.6-0.1z"
      fill="#1e1f1f"
    />
  </svg>`;

  const svgDataUri = useMemo(() => `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(svgIcon)}`, [svgIcon]);
  const markerIcon = useMemo(
    () => ({
      url: svgDataUri,
      scaledSize: new window.google.maps.Size(50, 66),
    }),
    [svgDataUri],
  );

  return (
    <Marker
      position={coordinate}
      icon={markerIcon}
      onClick={() => {
        markerTourClicked();
      }}
      animation={animation}
      clusterer={clusterer}
    />
  );
};

export default GMarkerProviderVideoBase;
