import React from "react";
import Model, { Providers } from "@tripian/model";
import moment from "moment";
import { Button } from "@tripian/react";
import classes from "./ViatorTourOption.module.scss";

interface IViatorTourOption {
  tourOption?: Providers.Viator.AvailabilityBookableItem;
  travelDate?: string;
  currency?: string;
  bookingRequestCallback: (productOption: Providers.Viator.AvailabilityBookableItem) => void;
  t: (value: Model.TranslationKey) => string;
}

const ViatorTourOption: React.FC<IViatorTourOption> = ({ tourOption, travelDate, currency, bookingRequestCallback, t }) => {
  moment.locale(window.twindow.langCode);

  const addToCart = () => {
    if (tourOption) bookingRequestCallback(tourOption);
  };

  return (
    <div className={classes.mainDiv}>
      {tourOption?.available === true ? (
        <div>
          <div className="p-4">
            <div className={classes.startTime}>{moment(travelDate).format("dddd, ll")}</div>
            <div className={classes.timeOptions}>{tourOption?.startTime}</div>
            <div>
              <div className={classes.priceBreakdown}>{t("trips.myTrips.localExperiences.tourDetails.priceBreakdown")}</div>

              <div>
                <div>
                  {tourOption?.lineItems.map((i, index) => (
                    <div key={index}>
                      <span style={{ textTransform: "capitalize" }}>{i.ageBand}</span> <span>{i.numberOfTravelers}</span> × <span>{i.subtotalPrice.price.partnerNetPrice}</span>{" "}
                      <span>{currency}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.gygTourOptionsBottom}>
            <div className={classes.totalPrice}>
              <div className={classes.totalPriceText}>{t("trips.myTrips.localExperiences.tourDetails.totalPrice")}</div>
              <div>
                <span className={classes.gygTourOptionsBottomPrice}>{tourOption?.totalPrice.price.partnerTotalPrice}</span>&nbsp;
                <span className={classes.gygTourOptionsBottomPrice}>{currency}</span>
              </div>
            </div>
            <Button /* disabled={isButtonDisabled} */ className={classes.addToCartButton} onClick={addToCart} text={t("trips.myTrips.localExperiences.tourDetails.bookNow")} />
          </div>
        </div>
      ) : (
        <div>
          <div className="p-4">
            <div>{tourOption?.unavailableReason}</div>
            <div className={classes.startTime}>{moment(travelDate).format("dddd, ll")}</div>
            <div className={classes.timeOptions}>{tourOption?.startTime}</div>
            <div>
              <div className={classes.priceBreakdown}>{"trips.myTrips.localExperiences.tourDetails.priceBreakdown"}</div>

              <div>
                <div>
                  {tourOption?.lineItems.map((i, index) => (
                    <div key={index}>
                      <span style={{ textTransform: "capitalize" }}>{i.ageBand}</span> <span>{i.numberOfTravelers}</span> × <span>{i.subtotalPrice.price.partnerNetPrice}</span>{" "}
                      <span>{currency}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className={classes.gygTourOptionsBottom}>
            <div className={classes.totalPrice}>
              <div className={classes.totalPriceText}>{t("trips.myTrips.localExperiences.tourDetails.totalPrice")}</div>
              <div>
                <span className={classes.gygTourOptionsBottomPrice}>{tourOption?.totalPrice.price.partnerTotalPrice}</span>&nbsp;
                <span className={classes.gygTourOptionsBottomPrice}>{currency}</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ViatorTourOption;
