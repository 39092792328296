/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/brace-style */
import { Providers } from '@tripian/model';
import { AxiosError } from 'axios';

// interface GygErrorResponse {
//   apiVersion: string;
//   date: string;
//   descriptor: string;
//   errors: {
//     errorCode: number;
//     errorMessage: string;
//   }[];
//   helpURL: string;
//   method: string;
//   query: string;
//   status: string;
// }

// {
//   "id": 1,
//   "functionName": "gygFetchTourError",
//   "title": "Get Your Guide Fetch Tour",
//   "message": {
//     "descriptor": "GetYourGuide AG",
//     "apiVersion": "1",
//     "method": "getTourByIdAction",
//     "date": "2020-10-22T18:30:49Z",
//     "status": "ERROR",
//     "query": "cnt_language=en&currency=usd&preformatted=full",
//     "errors": [
//       {
//         "errorCode": 995,
//         "errorMessage": "Tour not found"
//       }
//     ],
//     "helpURL": "https://api.getyourguide.com/doc"
//   },
//   "hide": false
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleHttpResponseErrorForGyg = (errorResponse: AxiosError<Providers.Gyg.Error>, dataKey: string, params?: any) => {
  let result: any = '';

  // Has Response
  if (errorResponse.response) {
    // console.log('Has Response');

    // Has Response Data
    if (errorResponse.response.data) {
      // console.log('Has Response and Data');

      // Has Gyg API error data
      if (errorResponse.response.data.originalResponse.errors.length > 0) {
        const errorGyg = errorResponse.response.data.originalResponse.errors[0];
        console.log('errorGyg', errorGyg);
        // console.log('Has Response and Data and Gyg API error data');
        result = errorGyg.errorMessage;
      }
      // Gyg API error data doens't match with GygErrorResponse
      else {
        // console.log('Has Response and Data but data doenst match with GygErrorResponse data');
        result = errorResponse.response.data.error;
      }
    }
    // Has Not Response Data
    else {
      // console.log('Has Not Response Data');
      result = errorResponse.response;
    }

    // Has Not Response
    // HTTP error handle
  } else if (errorResponse.message) {
    console.log('Has Not Response. Has HTTP error message');
    result = errorResponse.message;
  } else {
    console.log('Has Not Response. Has not HTTP error message');
    result = errorResponse;
  }

  // console.log('Return result');
  throw JSON.stringify(result);
};

// eslint-disable-next-line import/prefer-default-export
export { handleHttpResponseErrorForGyg };
