import React from 'react';
import Svg from '../Svg';

interface ILandingThumbsUp {
  fill?: string;
  color?: string;
  size?: string;
}

const LandingThumbsUp: React.FC<ILandingThumbsUp> = ({ fill, color, size = '32px' }) => (
  <Svg
    fill={fill}
    color={color}
    size={size}
    path="M 2.92,10.4995 Q 2.92,10.2965 2.77744,10.148 2.63488,9.9995 2.44,9.9995 2.23744,9.9995 2.09872,10.148 1.96,10.2965 1.96,10.4995 q 0,0.211 0.13872,0.3555 0.13872,0.1445 0.34128,0.1445 0.19488,0 0.33744,-0.1445 Q 2.92,10.7105 2.92,10.4995 z m 1.2,-4 v 5 q 0,0.203 -0.14256,0.3515 Q 3.83488,11.9995 3.64,11.9995 H 1.48 q -0.19488,0 -0.33744,-0.1485 Q 1,11.7025 1,11.4995 v -5 Q 1,6.2965 1.14256,6.148 1.28512,5.9995 1.48,5.9995 h 2.16 q 0.19488,0 0.33744,0.1485 Q 4.12,6.2965 4.12,6.4995 z m 8.88,0 q 0,0.672 -0.41232,1.164 0.11232,0.344 0.11232,0.594 0.02256,0.594 -0.32256,1.0705 0.12768,0.4375 0,0.914 -0.11232,0.4455 -0.40512,0.7345 0.06768,0.875 -0.36768,1.414 -0.48,0.594 -1.47744,0.6095 H 9.15952 Q 8.66464,13 8.07952,12.879 7.4944,12.758 7.16848,12.6525 6.84256,12.547 6.26464,12.344 5.34208,12.008 5.07952,12 4.88464,11.992 4.74208,11.8475 4.59952,11.703 4.59952,11.5 V 6.492 q 0,-0.1955 0.13488,-0.34 Q 4.86928,6.0075 5.05696,5.992 5.23696,5.9765 5.6272,5.531 6.01744,5.0855 6.38464,4.5855 6.8944,3.906 7.14208,3.648 7.27696,3.5075 7.3744,3.273 7.47184,3.0385 7.50544,2.894 7.53904,2.7495 7.60672,2.4215 7.65904,2.117 7.70032,1.945 7.7416,1.773 7.84672,1.539 7.95184,1.305 8.1016,1.1485 8.24416,1 8.43904,1 8.78416,1 9.05776,1.082 9.33136,1.164 9.508,1.285 q 0.17664,0.121 0.3,0.3165 0.12336,0.1955 0.18,0.3515 0.05664,0.156 0.08976,0.3905 0.03312,0.2345 0.03744,0.3515 0.0043,0.117 0.0038,0.3045 0,0.297 -0.07104,0.594 -0.071,0.297 -0.14252,0.469 Q 9.83392,4.2345 9.69904,4.5 9.67648,4.547 9.62416,4.6405 9.57184,4.734 9.5416,4.8125 9.51136,4.891 9.4816,5 h 2.07744 q 0.58512,0 1.01232,0.4455 0.4272,0.4455 0.42768,1.0545 z"
    viewBox="0 0 14 14"
  />
);

export default LandingThumbsUp;
