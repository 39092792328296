import React from 'react';
import Svg from '../Svg';

interface IGoogle {
  fill?: string;
  color?: string;
  size?: string;
}

const Google: React.FC<IGoogle> = ({ fill, color, size = '20px' }) => (
  <Svg fill={fill} color={color} size={size} path="" viewBox="0 0 22 22">
    <g clipPath="url(#clip0_205_123)">
      <path
        d="M22 11C22 10.3089 21.9352 9.61713 21.8068 8.94553C21.7488 8.64153 21.4826 8.42183 21.1736 8.42183H11.043C10.6867 8.42183 10.3984 8.71011 10.3984 9.06636V12.9335C10.3984 13.2898 10.6867 13.5781 11.043 13.5781H16.2408C15.9169 14.2337 15.4706 14.8026 14.9465 15.2811L18.5894 18.924C20.6855 16.9271 22 14.1169 22 11Z"
        fill="black"
      />
      <path
        d="M11 5.15625C12.2645 5.15625 13.4674 5.55784 14.4782 6.31817C14.7337 6.51015 15.0944 6.48686 15.321 6.25836L18.0716 3.50775C18.1994 3.37996 18.2679 3.20435 18.2598 3.02371C18.2516 2.84307 18.1685 2.67438 18.03 2.55793C16.0618 0.908273 13.5649 0 11 0C7.22524 0 3.89048 1.91873 1.91911 4.83192L5.70243 8.61523C6.60503 6.58212 8.63582 5.15625 11 5.15625Z"
        fill="black"
      />
      <path d="M11 22C13.4605 22 15.7263 21.175 17.5571 19.8006L13.8459 16.0893C12.9976 16.5669 12.028 16.8438 11 16.8438C8.63582 16.8438 6.60503 15.4179 5.70243 13.3848L1.91911 17.1681C3.89048 20.0813 7.22524 22 11 22Z" fill="black" />
      <path d="M5.25009 11.9283C5.20072 11.6242 5.15625 11.3178 5.15625 11C5.15625 10.6822 5.20072 10.3758 5.25009 10.0717L1.2057 6.02727C0.440516 7.52172 0 9.20889 0 11C0 12.7911 0.440516 14.4783 1.20575 15.9727L5.25009 11.9283Z" fill="black" />
    </g>
    <defs>
      <clipPath id="clip0_205_123">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </Svg>
);

export default Google;
