/* eslint-disable import/prefer-default-export */
/* eslint-disable import/no-extraneous-dependencies */
import Model from '@tripian/model';
import data from '../../../data/data';

const allQuestionsData = (lang: Model.LangCodeKey) => {
  if (!data.questions) data.questions = {} as Record<Model.LangCodeKey, Model.Question[]>;
  if (!data.questionsTrip) data.questionsTrip = {} as Record<Model.LangCodeKey, Model.Question[]>;
  if (!data.questionsProfile) data.questionsProfile = {} as Record<Model.LangCodeKey, Model.Question[]>;
  if (!data.questionsCompanion) data.questionsCompanion = {} as Record<Model.LangCodeKey, Model.Question[]>;

  data.questions[lang] = (data.questionsTrip[lang] || []).concat(data.questionsProfile[lang] || []).concat(data.questionsCompanion[lang] || []);
};

export { allQuestionsData };
