import React from 'react';
import Svg from '../Svg';

interface IThumbsDownEmpty {
  fill?: string;
  bgColor?: string;
  size?: string;
  clicked: (like: number) => void;
}

const ThumbsDownEmpty: React.FC<IThumbsDownEmpty> = ({ fill, bgColor, size, clicked }) => (
  <div
    style={{ width: '100%', outline: '0', textAlign: 'center' }}
    onKeyDown={() => {}}
    role="button"
    tabIndex={0}
    onClick={(event) => {
      event.stopPropagation();
      clicked(-1);
    }}
  >
    <Svg
      fill={fill}
      bgColor={bgColor}
      size={size}
      path="M466.27 225.31c4.674-22.647.864-44.538-8.99-62.99 2.958-23.868-4.021-48.565-17.34-66.99C438.986 39.423 404.117 0 327 0c-7 0-15 .01-22.22.01C201.195.01 168.997 40 128 40h-10.845c-5.64-4.975-13.042-8-21.155-8H32C14.327 32 0 46.327 0 64v240c0 17.673 14.327 32 32 32h64c11.842 0 22.175-6.438 27.708-16h7.052c19.146 16.953 46.013 60.653 68.76 83.4 13.667 13.667 10.153 108.6 71.76 108.6 57.58 0 95.27-31.936 95.27-104.73 0-18.41-3.93-33.73-8.85-46.54h36.48c48.602 0 85.82-41.565 85.82-85.58 0-19.15-4.96-34.99-13.73-49.84zM64 296c-13.255 0-24-10.745-24-24s10.745-24 24-24 24 10.745 24 24-10.745 24-24 24zm330.18 16.73H290.19c0 37.82 28.36 55.37 28.36 94.54 0 23.75 0 56.73-47.27 56.73-18.91-18.91-9.46-66.18-37.82-94.54C206.9 342.89 167.28 272 138.92 272H128V85.83c53.611 0 100.001-37.82 171.64-37.82h37.82c35.512 0 60.82 17.12 53.12 65.9 15.2 8.16 26.5 36.44 13.94 57.57 21.581 20.384 18.699 51.065 5.21 65.62 9.45 0 22.36 18.91 22.27 37.81-.09 18.91-16.71 37.82-37.82 37.82z"
      viewBox="0 0 512 512"
    />
  </div>
);

export default ThumbsDownEmpty;
