/* eslint-disable no-console */
/* eslint-disable import/no-extraneous-dependencies */
import { Providers } from '@tripian/model';
import { AxiosError } from 'axios';

// {
//   "error": "Resource not found or server returned an error",
//   "originalResponse": {
//       "code": "BAD_REQUEST",
//       "message": "Invalid date range: startDate must not be in the past",
//       "timestamp": "2024-09-20T07:48:31.088665043Z",
//       "trackingId": "03FC1231:932C_0A280798:01BB_66ED28CE_A0730B:31F1CB"
//   }
// }

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleHttpResponseErrorForViator = (errorResponse: AxiosError<Providers.Viator.Error>) => {
  let result: any = '';

  // Has Response
  if (errorResponse.response) {
    // Has Response Data
    if (errorResponse.response.data) {
      // Has Gyg API error data
      if (errorResponse.response.data.originalResponse.message) {
        result = errorResponse.response.data.originalResponse.message;
      } else {
        result = errorResponse.response.data.error;
      }
    } else {
      result = errorResponse.response;
    }
  } else if (errorResponse.message) {
    console.log('Has Not Response. Has HTTP error message');
    result = errorResponse.message;
  } else {
    console.log('Has Not Response. Has not HTTP error message');
    result = errorResponse;
  }

  throw JSON.stringify(result);
};

// eslint-disable-next-line import/prefer-default-export
export { handleHttpResponseErrorForViator };
