import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const TapRight: React.FC<IIconSvg> = ({ bgColor, size = '20px' }) => (
  <Svg
    color={bgColor}
    size={size}
    path="M12.225 18.0416H3.51668C2.60001 18.0416 1.7917 17.5666 1.35003 16.7666C0.908364 15.9666 0.933379 15.025 1.41671 14.25L3.72501 10.5583C3.90834 10.2666 3.90834 9.72498 3.72501 9.44165L1.41671 5.74998C0.933379 4.97498 0.908364 4.03331 1.35003 3.23331C1.7917 2.43331 2.60834 1.95831 3.51668 1.95831H13.05C13.8333 1.95831 14.7417 2.45831 15.15 3.12498L18.6334 8.69165C19.1 9.44165 19.0584 10.5916 18.525 11.3L14.2084 17.05C13.7834 17.6166 12.9334 18.0416 12.225 18.0416ZM3.51668 3.20831C3.06668 3.20831 2.65836 3.44165 2.4417 3.84165C2.22503 4.24165 2.23334 4.70831 2.47501 5.09165L4.78336 8.78331C5.21669 9.48331 5.21669 10.5333 4.78336 11.2333L2.47501 14.925C2.23334 15.3083 2.22503 15.775 2.4417 16.175C2.65836 16.575 3.06668 16.8083 3.51668 16.8083H12.225C12.5417 16.8083 13.0167 16.5666 13.2083 16.3166L17.5251 10.5666C17.7501 10.2666 17.7751 9.69165 17.5751 9.37498L14.0917 3.80831C13.9083 3.50831 13.4 3.23331 13.05 3.23331H3.51668V3.20831Z"
    viewBox="0 0 20 20"
  />
);

export default TapRight;
