import React from 'react';
import Svg from '../Svg';
import IIconSvg from './IIconSvg';

const Mask: React.FC<IIconSvg> = ({ fill, bgColor, size = '20px', className }) => (
  <Svg
    className={className}
    fill={fill}
    color={bgColor}
    size={size}
    path="M37.1,23.55c19.38-2.7,29.56-2.64,48.68.08-18.41,3-30.28,3.16-48.68-.08ZM103,11.13c8.29-1.66,14,.74,17.08,5a14.46,14.46,0,0,1,2.66,7,18.54,18.54,0,0,1-.8,7.72c-2.2,7-8.39,13.49-18.26,14.93l-.57-4c8.12-1.18,13.19-6.49,15-12.16a14.54,14.54,0,0,0,.64-6,10.33,10.33,0,0,0-1.89-5c-2.24-3-6.53-4.69-13.05-3.39l-.79-4Zm-84,4c-6.52-1.3-10.81.37-13,3.39a10.33,10.33,0,0,0-1.89,5,14.54,14.54,0,0,0,.64,6c1.79,5.67,6.86,11,15,12.16l-.57,4C9.3,44.25,3.11,37.73.91,30.76A18.54,18.54,0,0,1,.11,23a14.36,14.36,0,0,1,2.66-6.95c3.12-4.22,8.79-6.62,17.08-5l-.79,4Zm4.12,33.3V12a2,2,0,0,1,2-2c6.06-.58,11-2.7,16-4.82C42.93,4.45,44.7,3.69,46.43,3,52.37.77,57.31-.17,62.28,0s9.79,1.5,15.59,3.73c1.83.7,3.67,1.46,5.47,2.22,5.13,2.13,9.93,4.13,14.5,4.49a2,2,0,0,1,1.86,2V48.39a2,2,0,0,1-.42,1.23,32.77,32.77,0,0,1-7.5,7.89,51.51,51.51,0,0,1-26.51,9.91,47.65,47.65,0,0,1-30.18-7.14A45.77,45.77,0,0,1,23.61,49.62a2,2,0,0,1-.42-1.23Zm4-34.56V47.7a42,42,0,0,0,10.06,9.2,43.67,43.67,0,0,0,27.64,6.5,47.45,47.45,0,0,0,24.4-9.07,29.59,29.59,0,0,0,6.34-6.54V14.27c-4.56-.7-9.08-2.59-13.87-4.58C80.07,9,78.3,8.23,76.43,7.52,71,5.44,66.54,4.22,62.13,4.05s-8.84.69-14.26,2.75c-1.8.69-3.46,1.4-5.13,2.11C38,11,33.15,13,27.22,13.83ZM38.69,43.61c12.8,5.89,31,5.87,45.5-.2-10.57,9.1-28.06,15.14-45.5.2ZM32.12,32a214.06,214.06,0,0,0,58.64,0,56.33,56.33,0,0,1-13.45,5c-15,3.67-31.62,2.79-45.19-5Z"
    viewBox="0 0 122.88 67.62"
  />
);

export default Mask;
