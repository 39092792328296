// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
import moment from 'moment';

class Random {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private constructor() {}

  /**
   * random object in array
   * @param {array} any array
   * @returns {object} random array item
   */
  static random = <T>(array: Array<T>): T => {
    return array[Math.floor(array.length * Math.random())];
  };

  /**
   * random coordinate on the world
   * @returns {object} random coordinate on the world { lat: [(-90)-(90)], lng: [(-180)-(180)] }
   */
  static randomCoordinate = (): Model.Coordinate => {
    return {
      lat: (Math.floor(Math.random() * 90000000) / 1000000) * (Math.random() < 0.5 ? 1 : -1),
      lng: (Math.floor(Math.random() * 180000000) / 1000000) * (Math.random() < 0.5 ? 1 : -1),
    };
  };

  /**
   * random adult count
   * @returns {number} random adult count between 1-12
   */
  static randomAdultCount = (): number => {
    return 1 + Math.floor(Math.random() * 12);
  };

  /**
   * random children count
   * @returns {number} random children count between 0-12
   */
  static randomChildrenCount = (): number => {
    return Math.floor(Math.random() * 12);
  };

  /**
   * random adult age
   * @returns {number} random adult count age 18-115
   */
  static randomAdultAge = (): number => {
    return 18 + Math.floor(Math.random() * 97);
  };

  /**
   * random children age
   * @returns {number} random children count age 0-17
   */
  static randomChildAge = (): number => {
    return Math.floor(Math.random() * 17);
  };

  /**
   * random answers array
   * @returns {array} random sub array of [5, 6, 16, 18, 27, 35, 12, 13, 25, 32, 33, 34, 1, 2, 41, 9, 10, 22, 40]
   */
  static randomAnswers = (): Array<number> => {
    const allAnswers = [5, 6, 16, 18, 27, 35, 12, 13, 25, 32, 33, 34, 9, 10, 22, 40, 1, 2, 41, 46, 47, 49, 43, 44, 45];
    const count = Math.floor(allAnswers.length - 1 * Math.random()) + 1;
    const answers: Array<number> = [];
    for (let i = 0; i < count; i += 1) {
      const answer = allAnswers[Math.floor(allAnswers.length * Math.random())];
      if (!answers.includes(answer)) answers.push(answer);
    }
    return answers;
  };

  /**
   * random username
   * @returns {string} random username start with `Uu` and length is 9 chars
   */
  static randomUsername = (): string => {
    return `Uu${Math.random()
      .toString(36)
      .substring(7)}`;
  };

  /**
   * add days param times day to date
   * @param {Date} increase this date
   * @param {number} number days for increase
   * @return {Date} increased date
   */
  static addDays = (date: Date, days: number): Date => {
    return new Date(date.valueOf() + 864e5 * days);
  };

  /**
   * If chars's length is 2 return chars, if chars's length is 1 return `0${chars}`
   * @param {string} chars any string
   * @return {string} 2 chars string
   */
  static extend2Chars = (chars: string): string => {
    return chars.toString().length === 2 ? chars.toString() : `0${chars.toString()}`;
  };

  /**
   * random date range
   * @returns {object} random date range for create a trip { arrivalDate: YYYY-MM-DD, departureDate: YYYY-MM-DD } Maximum range is 14.
   */
  static randomDateRange = (): { arrivalDate: string; departureDate: string } => {
    const randomCount = 1 + Math.floor(Math.random() * 30);
    let arrivalDate = new Date();
    arrivalDate = Random.addDays(arrivalDate, randomCount);
    let departureDate = new Date();
    departureDate = Random.addDays(departureDate, randomCount + 1 + Math.floor(Math.random() * 13));
    return {
      arrivalDate: `${arrivalDate.getFullYear()}-${Random.extend2Chars((arrivalDate.getMonth() + 1).toString())}-${Random.extend2Chars(
        arrivalDate.getDate().toString(),
      )}`,
      departureDate: `${departureDate.getFullYear()}-${Random.extend2Chars((departureDate.getMonth() + 1).toString())}-${Random.extend2Chars(
        departureDate.getDate().toString(),
      )}`,
    };
  };

  /**
   * random time
   * @returns {string} random time between `00:00` and `23:59`
   */
  static randomTime = (): string => {
    return `${Random.extend2Chars(Math.floor(Math.random() * 24).toString())}:00`;
  };

  /**
   * random time range
   * @returns { startTime: string; endTime: string } random time range for create a trip { startTime: hh:mm, endTime: hh:mm }
   */
  static randomTimeRange = (): { startTime: string; endTime: string } => {
    const startTime = Math.floor(Math.random() * 12);
    const endTime = 12 + Math.floor(Math.random() * 12);
    return { startTime: `${Random.extend2Chars(startTime.toString())}:00`, endTime: `${Random.extend2Chars(endTime.toString())}:00` };
  };

  /**
   * random ordered array
   * @param {Array<T>} array
   * @returns {Array<T>} random ordered array of param array items.
   */
  static randomOrder = <T>(array: Array<T>): Array<T> => {
    const exOrder = [...array];
    const newOrder = [];
    while (exOrder.length !== 0) {
      const i = Math.floor(Math.random() * exOrder.length);
      newOrder.push(exOrder[i]);
      exOrder.splice(i, 1);
    }
    return newOrder;
  };

  /**
   * random companion
   *
   */
  static randomNewCompanion = (answers: number[] = [43, 44, 45]): Model.CompanionRequest => {
    const title = 'Family member';
    const name = Math.random()
      .toString(36)
      .substring(7);
    const age = 1 + Math.floor(Math.random() * 114);

    return { title, name, answers, age };
  };

  /**
   * random trip profile
   *
   */
  static randomTripProfile = (
    cityId: number = 109,
    /* allAnswers: number[] = [
      16,
      5,
      101,
      102,
      103,
      104,
      18,
      105,
      106,
      107,
      108,
      109,
      6,
      110,
      111,
      112,
      50,
      113,
      114,
      115,
      27,
      35,
      51,
      116,
      117,
      118,
      12,
      13,
      25,
      32,
      33,
      34,
    ], */
  ): Model.TripProfile => {
    const arrivalDatetime = moment(new Date())
      .add(1, 'days')
      .set({ hour: 9, minute: 0 })
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    const departureDatetime = moment(new Date())
      .add(4, 'days')
      .set({ hour: 18, minute: 0 })
      .format('YYYY-MM-DDTHH:mm:ss[Z]');
    return {
      cityId,
      arrivalDatetime,
      departureDatetime,
      numberOfAdults: 1 + Math.floor(Math.random() * 19),
      numberOfChildren: Math.floor(Math.random() * 20),
      answers: [], // TODO answers will be partial allAnswers
      companionIds: [],
      excludeHash: [],
      doNotGenerate: 0,
    };
  };

  /**
   * random reservation
   *
   */
  static randomNewUserReservation = (poiId: string, tripHash: string): Model.UserReservationRequest => {
    return {
      key: 'Reservation1',
      value: {},
      provider: `Provider${Math.random()
        .toString(36)
        .substring(7)}`,
      tripHash,
      poiId,
    };
  };

  /**
   * random password
   * @returns {string} random username start with pass
   */
  static randomPassword = (): string => {
    return `pass${Math.random()
      .toString(36)
      .substring(4)}`;
  };

  /**
   * random user update request.
   *
   */
  static randomUser = (allAnswers: number[] = [1, 2, 41]): Model.UserUpdateRequest => {
    return {
      firstName: `FirstName${Math.random()
        .toString(36)
        .substring(7)}`,
      lastName: `LastName${Math.random()
        .toString(36)
        .substring(7)}`,
      /* password: `pass${Math.random()
        .toString(36)
        .substring(4)}`, */
      answers: allAnswers, // TODO answers will be partial allAnswers
    };
  };

  static randomUserPassword = (): Model.UserUpdateRequest => {
    return {
      password: `pass${Math.random()
        .toString(36)
        .substring(4)}`,
    };
  };

  /**
   * random register request.
   *
   */
  static randomNewUser = (): Model.RegisterRequest => {
    return {
      firstName: `FirstName${Math.random()
        .toString(36)
        .substring(7)}`,
      lastName: `LastName${Math.random()
        .toString(36)
        .substring(7)}`,
      password: `pass${Math.random()
        .toString(36)
        .substring(4)}`,
      email: `${Random.randomUsername()}@gmail.com`,
    };
  };

  /**
   * random trip profile
   *
   */
  static randomOffer = (
    cityId: 108,
    /* allAnswers: number[] = [
        16,
        5,
        101,
        102,
        103,
        104,
        18,
        105,
        106,
        107,
        108,
        109,
        6,
        110,
        111,
        112,
        50,
        113,
        114,
        115,
        27,
        35,
        51,
        116,
        117,
        118,
        12,
        13,
        25,
        32,
        33,
        34,
      ], */
  ): Model.OfferAddRequest => {
    const start = moment(new Date())
      .add(1, 'days')
      .set({ hour: 9, minute: 0 })
      .format('YYYY-MM-DD HH:mm');
    const end = moment(new Date())
      .add(2, 'days')
      .set({ hour: 18, minute: 0 })
      .format('YYYY-MM-DD HH:mm');
    return {
      cityId,
      title: 'string',
      description: 'string',
      currency: Model.OFFER_CURRENCY.CAD,
      status: Model.OFFER_STATUS.ACTIVE,
      threshold: 1,
      discount: 10,
      discountedProductCount: 0,
      quantity: 55,
      productName: 'lahmacun',
      timeframe: {
        start,
        end,
        blackouts: [],
        recurrent: [],
      },
      imageUrl: 'https://poi-pics.s3-eu-west-1.amazonaws.com/Place/108/556400/8dd63d2cb1568dd3c93864b08fdb0aa7.jpg',
      productTypeId: 1,
      offerType: Model.OFFER_TYPE.PERCENTAGE,
    };
  };
}

export default Random;
