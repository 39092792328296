import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const Address: React.FC<IIconSvg> = ({ fill, className, bgColor, size = '20px' }) => (
  <Svg className={className} fill={fill} color={bgColor} size={size} path="" viewBox="0 0 20 20">
    <path
      d="M12.5 18.9583H7.50002C2.97502 18.9583 1.04169 17.025 1.04169 12.5V7.5C1.04169 2.975 2.97502 1.04167 7.50002 1.04167H12.5C17.025 1.04167 18.9584 2.975 18.9584 7.5V12.5C18.9584 17.025 17.025 18.9583 12.5 18.9583ZM7.50002 2.29167C3.65835 2.29167 2.29169 3.65833 2.29169 7.5V12.5C2.29169 16.3417 3.65835 17.7083 7.50002 17.7083H12.5C16.3417 17.7083 17.7084 16.3417 17.7084 12.5V7.5C17.7084 3.65833 16.3417 2.29167 12.5 2.29167H7.50002Z"
      fill={fill}
    />
    <path
      d="M11.6251 18.9583C11.3417 18.9583 11.0917 18.7667 11.0167 18.4833L6.89173 1.81666C6.80839 1.48333 7.01672 1.14167 7.35005 1.05834C7.68338 0.975005 8.02503 1.17501 8.10836 1.51667L12.2334 18.1833C12.3167 18.5167 12.1084 18.8583 11.775 18.9417C11.725 18.95 11.6751 18.9583 11.6251 18.9583Z"
      fill={fill}
    />
    <path
      d="M1.66654 13.125C1.39154 13.125 1.1499 12.95 1.06657 12.675C0.966568 12.3416 1.15819 12 1.49152 11.9L9.43322 9.58331C9.76655 9.48331 10.1082 9.67497 10.2082 10.0083C10.3082 10.3416 10.1165 10.6833 9.7832 10.7833L1.84156 13.1C1.78323 13.1166 1.72488 13.125 1.66654 13.125Z"
      fill={fill}
    />
  </Svg>
);

export default Address;
