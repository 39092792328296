export enum ACTIVITY_THEMES {
  ON_THE_LAND = 'On the Land',
  FAMILY_FUN = 'Family Fun',
  SOFT_ADVENTURE = 'Soft Adventure',
  ON_THE_WATER = 'On the Water',
  WELLNESS = 'Wellness',
  ART_AND_CULTURE = 'Arts & Culture',
  HISTORY = 'History',
  UNDER_WATER = 'Under Water',
  IN_THE_AIR = 'In the Air',
  ROMANCE = 'Romance',
  FOODIE_DELIGHTS = 'Foodie Delights',
  UNDER_GROUND = 'Under Ground',
  VOLUNTOURISM = 'Voluntourism',
  SHORE_EXCURSIONS = 'Shore Excursions',
}
