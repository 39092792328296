import Model from "@tripian/model";
import { SvgIcons } from "@tripian/react";
import moment from "moment";
import React from "react";

interface ITravelSummary {
  tourName: string;
  tourImage: string;
  travelDate?: string;
  travelHour?: string;
  travelPrice?: number;
  travelerCount: number;
  showPrice?: boolean;
  t: (value: Model.TranslationKey) => string;
}

const TravelSummary: React.FC<ITravelSummary> = ({ tourName, tourImage, travelDate, travelHour, travelPrice, travelerCount, showPrice = true, t }) => {
  return (
    <div className="relative m-0 p-0">
      <div className={`rounded-lg bg-white ${showPrice ? "shadow-sm m-6" : ""}`}>
        <div className="p-5">
          <div>
            <div className="photo-row flex gap-4 items-start">
              <img className="w-20 h-20 rounded-md object-cover overflow-hidden shrink-0" src={tourImage} alt="" />
              <div>
                <div className="mb-1">
                  <div className="bg-green-600 text-white inline-flex items-center py-3 px-2 rounded-sm h-6 max-w-full">
                    <strong className="overflow-ellipsis self-auto whitespace-nowrap leading-5 text-sm font-bold ">
                      {t("trips.myTrips.localExperiences.tourDetails.freeCancellation")}
                    </strong>
                  </div>
                </div>
                <span className="text-sm font-bold line-clamp-2">{tourName}</span>
              </div>
            </div>
            <div className="product-info mt-4 flex flex-col text-sm">
              <ul className="list-none p-0 m-0 flex flex-col gap-2 text-sm font-medium mb-2">
                <li className="flex items-start">
                  <SvgIcons.Avatar className="mt-[0.125rem] mr-2" size="0.875rem" />
                  <span>
                    {travelerCount} {t("trips.myTrips.localExperiences.tourDetails.person")}
                  </span>
                </li>
                <li className="flex items-start">
                  <SvgIcons.Calendar className="mt-[0.175rem] mr-2" size="0.875rem" />
                  <span>{moment(travelDate).format("ddd,MMM DD,YYYY")}</span>
                  {travelHour && (
                    <>
                      <span className="whitespace-pre-wrap px-1"> • </span>
                      <span>{moment(travelHour, "HH:mm").format("hh:mm A")}</span>
                    </>
                  )}
                </li>
              </ul>
            </div>
            {showPrice && (
              <div className="pricing-row flex items-end gap-2 flex-row-reverse text-end">
                <div className="font-bold">${travelPrice}</div>
              </div>
            )}
          </div>
          {showPrice && (
            <>
              <hr className="my-4" style={{ opacity: 0.6 }} />
              <div className="flex justify-between font-bold w-full text-lg">
                <div>{t("trips.myTrips.localExperiences.tourDetails.totalPrice")}</div>
                <div>${travelPrice}</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default TravelSummary;
