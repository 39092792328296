export enum LOCATION_TYPE {
  CAPITAL = 'capital',
  CITY = 'city',
  COUNTRY = 'country',
  DISTRICT = 'district',
  ISLAND = 'island',
  MUNICIPALITY = 'municipality',
  PARISH = 'parish',
  PROVINCE = 'province',
  REGION = 'region',
  STATE = 'state',
  TERRITORY = 'territory',
  TOWN = 'town',
  VILLAGE = 'village',
}
