import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const Food: React.FC<IIconSvg> = ({ fill, bgColor, size }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    size={size}
    viewBox="0 0 40 40"
    path="M20 9.16667L30 16.6667V31.6667H10V16.6667L20 9.16667ZM20 5L6.66666 15V35H33.3333V15L20 5ZM19.1667 15.8333V20.8333H18.3333V15.8333H16.6667V20.8333H15.8333V15.8333H14.1667V20.8333C14.1667 22.2167 15.2833 23.3333 16.6667 23.3333V30H18.3333V23.3333C19.7167 23.3333 20.8333 22.2167 20.8333 20.8333V15.8333H19.1667ZM21.6667 19.1667V24.1667H23.3333V30H25V15.8333C23.1667 15.8333 21.6667 17.3333 21.6667 19.1667Z"
  />
);

export default Food;
