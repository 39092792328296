/* eslint-disable prefer-destructuring */
import axiosLib, { AxiosInstance } from 'axios';
import moment from 'moment';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private applicationId: string;

  private privateKey: string;

  private sandbox: boolean;

  private redirectUrlSandbox: string = 'https://traveldemo.agendas.group/results#/ticketAndAttraction/searchSession=';

  private redirectUrlProd: string = 'https://www.vacavia.co.uk/results#/ticketAndAttraction/searchSession=';

  private redirectUrl: string;

  constructor(apiUrl: string, userPass: string, sandbox: boolean, proxyURL: string, clientId?: string) {
    this.proxyURL = proxyURL;
    this.axios = axiosLib.create();
    // this.axios.defaults.baseURL = this.proxyURL + apiUrl;
    this.axios.defaults.baseURL = apiUrl;

    const userPassArr = userPass.split(':');
    this.applicationId = userPassArr[0];
    this.privateKey = userPassArr[1];

    this.axios.defaults.timeout = 30000; // 2500;
    // this.axios.defaults.headers.common['Accept-Language'] = 'en-US';
    this.axios.defaults.auth = { username: this.applicationId, password: this.privateKey };

    this.sandbox = sandbox;
    this.redirectUrl = this.sandbox ? this.redirectUrlSandbox : clientId || this.redirectUrlProd;
  }

  getTourTicketUrl = async (startDate: string, endDate: string, latitude: number, longitude: number, radius = 1): Promise<string> => {
    const data = {
      environment: 0,
      searchType: 4,
      language: 'en',
      locale: null,
      currency: 'USD',
      distanceUnit: 1,
      nationality: 'US',
      customerIP: '31.223.103.8',
      customerUserAgent: 'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/109.0.0.0 Safari/537.36',
      source: { app: 'tripian' },
      ticketSearchCriteria: {
        ticketType: 'Any',
        latitude, // 48.856614,
        longitude, // 2.352222,
        radius, // 1,
        startDate: moment(startDate).isBefore(moment()) ? moment().format('YYYY-MM-DDTHH:mm:ss[Z]') : startDate, // '2023-02-25T00:00:00Z',
        endDate, // '2023-02-29T23:59:00Z',
        participants: [
          {
            type: 'Adult',
          },
        ],
      },
    };
    return this.axios
      .post('search', data)
      .then((d) => (d.data as any).searchSession)
      .then((searchSession) => `${this.redirectUrl}${searchSession}`);
  };
}

export default API;
