/*
 * action types
 */

export const CHANGE_INIT_LOADING = 'CHANGE_INIT_LOADING';
// export const CHANGE_NAVBAR_VISIBLE: string = 'CHANGE_NAVBAR_VISIBLE';
export const CHANGE_TRIP_LIST_VISIBLE = 'CHANGE_TRIP_LIST_VISIBLE';
export const CHANGE_FOCUS_INFO_VISIBLE = 'CHANGE_FOCUS_INFO_VISIBLE';
// export const CHANGE_SEARCH_VISIBLE: string = 'CHANGE_SEARCH_VISIBLE';
// export const CHANGE_FAVORITES_VISIBLE: string = 'CHANGE_FAVORITES_VISIBLE';
// export const CHANGE_FAVORITES_MODAL_VISIBLE: string = 'CHANGE_FAVORITES_MODAL_VISIBLE';
// export const CHANGE_BOOKINGS_MODAL_VISIBLE: string = 'CHANGE_BOOKINGS_MODAL_VISIBLE';
// export const CHANGE_LOCAL_EXPERIENCES_MODAL_VISIBLE: string = 'CHANGE_LOCAL_EXPERIENCES_MODAL_VISIBLE';
