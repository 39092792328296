import { OFFER_TYPE } from '../enums/OFFER_TYPE';
import { PROVIDER_ID } from '../enums/PROVIDER_ID';

export enum CONFIG_CONTENTS_PROVIDER_NAME {
  BOOKBARBADOS = 'bookbarbados',
  // GLX = 'glx',
  GYG = 'gyg',
  OPENTABLE = 'opentable',
  YELP = 'yelp',
  UBER = 'uber',
}

export type ProviderObject = {
  id: PROVIDER_ID;
  name: string;
  apiUrl: string;
  apiKey: string;
  prod: boolean;
  clientId: string;
};

export type Theme = {
  dark: {
    primary: string;
    secondary: string;
    success: string;
    warning: string;
    info: string;
    danger: string;
    textPrimary: string;
    background: string;
    headerTextColor: string;
    headerBg: string;
  };
  light: {
    primary: string;
    secondary: string;
    success: string;
    warning: string;
    info: string;
    danger: string;
    textPrimary: string;
    background: string;
    headerTextColor: string;
    headerBg: string;
  };
};

export type MenuLink = {
  name: string; // "Flights",
  url: string; // "https://trial-dev.tripian.com/",
  external_link: boolean; // true
};

export type ConfigList = {
  apiKey: string;
  whiteLabels: {
    reverseProxyUrl: string;
    brandName: string;
    imagePaths: {
      logoPathDark: string;
      logoPathLight: string;
      formHeaderImgUrl?: string;
      formBgImgUrl?: string;
      appBackgroundImgUrl?: string;
    };
    googleAnalyticsUrl: {
      business: string;
      customer: string;
    };
    googleAnalyticsKey: {
      business: string;
      customer: string;
    };
    providers: {
      name: CONFIG_CONTENTS_PROVIDER_NAME;
      apikey: string;
      apiurl: string;
      prod: boolean;
    }[];
    cognito: {
      clientId: string;
      domain: string;
      region: string;
      identityProviders: string[];
    };
    theme?: Theme;
    // Sadece light => [“light”]
    // Sadece dark => [“dark”]
    // Default light => [“dark”, “light”]
    // Default dark => [“dark”,“light”]
    availableThemes: Array<'dark' | 'light'>;

    rootPath?: string;
    defaultDestinationId?: number;
    brandUrl?: string;
    tosUrl?: string;
    ppUrl?: string;
    landingPageUrl?: string;

    languages: string[];
    dealsPageUrl?: string;
    externalMenuLinks?: Record<string, MenuLink[]>;
  };
  baseFeatures: {
    showRegister: boolean;
    showLogin: boolean;
    showChangePassword: boolean;
    showTripModeQuestion: boolean;
    showOverview: boolean;
    showOffers: boolean;
    showStepCardThumbs: boolean;
    loginWithToken: boolean;
    loginWithHash: boolean;
    saveSession: boolean;
    showStepScoreDetail: boolean;
    showTravelGuide: boolean;
    showVoucher: boolean;
    showSaveTrip: boolean;
    showSideNav: boolean;
    showHome: boolean;
    showCreateTrip: boolean;
    showUpdateTrip: boolean;
    showUserProfile: boolean;
    sharedTrip: boolean;
    qrReader: 'business' | 'customer';
    widgetTheme1: boolean;
  };
  sbt: {
    campaignDefaultCurrency: string;
    offerTypes: OFFER_TYPE[];
  };
  providers: {
    // showToursAndTickets: boolean;
    // showRestaurantReservations: boolean;
    // showBookARide: boolean;
    // showAccommodationPois: boolean;
    // showCarRentPois: boolean;
    // tourProviderSource: PROVIDER_ID;
    // tourProviderSourceProd: boolean;
    // ticketProviderSource: PROVIDER_ID;
    // ticketProviderSourceProd: boolean;
    // restaurantReservationProviderSource: PROVIDER_ID[];
    // restaurantReservationSourceProd: boolean;
    // accommodationPoiProvider: PROVIDER_ID;
    // TODO
    tourAndTicket: ProviderObject[];
    accommodation: ProviderObject[];
    restaurant: ProviderObject[];
    transportation: ProviderObject[];
  };

  /* cognito: {
    clientId: string;
    domain: string;
    region: string;
    identityProviders: string[];
  };
  contents: {
    brandName: string;
    googleAnalyticsKey: string;
    googleAnalyticsUrl: string;
    logoPath: string;
    theme: string;
    reverseProxyUrl: string;
    providers: {
      name: CONFIG_CONTENTS_PROVIDER_NAME;
      apikey: string;
      apiurl: string;
      prod: boolean;
    }[];
  }; */
};
