/* eslint-disable no-console */
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
import TDate from './TDate';

const easy = {
  // /**
  //  * Push message to tripian.data.log array.
  //  * If NODE_ENV is development, write message to console.
  //  * @param {object} message
  //  */
  // log: (message: object) => {
  //   // data.logs.push(...message);
  //   if (process.env.NODE_ENV === 'development') {
  //     // eslint-disable-next-line no-console
  //     console.log(message);
  //   }
  // },

  // /**
  //  * Push error message to tripian.data.errors array.
  //  * If NODE_ENV is development, write error message to console.
  //  * @param {object} message
  //  */
  // error: (message: object) => {
  //   // data.errors.push(...message);
  //   if (process.env.NODE_ENV === 'development') {
  //     // eslint-disable-next-line no-console
  //     console.error(message);
  //   }
  // },

  /**
   * Replace stringQuery, param.key with param.value.
   * @param {string} stringQuery
   * @param {object} param { key, value }
   * @returns {string} stringQuery.replace(`{${param.key}}`, param.value)
   */
  setParameter: (stringQuery: string, param: { key: string; value: string }): string => {
    return stringQuery.replace(`{${param.key}}`, param.value);
  },

  /**
   * Replace stringQuery, '{id}' with id
   * @param {string} stringQuery
   * @param {number} id
   * @returns {string} stringQuery.replace('{id}', id);
   */
  setParameterId: (stringQuery: string, id: number | string, key: string): string => {
    return stringQuery.replace(`{${key}}`, id.toString());
  },

  /**
   * Replace stringQuery, all param.key with all param.value foreach params parameter
   * @param {string} stringQuery
   * @param {object} params [{ key, value }, { key, value } ...]
   * @returns {string} stringQuery.replace(`{${param.key}}`, param.value)
   */
  setParameters: (stringQuery: string, params: { key: string; value: string }[]): string => {
    return params.reduce((previoueValue, currentValue) => easy.setParameter(previoueValue, currentValue), stringQuery);
  },

  parseAccessToken: (idToken: string): Model.TokenPayload | undefined => {
    try {
      const base64Url: string = idToken.split('.')[1];

      const base64: string = base64Url.replace(/-/g, '+').replace(/_/g, '/');

      const encodedURIComponent: string = Buffer.from(base64, 'base64').toString();

      const jsonPayload: string = decodeURIComponent(encodedURIComponent);

      return JSON.parse(jsonPayload);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Invalid access token', error);
      return undefined;
    }
  },

  parseToken: (token: Model.Token): Model.TokenPayload | undefined => {
    const { idToken: accessToken } = token;
    return easy.parseAccessToken(accessToken);
  },

  accessTokenExpSecond: (tokenPayload: Model.TokenPayload): number => {
    const now = Math.floor(Date.now() / 1000);
    return tokenPayload.exp - now;
  },

  refreshTokenExpSecond: (tokenPayload: Model.TokenPayload): number => {
    const refreshTokenValidationDuration = 2592000; // 60*60*24*30 (1 month)
    const refreshTokenExpireTime = tokenPayload?.auth_time + refreshTokenValidationDuration;
    const now = Math.floor(Date.now() / 1000);
    return refreshTokenExpireTime - now;
  },

  TDate,
};

export default easy;
