import 'cross-fetch/polyfill';
// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
import easy from '../easy/easy';
import { handleHttpResponseSuccess, handleHttpResponseError } from '../easy/handle/handle';
import IXhrOptions from './IXhrOptions';

const urlSearchParams = (params?: any) => {
  if (params === undefined) return '';
  const objectEntries = Object.entries(params).filter((x) => x[1] !== undefined);
  const object = Object.fromEntries(objectEntries) as any;

  return `?${new URLSearchParams(object)}`;
};

class XHR {
  private baseURL: string;

  private xApiKey: string;

  private timeout = 30000;

  private authorization?: string;

  private token?: Model.Token;

  private lang?: string;

  getToken = () => this.token;

  setToken = (token: Model.Token) => {
    this.token = token;
    this.authorization = `${token.tokenType} ${token.idToken}`;
  };

  removeToken = () => {
    this.token = undefined;
    this.authorization = undefined;
  };

  setLang = (lang: string) => {
    this.lang = lang;
  };

  constructor(xhrOptions: IXhrOptions, lang: string) {
    this.baseURL = xhrOptions.url;
    this.xApiKey = xhrOptions.xApiKey;
    this.timeout = 30000;
    if (xhrOptions.token) this.setToken(xhrOptions.token);
    if (lang) this.setLang(lang);
  }

  public refreshXhrToken = () => {
    const refreshToken = this.token?.refreshToken;
    if (refreshToken) {
      return this.req('POST')<Model.Token>('/refresh', 'token', { refreshToken }).then((token) => {
        const fullToken: Model.Token = { ...token, refreshToken };
        this.setToken(fullToken);
        return fullToken;
      });
    }
    return undefined;
  };

  private get = async <T>(url: string, dataKey: string, params?: any, customHeaders?: any): Promise<T> => {
    return fetch(this.baseURL + url + urlSearchParams({ ...params, lang: this.lang }), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.authorization ?? '',
        'x-api-key': this.xApiKey,
        ...customHeaders,
      },
    })
      .then((response) => response.json() as Promise<Model.SuccessResponse<T>>)
      .then<T>(
        (httpResponse: Model.SuccessResponse<T>) => handleHttpResponseSuccess<T>(httpResponse, dataKey, this.lang as Model.LangCodeKey, params).data,
      )
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey, params));
  };

  private getWithPage = async <T>(url: string, dataKey: string, params?: any): Promise<Model.DataPayload<T>> => {
    return fetch(this.baseURL + url + urlSearchParams({ ...params, lang: this.lang }), {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.authorization ?? '',
        'x-api-key': this.xApiKey,
      },
    })
      .then((response) => response.json() as Promise<Model.SuccessResponse<T>>)
      .then<Model.DataPayload<T>>((httpResponse) => handleHttpResponseSuccess<T>(httpResponse, dataKey, this.lang as Model.LangCodeKey, params))
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey, params));
  };

  private appendLangToUrl = (url: string) => {
    const URL = this.baseURL + url;
    return `${URL}${urlSearchParams({ lang: this.lang })}`;
  };

  private post = async <T>(url: string, dataKey: string, params?: any, customHeaders?: any): Promise<T> => {
    const fullUrl = this.appendLangToUrl(url);
    return fetch(fullUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.authorization ?? '',
        'x-api-key': this.xApiKey,
        ...customHeaders,
      },
      body: JSON.stringify({ ...params, lang: this.lang }),
    })
      .then<Model.SuccessResponse<T>>((response) => response.json() as Promise<Model.SuccessResponse<T>>)
      .then<T>(
        (httpResponse: Model.SuccessResponse<T>) => handleHttpResponseSuccess<T>(httpResponse, dataKey, this.lang as Model.LangCodeKey, params).data,
      )
      .then<T>((dataResponse: T) => {
        if (dataKey === 'token') this.setToken((dataResponse as unknown) as Model.Token);
        return dataResponse;
      })
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey, params));
  };

  private put = async <T>(url: string, dataKey: string, params?: any, customHeaders?: any): Promise<T> => {
    const fullUrl = this.appendLangToUrl(url);
    return fetch(fullUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.authorization ?? '',
        'x-api-key': this.xApiKey,
        ...customHeaders,
      },
      body: JSON.stringify({ ...params, lang: this.lang }),
    })
      .then<Model.SuccessResponse<T>>((response) => response.json() as Promise<Model.SuccessResponse<T>>)
      .then<T>(
        (httpResponse: Model.SuccessResponse<T>) => handleHttpResponseSuccess<T>(httpResponse, dataKey, this.lang as Model.LangCodeKey, params).data,
      )
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey, params));
  };

  private delete = async <T>(url: string, dataKey: string, customHeaders?: any): Promise<T> => {
    return fetch(this.baseURL + url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        authorization: this.authorization ?? '',
        'x-api-key': this.xApiKey,
        ...customHeaders,
      },
    })
      .then<Model.SuccessResponse<T>>((response) => response.json() as Promise<Model.SuccessResponse<T>>)
      .then<T>((httpResponse: Model.SuccessResponse<T>) => handleHttpResponseSuccess<T>(httpResponse, dataKey, this.lang as Model.LangCodeKey).data)
      .catch((errorResponse) => handleHttpResponseError(errorResponse, dataKey));
  };

  req = (httpMethod: string) => {
    if (httpMethod === 'GET') return this.get;
    if (httpMethod === 'POST') return this.post;
    if (httpMethod === 'PUT') return this.put;
    if (httpMethod === 'DELETE') return this.delete;
    return this.get;
  };

  reqWithPage = (httpMethod: string) => {
    if (httpMethod === 'GET') return this.getWithPage;
    return this.getWithPage;
  };

  info = () => {
    const email = this.token ? easy.parseToken(this.token)?.email ?? '' : '';
    return {
      baseURL: this.baseURL,
      xApiKey: this.xApiKey,
      email,
    };
  };
}

export default XHR;
