/*
 * action types
 */

// Auths
export const LOGIN_FINALLY = "LOGIN_FINALLY";
export const LOGOUT_FINALLY = "LOGOUT_FINALLY";

// User Data
export const CHANGE_USER = "CHANGE_USER";
export const CHANGE_TRIP_REFERENCES = "CHANGE_TRIP_REFERENCES";
export const CHANGE_COMPANIONS = "CHANGE_COMPANIONS";

// Loadings
export const CHANGE_LOADING_USER = "CHANGE_LOADING_USER";
export const CHANGE_LOADING_TRIP_REFERENCES = "CHANGE_LOADING_TRIP_REFERENCES";
export const CHANGE_LOADING_COMPANIONS = "CHANGE_LOADING_COMPANIONS";

// Notifications
export const SAVE_NOTIFICATION = "SAVE_NOTIFICATION";
export const HIDE_NOTIFICATION = "HIDE_NOTIFICATION";
