// eslint-disable-next-line import/no-extraneous-dependencies
import Model, { Providers } from '@tripian/model';
import { version } from '../package.json';

import random from './Random';
import data from './data/data';
import API from './api/API';
import easy from './easy/easy';

import ProvidersYelpAPI from './providers/yelp/API';
import ProvidersGygAPI from './providers/gyg/API';
import ProvidersBbAPI from './providers/bb/API';
import ProvidersTravelifyAPI from './providers/travelify/API';
import ProvidersViatorAPI from './providers/viator/API';
import ProvidersRezdyAPI from './providers/rezdy/API';
import ProvidersVidereoAPI from './providers/videreo/API';

// eslint-disable-next-line import/no-mutable-exports
let api: API;

/**
 * This function initilize for web sdk.
 */
const init = (url: string, xApiKey: string, token?: Model.Token, useCahce: boolean = true, lang: string = 'en') => {
  if (!data.initialised) api = new API({ url, xApiKey, token }, lang, useCahce);
  data.initialised = true;
};

type Providers = {
  yelp?: ProvidersYelpAPI;
  gyg?: ProvidersGygAPI;
  bb?: ProvidersBbAPI;
  travelify?: ProvidersTravelifyAPI;
  viator?: ProvidersViatorAPI;
  rezdy?: ProvidersRezdyAPI;
  videreo?: ProvidersVidereoAPI;

  yelpInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang?: string) => void;
  gygInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang?: string, currency?: string) => void;
  bbInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string) => Promise<void>;
  travelifyInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, clientId?: string) => void;
  viatorInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang?: string) => void;
  rezdyInit: (apiUrl: string, apiToken: string, proxyURL: string) => void;
  videreoInit: (apiUrl: string, proxyURL: string) => void;
};

const providers: Providers = {
  yelp: undefined,
  gyg: undefined,
  bb: undefined,
  travelify: undefined,
  viator: undefined,
  rezdy: undefined,
  videreo: undefined,

  yelpInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang: string = 'en') => {
    providers.yelp = new ProvidersYelpAPI(apiUrl, apiToken, sandbox, proxyURL, lang);
  },

  gygInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang: string = 'en', currency: string = 'USD') => {
    providers.gyg = new ProvidersGygAPI(apiUrl, apiToken, sandbox, proxyURL, lang, currency);
  },

  bbInit: (apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string) => {
    providers.bb = new ProvidersBbAPI(apiUrl, sandbox, proxyURL);
    return providers.bb.login(apiToken);
  },

  travelifyInit: (apiUrl: string, userPass: string, sandbox: boolean, proxyURL: string, clientId?: string) => {
    providers.travelify = new ProvidersTravelifyAPI(apiUrl, userPass, sandbox, proxyURL, clientId);
  },

  viatorInit: (apiUrl: string, userPass: string, sandbox: boolean, proxyURL: string, lang: string = 'en') => {
    providers.viator = new ProvidersViatorAPI(apiUrl, userPass, sandbox, proxyURL, lang);
  },

  rezdyInit: (apiUrl: string, apiToken: string, proxyURL: string) => {
    providers.rezdy = new ProvidersRezdyAPI(apiUrl, apiToken, proxyURL);
  },

  videreoInit: (apiUrl: string, proxyURL: string) => {
    providers.videreo = new ProvidersVidereoAPI(apiUrl, proxyURL);
  },
};

const ver: string = version;

export { ver, random, data, API, api, init, easy, providers };
