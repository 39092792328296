import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Model, { helper, Providers } from "@tripian/model";
import { providers } from "@tripian/core";
import { saveNotification } from "../redux/action/user";
import useReservation from "./useReservation";
import moment from "moment";
import useTranslate from "./useTranslate";

const initialCatalog: Providers.Viator.CatalogGroup[] = [
  { title: "Adventure", items: [], parentTagIds: [22046] },
  { title: "Food", items: [], parentTagIds: [21482, 12208, 12694, 12713, 13285, 13288, 16548, 20245, 21482, 21567, 21911] },
  { title: "Culture and History", items: [], parentTagIds: [21479, 21511, 21517, 21910] },
  { title: "Sightseeing", items: [], parentTagIds: [11926, 11941, 12989, 20241, 21725, 21729, 21913] },
  { title: "Art and Museums", items: [], parentTagIds: [10847, 12716, 13109, 21514] },
  { title: "Local and Neighborhood", items: [], parentTagIds: [11265, 12208, 21754] },
  { title: "Others", items: [], parentTagIds: [] },
];

const defaultBookingConfirmRequest: Providers.Viator.BookingConfirmRequest = {
  cartRef: "",
  bookerInfo: {
    firstName: "",
    lastName: "",
  },
  communication: {
    email: "",
    phone: "",
  },
  items: [],
  paymentToken: "",
};

const useViatorApi = (cityId?: number, cityName?: string, arrivalDatetime?: string, departureDatetime?: string) => {
  const [viatorProducts, setViatorProducts] = useState<Providers.Viator.Product[]>();
  const [viatorProductCatalogGroup, setViatorProductCatalogGroup] = useState(initialCatalog);
  const [viatorBookingInfo, setViatorBookingInfo] = useState<Providers.Viator.BookingConfirm>();
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
  const [availableDate, setAvailableDate] = useState<string>(moment().add(1, "days").format("YYYY-MM-DD"));
  const [selectedHour, setSelectedHour] = useState<string>("");

  const [loadings, setLoadings] = useState<
    "loadingViatorProductCatalog" | "loadingViatorProductInfo" | "loadingViatorProductAvailability" | "loadingBookingHold" | "loadingBookingConfirm" | ""
  >("");

  const [viatorProductInfo, setViatorProductInfo] = useState<Providers.Viator.TourData>();

  const [viatorProductAvailability, setViatorProductAvailability] = useState<Providers.Viator.AvailabilityCheck>();

  const [successfullBooking, setSuccessfullBooking] = useState<boolean>(false);

  const [paymentSessionToken, setPaymentSessionToken] = useState<string>();

  const [bookingConfirmRequest, setBookingConfirmRequest] = useState<Providers.Viator.BookingConfirmRequest>(defaultBookingConfirmRequest);

  const { reservationAdd } = useReservation(cityId);

  const dispatch = useDispatch();

  const { t } = useTranslate();

  useEffect(() => {
    if (viatorProducts) {
      const newCatalog: Providers.Viator.CatalogGroup[] = helper.deepCopy(initialCatalog);
      let includes = false;
      viatorProducts.forEach((p) => {
        for (let catalogElement = 0; catalogElement < newCatalog.length; catalogElement++) {
          includes = false;
          for (let i = 0; i < p.tags.length; i++) {
            const tag = p.tags[i];
            if (newCatalog[catalogElement].parentTagIds.includes(tag)) {
              includes = true;
              break;
            }
          }

          if (includes) {
            newCatalog[catalogElement].items.push(p);
            break;
          }
        }

        if (!includes) newCatalog[newCatalog.length - 1].items.push(p);
      });

      setViatorProductCatalogGroup(newCatalog);
      setLoadings("");
    }
  }, [viatorProducts]);

  useEffect(() => {
    if (
      window.tconfig.TOUR_TICKET_PROVIDER_IDS.some((x) => x === Model.PROVIDER_ID.VIATOR) &&
      providers.viator &&
      cityName &&
      arrivalDatetime &&
      departureDatetime &&
      window.tconfig.SHOW_TOURS_AND_TICKETS
    ) {
      const today = moment().startOf("day");
      const tomorrow = moment(today).add(1, "days");

      const updatedArrivalDatetime = moment(arrivalDatetime).isBefore(today) ? today.format("YYYY-MM-DD") : moment(arrivalDatetime).format("YYYY-MM-DD");
      const updatedDepartureDatetime = moment(departureDatetime).isBefore(today) ? tomorrow.format("YYYY-MM-DD") : moment(departureDatetime).format("YYYY-MM-DD");

      setLoadings("loadingViatorProductCatalog");
      providers.viator
        .products(cityName, updatedArrivalDatetime, updatedDepartureDatetime)
        .then((products: Providers.Viator.Product[]) => {
          setViatorProducts(products);
        })
        .catch((viatorFetchToursError) => {
          dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "ViatorFetchToursError", viatorFetchToursError));
          setLoadings("");
        })
        .finally(() => setLoadings(""));
    }
  }, [arrivalDatetime, cityName, departureDatetime, dispatch]);

  const fetchProductInfo = useCallback(
    (productCode: string): Promise<Boolean> => {
      if (providers.viator && window.tconfig.SHOW_TOURS_AND_TICKETS && window.tconfig.TOUR_TICKET_PROVIDER_IDS.some((x) => x === Model.PROVIDER_ID.VIATOR)) {
        setLoadings("loadingViatorProductInfo");
        setViatorProductInfo(undefined);

        return providers.viator
          .tourData(productCode)
          .then((pi: Providers.Viator.TourData) => {
            setViatorProductInfo(pi);
            return true;
          })
          .catch((viatorFetchProductInfoError) => {
            dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorFetchTourError", viatorFetchProductInfoError));
            setLoadings("");
            return false;
          })
          .finally(() => {
            setLoadings("");
          });
      }
      return Promise.resolve(false);
    },
    [dispatch]
  );

  const fetchProductBookingHold = useCallback(
    (request: Providers.Viator.BookingCardHoldRequest): Promise<Boolean> => {
      if (window.tconfig.TOUR_TICKET_PROVIDER_IDS.some((x) => x === Model.PROVIDER_ID.VIATOR) && window.tconfig.SHOW_TOURS_AND_TICKETS && providers.viator) {
        setLoadings("loadingBookingHold");
        return providers.viator
          ?.bookingCardHold(request)
          .then((data) => {
            setPaymentSessionToken(data.paymentSessionToken);
            setBookingConfirmRequest((prevRequest) => ({
              ...prevRequest,
              cartRef: data.cartRef,
              items: data.items.map((item) => ({
                bookingRef: item.bookingRef,
              })),
            }));
            return true;
          })
          .catch((err) => {
            dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorProductBookingHold", err as string));
            return false;
          })
          .finally(() => {
            setLoadings("");
          });
      }

      return Promise.resolve(true);
    },
    [dispatch]
  );

  const fetchProductAvailability = useCallback(
    async (request: Providers.Viator.AvailabilityCheckRequest): Promise<Boolean> => {
      setLoadings("loadingViatorProductAvailability");

      try {
        if (window.tconfig.TOUR_TICKET_PROVIDER_IDS.some((x) => x === Model.PROVIDER_ID.VIATOR)) {
          if (providers.viator && /* tripReference && */ window.tconfig.SHOW_TOURS_AND_TICKETS) {
            const res = await providers.viator.availabilityPriceCheck(request);

            if ((res as any).code) {
              const errorMessage = (res as any).message;
              dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorFetchAvailabilityError", errorMessage as string));
              return false;
            } else {
              setViatorProductAvailability(res as Providers.Viator.AvailabilityCheck);
              return true;
            }
          }
          return Promise.resolve(false);
        }
      } catch (viatorFetchProductAvailabilityError) {
        dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorFetchAvailabilityError", viatorFetchProductAvailabilityError as string));
        return false;
      } finally {
        setLoadings("");
      }

      setLoadings("");
      return Promise.resolve(false);
    },
    [dispatch]
  );

  const setBookingConfirm = (request: Providers.Viator.BookingConfirmRequest) => {
    setBookingConfirmRequest(request);
  };

  const setBookingConfirmLoading = (loading: boolean) => {
    if (loading === true) {
      setLoadings("loadingBookingConfirm");
    } else {
      setLoadings("");
    }
  };

  const setBookingInfoModal = (show: boolean) => {
    setShowInfoModal(show);
  };

  const changeAvailableDate = (date: string) => {
    setAvailableDate(date);
  };

  const changeSelectedHour = (hour: string) => {
    setSelectedHour(hour);
  };

  const confirmPayment = async (requestParam: Providers.Viator.BookingConfirmRequest): Promise<boolean> => {
    providers.viator
      ?.bookingConfirm(requestParam)
      .then((reservationInfo) => {
        const saveData: Providers.Viator.BookingReservationDetails = {
          ...reservationInfo,
          cityName: cityName || "",
          tourImage: viatorProductInfo?.info.images[0].variants[viatorProductInfo.info.images[0].variants.length - 1].url || "",
          tourName: viatorProductInfo?.info.title || "",
          availableDate,
          selectedHour,
        };

        const bookingDateTime = `${availableDate} ${selectedHour}`;
        const formattedBookingDateTime = moment(bookingDateTime).format("YYYY-MM-DD HH:mm:ss");

        if (reservationInfo.items[0].status === "CONFIRMED") {
          reservationAdd({ key: Model.PROVIDER_NAME.VIATOR, provider: Model.PROVIDER_NAME.VIATOR, value: saveData, bookingDateTime: formattedBookingDateTime }, false).then(() => {
            setSuccessfullBooking(true);
            dispatch(saveNotification(Model.NOTIFICATION_TYPE.SUCCESS, "viatorBookingConfirmSuccess", t("trips.myTrips.localExperiences.tourDetails.successfullyBooked")));
          });
        }
        if (reservationInfo.items[0].status === "PENDING") {
          reservationAdd({ key: Model.PROVIDER_NAME.VIATOR, provider: Model.PROVIDER_NAME.VIATOR, value: saveData, bookingDateTime: formattedBookingDateTime }, false).then(() => {
            dispatch(
              saveNotification(Model.NOTIFICATION_TYPE.WARNING, "viatorBookingConfirmWarning", t("trips.myTrips.localExperiences.tourDetails.bookingIsStillAwatingConfirm"))
            );
          });
        }
        if (reservationInfo.items[0].status === "REJECTED") {
          dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorBookingConfirmError", t("trips.myTrips.localExperiences.tourDetails.bookingRejected")));
        }
        setShowInfoModal(true);
        setViatorBookingInfo(reservationInfo);
      })
      .catch((viatorBookingConfirmError) => {
        dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "viatorBookingConfirmError", viatorBookingConfirmError as string));
      })
      .finally(() => {
        setLoadings("");
      });
    return Promise.resolve(true);
  };

  return {
    viatorProductCatalogGroup,
    loadings,
    fetchProductInfo,
    viatorProductInfo,
    fetchProductAvailability,
    viatorProductAvailability,
    successfullBooking,
    fetchProductBookingHold,
    paymentSessionToken,
    bookingConfirmRequest,
    setBookingConfirm,
    setBookingConfirmLoading,
    showInfoModal,
    setBookingInfoModal,
    confirmPayment,
    viatorBookingInfo,
    availableDate,
    changeAvailableDate,
    selectedHour,
    changeSelectedHour,
  };
};

export default useViatorApi;
