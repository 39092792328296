// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import axiosLib, { AxiosInstance } from 'axios';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  constructor(apiUrl: string, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.apiUrl = apiUrl;
    this.axios = axiosLib.create();
    this.axios.defaults.timeout = 30000;
  }

  searchVideos = async (): Promise<Providers.Videreo.ResponseResult[]> => {
    const url = btoa(`${this.apiUrl}/api/1.1/obj/videos`);
    return this.axios
      .get<Providers.Videreo.ApiResponse>(`${this.proxyURL}?url=${url}`)
      .then((searchVideosResponse) => {
        return searchVideosResponse.data.response.results;
      })
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.error('videreo-searchVideosResponse', errorResponse);
        throw errorResponse.message;
      });
  };
}

export default API;
