import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const Timer: React.FC<IIconSvg> = ({ className, bgColor, size = '20px' }) => (
  <Svg className={className} color={bgColor} size={size} path="" viewBox="0 0 20 20">
    <path d="M9.99992 18.9583C5.63325 18.9583 2.08325 15.4083 2.08325 11.0417C2.08325 6.675 5.63325 3.125 9.99992 3.125C14.3666 3.125 17.9166 6.675 17.9166 11.0417C17.9166 15.4083 14.3666 18.9583 9.99992 18.9583ZM9.99992 4.375C6.32492 4.375 3.33325 7.36667 3.33325 11.0417C3.33325 14.7167 6.32492 17.7083 9.99992 17.7083C13.6749 17.7083 16.6666 14.7167 16.6666 11.0417C16.6666 7.36667 13.6749 4.375 9.99992 4.375Z" />
    <path d="M10 11.4583C9.65833 11.4583 9.375 11.175 9.375 10.8333V6.66666C9.375 6.325 9.65833 6.04166 10 6.04166C10.3417 6.04166 10.625 6.325 10.625 6.66666V10.8333C10.625 11.175 10.3417 11.4583 10 11.4583Z" />
    <path d="M12.5 2.29166H7.5C7.15833 2.29166 6.875 2.00833 6.875 1.66666C6.875 1.325 7.15833 1.04166 7.5 1.04166H12.5C12.8417 1.04166 13.125 1.325 13.125 1.66666C13.125 2.00833 12.8417 2.29166 12.5 2.29166Z" />
  </Svg>
);

export default Timer;
