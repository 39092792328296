import React from 'react';
import Svg from '../Svg';
import IIconSvg from './IIconSvg';

const Info: React.FC<IIconSvg> = ({ fill, bgColor, size = '16px', className }) => (
  <Svg
    className={className}
    fill={fill}
    color={bgColor}
    size={size}
    path="M7.45817 11.792H8.54146V7.00031H7.45817V11.792ZM7.99542 5.7407C8.16128 5.7407 8.30177 5.68461 8.41688 5.57243C8.53199 5.46024 8.58955 5.32122 8.58955 5.15537C8.58955 4.98952 8.53345 4.84903 8.42125 4.73391C8.30907 4.6188 8.17006 4.56124 8.00421 4.56124C7.83835 4.56124 7.69786 4.61734 7.58275 4.72953C7.46764 4.84173 7.41009 4.98075 7.41009 5.1466C7.41009 5.31244 7.46618 5.45292 7.57838 5.56803C7.69056 5.68315 7.82957 5.7407 7.99542 5.7407ZM8.00696 15.5836C6.96374 15.5836 5.98124 15.3863 5.05946 14.9915C4.13767 14.5968 3.33085 14.0537 2.639 13.3622C1.94714 12.6706 1.40376 11.8645 1.00886 10.9437C0.613955 10.0229 0.416504 9.03906 0.416504 7.99216C0.416504 6.94524 0.613865 5.96437 1.00859 5.04953C1.40331 4.13469 1.94643 3.33134 2.63796 2.63949C3.3295 1.94763 4.13566 1.40425 5.05644 1.00935C5.97722 0.614445 6.96106 0.416992 8.00796 0.416992C9.05488 0.416992 10.0358 0.614354 10.9506 1.00908C11.8654 1.4038 12.6688 1.94692 13.3606 2.63845C14.0525 3.32999 14.5959 4.13453 14.9908 5.05206C15.3857 5.96958 15.5831 6.94995 15.5831 7.99316C15.5831 9.03638 15.3858 10.0189 14.991 10.9407C14.5963 11.8625 14.0532 12.6693 13.3617 13.3611C12.6701 14.053 11.8656 14.5964 10.9481 14.9913C10.0305 15.3862 9.05017 15.5836 8.00696 15.5836ZM7.99982 14.5003C9.80537 14.5003 11.3401 13.8684 12.604 12.6045C13.8679 11.3406 14.4998 9.80586 14.4998 8.00031C14.4998 6.19475 13.8679 4.66003 12.604 3.39614C11.3401 2.13225 9.80537 1.50031 7.99982 1.50031C6.19426 1.50031 4.65954 2.13225 3.39565 3.39614C2.13176 4.66003 1.49982 6.19475 1.49982 8.00031C1.49982 9.80586 2.13176 11.3406 3.39565 12.6045C4.65954 13.8684 6.19426 14.5003 7.99982 14.5003Z"
    viewBox="0 0 16 16"
  />
);

export default Info;
