// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import axiosLib, { AxiosInstance } from 'axios';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  // private prodUrl = 'https://admin.bookbarbados.com/gptour/api';
  // private prodApiKey = 'ZUlpNGTnPszqLXMWq0r0j66Q4eEkUV9sYRSsfg/8Cts=';
  // private prodLogin = "kischuk@mailinator.com";
  // private prodPassword = "Prod%Tripian1";
  // private sandboxUrl = 'https://testadmin.bookbarbados.com/gptour/api';
  // private sandboxApiKey = 'COnZR5PQLxLEEydMDHfYu0CsyDHqrFPAAzf8AyewyFI=';
  // private sandboxLogin = "kischuk@mailinator.com";
  // private sandboxPassword = "Test%Tripian1";

  private sandbox: boolean;

  constructor(apiUrl: string, sandbox: boolean, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.sandbox = sandbox;
    this.apiUrl = apiUrl;

    this.axios = axiosLib.create();

    // this.axios.defaults.baseURL = `${this.proxyURL}?url=${btoa(apiUrl)}`;

    this.axios.defaults.timeout = 30000; // 2500;
    this.axios.defaults.headers.common['Accept-Language'] = 'en-US';
  }

  private getImgUrl = (subUrl: string): string => {
    if (subUrl && subUrl.startsWith('http')) return subUrl;

    return this.sandbox
      ? `https://testadmin.bookbarbados.com/gptour/thumbnails/999/${subUrl}`
      : `https://admin.bookbarbados.com/gptour/thumbnails/999/${subUrl}`;
  };

  login = async (apiKey: string): Promise<void> => {
    const url = btoa(`${this.apiUrl}/authorization?apiKey=${apiKey}`);
    try {
      const authorizationResponse: Providers.Bb.AuthorizationResponse = await this.axios
        .get<Providers.Bb.AuthorizationResponse>(`${this.proxyURL}?url=${url}`)
        .then((res) => res.data);

      this.axios.defaults.headers.common.token = authorizationResponse.token;
    } catch (errorResponse) {
      // eslint-disable-next-line no-console
      console.error('booke-barbados-authorization', errorResponse);
      throw (errorResponse as any).message ?? '';
    }
  };

  searchActivities = async (startDate: string, endDate: string): Promise<Providers.Bb.SearchActivitiesResponse> => {
    // searchActivities?countryId=3114&startDate=2022-05-10&endDate=2022-05-10&adults=2&privateActivity=true&sharedActivity=true&hikingActivity=true&fieldTripActivity=true&offerLimit=0&sortBy=PRICE_LOW_TO_HIGH
    const url = btoa(
      `${this.apiUrl}/searchActivities?countryId=3114&startDate=${startDate}&endDate=${endDate}&adults=2&privateActivity=true&sharedActivity=true&hikingActivity=true&fieldTripActivity=true&offerLimit=0&sortBy=PRICE_LOW_TO_HIGH`,
    );
    return (
      this.axios
        .get<Providers.Bb.SearchActivitiesResponse>(`${this.proxyURL}?url=${url}`)
        .then((searchActivitiesResponse) => {
          const { data } = searchActivitiesResponse;
          data.products.forEach((product: Providers.Bb.Product) => {
            // eslint-disable-next-line no-param-reassign
            product.mainImage = this.getImgUrl(product.mainImage);
          });
          return data;
        })
        // eslint-disable-next-line no-console
        .catch((errorResponse) => {
          // eslint-disable-next-line no-console
          console.error('booke-barbados-searchActivitiesResponse', errorResponse);
          throw errorResponse.message;
        })
    );
  };

  searchActivity = async (startDate: string, endDate: string, activityId: number): Promise<Providers.Bb.SearchActivitiesResponse> => {
    const url = btoa(`${this.apiUrl}/searchActivities?countryId=3114&startDate=${startDate}&endDate=${endDate}&adults=2&activityId=${activityId}`);
    return (
      this.axios
        .get<Providers.Bb.SearchActivitiesResponse>(`${this.proxyURL}?url=${url}`)
        .then((searchActivitiesResponse) => {
          const { data } = searchActivitiesResponse;
          data.products.forEach((product: Providers.Bb.Product) => {
            // eslint-disable-next-line no-param-reassign
            product.mainImage = this.getImgUrl(product.mainImage);
          });
          return data;
        })
        // eslint-disable-next-line no-console
        .catch((errorResponse) => {
          // eslint-disable-next-line no-console
          console.error('book-barbados-searchActivityResponse', errorResponse);
          throw errorResponse;
        })
    );
  };

  /* activities = async (): Promise<Providers.Bb.Activity[]> => {
    return this.axios
      .get<Providers.Bb.Activity[]>('/activities')
      .then((activitiesResponse) => activitiesResponse.data)
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.error('booke-barbados-activities', errorResponse);
        throw errorResponse;
      });
  }; */

  activityInfo = async (offerKey: string): Promise<Providers.Bb.ActivityInfo> => {
    const url = btoa(`${this.apiUrl}/activityInfo?offerKey=${offerKey}`);

    return this.axios
      .get<Providers.Bb.ActivityInfo>(`${this.proxyURL}?url=${url}`)
      .then((activityInfoResponse) => {
        const { data } = activityInfoResponse;
        data.images.forEach((image: Providers.Bb.ActivityInfoImage) => {
          // eslint-disable-next-line no-param-reassign
          image.url = this.getImgUrl(image.url);
        });
        return data;
      })
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.error('booke-barbados-activityInfoResponse', errorResponse);
        throw errorResponse.message;
      });
  };

  searchAccommodation = async (
    startDate: string,
    endDate: string,
    adults: number,
    bounds: number[],
  ): Promise<Providers.Bb.SearchAccommodationResponse> => {
    // searchAccommodation?startDate=2022-05-5&endDate=2022-05-7&northEastLatitude=13.33&northEastLongitude=-59.42&southWestLatitude=13.03&southWestLongitude=-59.67&rooms=adults:2
    const url = btoa(
      `${this.apiUrl}/searchAccommodation?countryId=3114&startDate=${startDate}&endDate=${endDate}&rooms=adults:${adults}&northEastLatitude=${bounds[0]}&northEastLongitude=${bounds[1]}&southWestLatitude=${bounds[2]}&southWestLongitude=${bounds[3]}`,
    );

    return (
      this.axios
        .get<Providers.Bb.SearchAccommodationResponse>(`${this.proxyURL}?url=${url}`)
        .then((searchAccommodationResponse) => {
          const { data } = searchAccommodationResponse;
          data.hotelOffers.forEach((hoffer: Providers.Bb.SearchAccommodationHotelOffer) => {
            // eslint-disable-next-line no-param-reassign
            hoffer.info.mainImageUrl = this.getImgUrl(hoffer.info.mainImageUrl);
          });
          return data;
        })
        // eslint-disable-next-line no-console
        .catch((errorResponse) => {
          // eslint-disable-next-line no-console
          console.error('booke-barbados-searchAccommodationResponse', errorResponse);
          throw errorResponse.message;
        })
    );
  };

  searchCarRent = async (
    pickUpDateTime: string,
    dropOffDateTime: string,
    driverAge?: number,
    pickUpCityId?: number,
    dropOffCityId?: number,
  ): Promise<Providers.Bb.SearchCarRentOffer[]> => {
    // searchCarRent?pickUpCityId=166987&dropOffCityId=166987&pickUpDateTime=2022-05-10&dropOffDateTime=2022-05-21&driverAge=24&driverCitizenshipId=3114

    const url = btoa(
      `${
        this.apiUrl
      }/searchCarRent?driverCitizenshipId=3114&pickUpDateTime=${pickUpDateTime}&dropOffDateTime=${dropOffDateTime}&driverAge=${driverAge ||
        24}&pickUpCityId=${pickUpCityId || 166987}&dropOffCityId=${dropOffCityId || 166987}`,
    );

    return (
      this.axios
        .get<Providers.Bb.SearchCarRentResponse>(`${this.proxyURL}?url=${url}`)
        .then((searchCarRentResponse) => {
          const { data } = searchCarRentResponse;
          const result: Providers.Bb.SearchCarRentOffer[] = [];

          for (let k = 0; k < data.products.length; k += 1) {
            for (let i = 0; i < data.products[k].offers.length; i += 1) {
              for (let j = 0; j < data.products[k].offers[i].pickUpPoints.length; j += 1) {
                if (data.products[k].offers[i].pickUpPoints[j].latitude) {
                  const existingCarRentOffer = result.find((r) => r.locationCode === data.products[k].offers[i].pickUpPoints[j].locationCode);
                  if (existingCarRentOffer) {
                    existingCarRentOffer.images.push(
                      ...data.products[k].info.images.map((img: Providers.Bb.SearchCarRentProductInfoImage) => {
                        // eslint-disable-next-line no-param-reassign
                        img.url = this.getImgUrl(img.url);
                        return img.url;
                      }),
                    );
                  } else {
                    const carRentOffice: Providers.Bb.SearchCarRentOffer = {
                      supplierCode: data.products[k].info.supplierCode,
                      carRentCompanyName: data.products[k].info.carRentCompanyName,
                      images: [
                        ...data.products[k].info.images.map((img: Providers.Bb.SearchCarRentProductInfoImage) => {
                          // eslint-disable-next-line no-param-reassign
                          img.url = this.getImgUrl(img.url);
                          return img.url;
                        }),
                      ],
                      features: data.products[k].info.features,
                      shortDescription: data.products[k].info.shortDescription,
                      description: data.products[k].info.description,
                      locationCode: data.products[k].offers[i].pickUpPoints[j].locationCode,
                      type: data.products[k].offers[i].pickUpPoints[j].type,
                      name: data.products[k].offers[i].pickUpPoints[j].name,
                      address: data.products[k].offers[i].pickUpPoints[j].address,
                      longitude: data.products[k].offers[i].pickUpPoints[j].longitude,
                      latitude: data.products[k].offers[i].pickUpPoints[j].latitude,
                    };
                    result.push(carRentOffice);
                  }
                }
              }
            }
          }
          return result;
        })
        // eslint-disable-next-line no-console
        .catch((errorResponse) => {
          // eslint-disable-next-line no-console
          console.error('booke-barbados-searchCarRentResponse', errorResponse);
          throw errorResponse.message;
        })
    );
  };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
/* const searchAccommodationResponse: Providers.Bb.SearchAccommodationResponse = {
  searchKey: 'TfHZjSMkUtAKlAB5',
  offersCount: 5,
  filters: {
    priceFrom: 228.76,
    priceTo: 988.71,
    hotelName: [
      'Ocean Spray Apartments',
      'Coconut Court Beach Hotel',
      'Time Out Hotel',
      'Barbados Beach Club - All Inclusive',
      'Courtyard by Marriott Bridgetown',
    ],
    categories: [3],
    roomTypes: [
      'Executive Guest Room',
      'Superior Room',
      'Super Deluxe Room',
      'Pool View Room',
      'Two Bedroom Apartment',
      'Queen Guest Room',
      'King Guest Room',
      'Standard Room',
      'Honeymoon Room',
      'Studio Apartment Ocean View',
      'One Bedroom Apartment',
      'Deluxe Room',
      'King Executive Suite',
      'One Bedroom Apartment (Landside)',
      'Studio Apartment Garden View',
    ],
    mealTypes: ['All Inclusive', 'Room Only'],
    hotelServices: [
      'Gift Shop',
      'Elevators',
      'Tour/Ticket Assistance',
      'Visa Credit/Debit',
      'Beach',
      'Car rentals',
      'Business Centre',
      'Connecting/Adjoining Rooms',
      'Non-motorized watersports',
      'Assistive Listening devices available',
      'Laundry Service',
      'Swimming',
      'MasterCard Credit/Debit',
      'Self-catering',
      'Beach Shuttle',
      'Buffet',
      'Convenience store',
      'Horseback riding nearby',
      'Free Public Space Wi-Fi',
      'Reception',
      'Discover',
      'Surfing',
      'Fire Extinguisher',
      'Enhanced cleaning measures',
      'Snorkelling',
      'Surfing lessons',
      'Wheelchair Accessibility',
      'Live Entertainment',
      'Kitesurfing',
      'Island tours',
      'American Express',
      'Golf Course nearby',
      'Free Wi-Fi',
      'A la carte dining',
      'Kids Menu',
      'Concierge service',
      'First aid kit',
      'Cash',
      'Fishing',
      'Street parking',
      'Dry cleaning service',
      'Beach Loungers',
      'Snack/sandwich bar',
      'Pathways',
      'Accessible shower',
      'Tennis on site',
      'Souvenir shop',
      'Conference Equipment',
      'Roll-in shower available on request',
      'Windsurfing',
      'Currency exchange',
      'Smoke free property',
      'Boat trips',
      'Doctor on call',
      'Garden',
      'Beach Bar',
      'Parking',
      'Ecotours',
      'Spa Services available',
      'Golf Driving range nearby',
      'Restaurant',
      'Bar',
      'Cots/Cribs',
      'Fitness facilities',
      'Books/Library',
      'Free Managers Reception',
      'Meeting Room',
      'Car park',
    ],
    hotelRoomServices: [
      'Towels provided',
      'Ocean view',
      'Telephone',
      'Limited housekeeping service',
      'Free Wi-Fi',
      'Limited hours room service',
      'Microwave',
      'Iron',
      'Coffee/Tea maker',
      'Seating area with chair',
      'Sofa bed',
      'In-room safe',
      'Adjoining Room',
      'Pool view',
      'Desk',
      'Separate shower',
      'Private bathroom',
      'Air conditioning',
      'Bathroom Amenities',
      'Blackout drapes/curtains',
      'Garden view',
      'Mini-refrigerator',
      'Hairdryer',
      'Refrigerator',
      'Cable/Satellite television',
      'Tub/shower combination',
      'Partial ocean view',
      'Cribs available',
      'Kitchenette',
      'Non-smoking',
      'Eco-friendly housekeeping',
      'Ceiling Fan',
      'Television',
      'Free local calls',
      'Stove',
      'Ironing Board',
      'Linens provided',
      'Alarm Clock',
      'Daily housekeeping service',
      'Balcony/Terrace',
      'Street view',
    ],
    sharedRooms: [],
    hotelClassifications: [],
    hotelTypes: ['Motel', 'Hotel', 'All-Inclusive resort'],
  },
  processed: true,
  hotelOffers: [
    {
      info: {
        name: 'Time Out Hotel',
        address: 'Dover Christ Church',
        category: 3,
        supplierCode: 'company.2134487',
        cityId: 527499,
        cityCode: '2622476',
        cityName: 'Dover',
        countryName: 'Barbados',
        hotelCode: 'TOHSG',
        latitude: '13.06683',
        longitude: '-59.56745',
        mainImageUrl: 'hotel-images2/1/b/9/1b91f865caa14f7e93ef97223f4f223f.jpg',
        hotelType: 'LIVING',
        offerServicesForFilter: [],
        ratings: [
          {
            ratingSource: 'INTERNAL',
            rating: 875.0,
          },
        ],
        hotelServices: [
          {
            supplierServiceName: 'Car park',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Car rentals',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Concierge service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Doctor on call',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Dry cleaning service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Street parking',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Wi-Fi',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'American Express',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cash',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Discover',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'MasterCard Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Visa Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Boat trips',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Convenience store',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Ecotours',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Course nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Driving range nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Swimming',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Enhanced cleaning measures',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'First aid kit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Public Space Wi-Fi',
            groupName: 'Property Amenities',
          },
        ],
        hotelClassifications: [],
        type: 'Motel',
        typeOTAName: 'Motel',
        recommended: false,
      },
      roomOffers: [
        {
          offerKey: 'fl25LqorL2GCOvP3',
          cancellations: [
            {
              dateFrom: '2022-05-03 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 420.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 420.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 420.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 420.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 428.76,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 175.0,
                    originalCurrency: 'USD',
                    originalAmount: 175.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 175.0,
                    originalCurrency: 'USD',
                    originalAmount: 175.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 35.0,
                    originalCurrency: 'USD',
                    originalAmount: 35.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 35.0,
                    originalCurrency: 'USD',
                    originalAmount: 35.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Superior Room',
            roomTypeServiceId: 2134649,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2134652,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920199',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Twin Bed',
                quantity: 2,
                alternate: true,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Balcony/Terrace',
            'Cable/Satellite television',
            'Air conditioning',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Daily housekeeping service',
            'Cribs available',
            'Telephone',
            'Towels provided',
            'Television',
            'Private bathroom',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'TOHSG',
          hotelSupplierCode: 'company.2134487',
          hotelSupplierId: 2134487,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: '5RNyPntrYAi6g0UZ',
          cancellations: [
            {
              dateFrom: '2022-05-03 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 420.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 420.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 420.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 420.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 428.76,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 175.0,
                    originalCurrency: 'USD',
                    originalAmount: 175.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 175.0,
                    originalCurrency: 'USD',
                    originalAmount: 175.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 35.0,
                    originalCurrency: 'USD',
                    originalAmount: 35.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 35.0,
                    originalCurrency: 'USD',
                    originalAmount: 35.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Standard Room',
            roomTypeServiceId: 2134651,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2134652,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920198',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Twin Bed',
                quantity: 2,
                alternate: true,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Cable/Satellite television',
            'Air conditioning',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Daily housekeeping service',
            'Cribs available',
            'Telephone',
            'Towels provided',
            'Television',
            'Separate shower',
            'Private bathroom',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'TOHSG',
          hotelSupplierCode: 'company.2134487',
          hotelSupplierId: 2134487,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'e95XF31XitwzLlqa',
          cancellations: [
            {
              dateFrom: '2022-05-03 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 460.8,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 460.8,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 460.8,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 460.8,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 469.56,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 192.0,
                    originalCurrency: 'USD',
                    originalAmount: 192.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 192.0,
                    originalCurrency: 'USD',
                    originalAmount: 192.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 38.4,
                    originalCurrency: 'USD',
                    originalAmount: 38.4,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 38.4,
                    originalCurrency: 'USD',
                    originalAmount: 38.4,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Pool View Room',
            roomTypeServiceId: 2134648,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2134652,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920200',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Double Bed',
                quantity: 2,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Balcony/Terrace',
            'Cable/Satellite television',
            'Air conditioning',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Daily housekeeping service',
            'Cribs available',
            'Telephone',
            'Towels provided',
            'Television',
            'Private bathroom',
            'Pool view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'TOHSG',
          hotelSupplierCode: 'company.2134487',
          hotelSupplierId: 2134487,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'D2LUpVPYZyYMyhrX',
          cancellations: [
            {
              dateFrom: '2022-05-03 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 528.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 528.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 528.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 528.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 536.76,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 220.0,
                    originalCurrency: 'USD',
                    originalAmount: 220.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 220.0,
                    originalCurrency: 'USD',
                    originalAmount: 220.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 44.0,
                    originalCurrency: 'USD',
                    originalAmount: 44.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 44.0,
                    originalCurrency: 'USD',
                    originalAmount: 44.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Deluxe Room',
            roomTypeServiceId: 2134646,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2134652,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920201',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Double Bed',
                quantity: 2,
                alternate: false,
              },
              {
                type: 'King Bed',
                quantity: 1,
                alternate: true,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Balcony/Terrace',
            'Cable/Satellite television',
            'Air conditioning',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Daily housekeeping service',
            'Cribs available',
            'Telephone',
            'Street view',
            'Towels provided',
            'Television',
            'Private bathroom',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'TOHSG',
          hotelSupplierCode: 'company.2134487',
          hotelSupplierId: 2134487,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
      ],
      bookAllowed: false,
    },
    {
      info: {
        name: 'Coconut Court Beach Hotel',
        address: 'Garrison Historic Area, Hastings, Christ Church',
        category: 3,
        supplierCode: 'company.1810972',
        cityId: 166988,
        cityCode: '166988',
        cityName: 'Hastings',
        countryName: 'Barbados',
        hotelCode: 'CCBH',
        latitude: '13.07614',
        longitude: '-59.60205',
        mainImageUrl: 'hotel-images2/5/0/5/505f26225ff94310889840f15d83eaf2.jpg',
        hotelType: 'LIVING',
        offerServicesForFilter: [],
        ratings: [
          {
            ratingSource: 'INTERNAL',
            rating: 820.0,
          },
          {
            ratingSource: 'TRIPADVISOR',
            rating: 0.0,
            reviewsURL:
              'https://www.tripadvisor.com/Hotel_Review-g666611-d150723-Reviews-Coconut_Court_Beach_Hotel-Hastings_Christ_Church_Parish_Barbados.html',
          },
        ],
        hotelServices: [
          {
            supplierServiceName: 'Car park',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Car rentals',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Concierge service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Currency exchange',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Garden',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Tour/Ticket Assistance',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Business Centre',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Connecting/Adjoining Rooms',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Kids Menu',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'A la carte dining',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Self-catering',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Discover',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'MasterCard Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Visa Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Fishing',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Gift Shop',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Island tours',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Non-motorized watersports',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Snorkelling',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Souvenir shop',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Spa Services available',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Surfing lessons',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Swimming',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Enhanced cleaning measures',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Fire Extinguisher',
            groupName: 'Property Amenities',
          },
        ],
        hotelClassifications: [],
        type: 'Hotel',
        typeOTAName: 'Hotel',
        recommended: false,
      },
      roomOffers: [
        {
          offerKey: 'qDH81BJZO7U0igSg',
          cancellations: [
            {
              dateFrom: '2022-05-02 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 220.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 220.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 220.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 220.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 239.3,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 100.0,
                    originalCurrency: 'USD',
                    originalAmount: 100.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 100.0,
                    originalCurrency: 'USD',
                    originalAmount: 100.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 19.3,
                    originalCurrency: 'USD',
                    originalAmount: 19.3,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 20.0,
                    originalCurrency: 'USD',
                    originalAmount: 20.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'One Bedroom Apartment (Landside)',
            roomTypeServiceId: 1811149,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 1811158,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920083',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Ceiling Fan',
            'Cable/Satellite television',
            'Blackout drapes/curtains',
            'Air conditioning',
            'Adjoining Room',
            'Linens provided',
            'Limited hours room service',
            'Kitchenette',
            'Eco-friendly housekeeping',
            'In-room safe',
            'Ironing Board',
            'Iron',
            'Free Wi-Fi',
            'Free local calls',
            'Hairdryer',
            'Daily housekeeping service',
            'Cribs available',
            'Coffee/Tea maker',
            'Desk',
            'Telephone',
            'Tub/shower combination',
            'Towels provided',
            'Television',
            'Ocean view',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'CCBH',
          hotelSupplierCode: 'company.1810972',
          hotelSupplierId: 1810972,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
      ],
      bookAllowed: false,
    },
    {
      info: {
        name: 'Ocean Spray Apartments',
        address: 'Surfers Point, Inch Marlow, Christ Church',
        category: 3,
        supplierCode: 'company.2131589',
        cityId: 501622,
        cityCode: '812044',
        cityName: 'Inch Marlow',
        countryName: 'Barbados',
        hotelCode: 'OSBA',
        latitude: '13.05242',
        longitude: '-59.50734',
        mainImageUrl: 'hotel-images2/1/d/7/1d74d890a8284a0fb89d7a490fc4cd9c.jpg',
        hotelType: 'LIVING',
        offerServicesForFilter: [],
        ratings: [
          {
            ratingSource: 'INTERNAL',
            rating: 723.0,
          },
        ],
        hotelServices: [
          {
            supplierServiceName: 'Books/Library',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Car rentals',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Currency exchange',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Doctor on call',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Tour/Ticket Assistance',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Wi-Fi',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Self-catering',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cash',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'MasterCard Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Visa Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach Loungers',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Course nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Kitesurfing',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Surfing',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Surfing lessons',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Windsurfing',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Public Space Wi-Fi',
            groupName: 'Property Amenities',
          },
        ],
        hotelClassifications: [],
        type: 'Motel',
        typeOTAName: 'Motel',
        recommended: false,
      },
      roomOffers: [
        {
          offerKey: 'hWv55pEu5aXWWNie',
          cancellations: [
            {
              dateFrom: '2022-04-28 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 220.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 220.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 220.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 220.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 228.76,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 125.0,
                    originalCurrency: 'USD',
                    originalAmount: 125.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 75.0,
                    originalCurrency: 'USD',
                    originalAmount: 75.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 20.0,
                    originalCurrency: 'USD',
                    originalAmount: 20.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Studio Apartment Garden View',
            roomTypeServiceId: 2131753,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2131754,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920194',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Queen Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Ceiling Fan',
            'Linens provided',
            'Limited housekeeping service',
            'Eco-friendly housekeeping',
            'Free Wi-Fi',
            'Hairdryer',
            'Garden view',
            'Telephone',
            'Stove',
            'Towels provided',
            'Refrigerator',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'OSBA',
          hotelSupplierCode: 'company.2131589',
          hotelSupplierId: 2131589,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'ORGahO7PCnFsRelS',
          cancellations: [
            {
              dateFrom: '2022-04-28 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 253.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 253.0,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 253.0,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 253.0,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 261.76,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 140.0,
                    originalCurrency: 'USD',
                    originalAmount: 140.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 90.0,
                    originalCurrency: 'USD',
                    originalAmount: 90.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 23.0,
                    originalCurrency: 'USD',
                    originalAmount: 23.0,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Studio Apartment Ocean View',
            roomTypeServiceId: 2131752,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2131754,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920195',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Queen Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Ceiling Fan',
            'Linens provided',
            'Limited housekeeping service',
            'Eco-friendly housekeeping',
            'Free Wi-Fi',
            'Hairdryer',
            'Telephone',
            'Stove',
            'Towels provided',
            'Refrigerator',
            'Ocean view',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'OSBA',
          hotelSupplierCode: 'company.2131589',
          hotelSupplierId: 2131589,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'bUNplPgkvmYPKSp0',
          cancellations: [
            {
              dateFrom: '2022-04-28 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 313.5,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 313.5,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 313.5,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 313.5,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 322.26,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 175.0,
                    originalCurrency: 'USD',
                    originalAmount: 175.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 110.0,
                    originalCurrency: 'USD',
                    originalAmount: 110.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 28.5,
                    originalCurrency: 'USD',
                    originalAmount: 28.5,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'One Bedroom Apartment',
            roomTypeServiceId: 2131750,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2131754,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920196',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Queen Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Double Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Ceiling Fan',
            'Linens provided',
            'Limited housekeeping service',
            'Eco-friendly housekeeping',
            'Free Wi-Fi',
            'Hairdryer',
            'Telephone',
            'Stove',
            'Towels provided',
            'Refrigerator',
            'Ocean view',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'OSBA',
          hotelSupplierCode: 'company.2131589',
          hotelSupplierId: 2131589,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'PC6q8R5b3hQF7HPt',
          cancellations: [
            {
              dateFrom: '2022-04-28 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 412.5,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 412.5,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 412.5,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 412.5,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 430.02,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 225.0,
                    originalCurrency: 'USD',
                    originalAmount: 225.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 150.0,
                    originalCurrency: 'USD',
                    originalAmount: 150.0,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 17.52,
                    originalCurrency: 'USD',
                    originalAmount: 17.52,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 37.5,
                    originalCurrency: 'USD',
                    originalAmount: 37.5,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Two Bedroom Apartment',
            roomTypeServiceId: 2131748,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2131754,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920197',
            bedroomsQuantity: 2,
            bathroomsQuantity: 2,
            bedTypes: [
              {
                type: 'Queen Bed',
                quantity: 2,
                alternate: false,
              },
              {
                type: 'Sofa Bed Double',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Ceiling Fan',
            'Linens provided',
            'Limited housekeeping service',
            'Eco-friendly housekeeping',
            'Free Wi-Fi',
            'Hairdryer',
            'Telephone',
            'Stove',
            'Towels provided',
            'Refrigerator',
            'Ocean view',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'OSBA',
          hotelSupplierCode: 'company.2131589',
          hotelSupplierId: 2131589,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
      ],
      bookAllowed: false,
    },
    {
      info: {
        name: 'Barbados Beach Club - All Inclusive',
        address: 'Maxwell Coast Road, Christ Church',
        category: 3,
        supplierCode: 'company.2130149',
        cityId: 166990,
        cityCode: '166990',
        cityName: 'Maxwell',
        countryName: 'Barbados',
        hotelCode: 'BBC',
        latitude: '13.06594',
        longitude: '-59.55836',
        mainImageUrl: 'hotel-images2/9/1/5/9158f07aecbc477db11080604962b48b.jpg',
        hotelType: 'LIVING',
        offerServicesForFilter: [],
        ratings: [
          {
            ratingSource: 'INTERNAL',
            rating: 623.0,
          },
          {
            ratingSource: 'TRIPADVISOR',
            rating: 0.0,
            reviewsURL: 'https://www.tripadvisor.com/Hotel_Review-g147266-d150711-Reviews-Barbados_Beach_Club-Christ_Church_Parish_Barbados.html',
          },
        ],
        hotelServices: [
          {
            supplierServiceName: 'Car park',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Concierge service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Currency exchange',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Doctor on call',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Dry cleaning service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Laundry Service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Live Entertainment',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Tour/Ticket Assistance',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Conference Equipment',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Wi-Fi',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Meeting Room',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Connecting/Adjoining Rooms',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cots/Cribs',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Buffet',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Managers Reception',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'American Express',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cash',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'MasterCard Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Visa Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach Loungers',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Gift Shop',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Course nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Driving range nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Non-motorized watersports',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Swimming',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Tennis on site',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Parking',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Pathways',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Reception',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Restaurant',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Wheelchair Accessibility',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Assistive Listening devices available',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Roll-in shower available on request',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Free Public Space Wi-Fi',
            groupName: 'Property Amenities',
          },
        ],
        hotelClassifications: [],
        type: 'All-Inclusive resort',
        typeOTAName: 'All-Inclusive resort',
        recommended: false,
      },
      roomOffers: [
        {
          offerKey: 'YIVTItwn3dihKu6x',
          cancellations: [
            {
              dateFrom: '2022-05-04 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 935.3,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 935.3,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 935.3,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 935.3,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 944.06,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 398.0,
                    originalCurrency: 'USD',
                    originalAmount: 398.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 398.0,
                    originalCurrency: 'USD',
                    originalAmount: 398.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 59.7,
                    originalCurrency: 'USD',
                    originalAmount: 59.7,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 79.6,
                    originalCurrency: 'USD',
                    originalAmount: 79.6,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Deluxe Room',
            roomTypeServiceId: 2130311,
            mealTypes: [
              {
                otaCode: 1,
                otaName: 'All inclusive',
                standardCode: 'AI',
                standardName: 'All inclusive',
                name: 'All Inclusive',
                id: 2130312,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920191',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Double Bed',
                quantity: 2,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Cable/Satellite television',
            'Air conditioning',
            'Adjoining Room',
            'Linens provided',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Hairdryer',
            'Daily housekeeping service',
            'Telephone',
            'Tub/shower combination',
            'Towels provided',
            'Television',
            'Ocean view',
            'Non-smoking',
            'Pool view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'BBC',
          hotelSupplierCode: 'company.2130149',
          hotelSupplierId: 2130149,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'gUKwQKG57hWcTddM',
          cancellations: [
            {
              dateFrom: '2022-05-04 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 968.2,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 968.2,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 968.2,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 968.2,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 976.96,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 412.0,
                    originalCurrency: 'USD',
                    originalAmount: 412.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 412.0,
                    originalCurrency: 'USD',
                    originalAmount: 412.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 61.8,
                    originalCurrency: 'USD',
                    originalAmount: 61.8,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 82.4,
                    originalCurrency: 'USD',
                    originalAmount: 82.4,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Super Deluxe Room',
            roomTypeServiceId: 2130310,
            mealTypes: [
              {
                otaCode: 1,
                otaName: 'All inclusive',
                standardCode: 'AI',
                standardName: 'All inclusive',
                name: 'All Inclusive',
                id: 2130312,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920192',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Queen Bed',
                quantity: 1,
                alternate: true,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Cable/Satellite television',
            'Air conditioning',
            'Adjoining Room',
            'Linens provided',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Hairdryer',
            'Daily housekeeping service',
            'Telephone',
            'Tub/shower combination',
            'Towels provided',
            'Television',
            'Ocean view',
            'Non-smoking',
            'Pool view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'BBC',
          hotelSupplierCode: 'company.2130149',
          hotelSupplierId: 2130149,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'AZBfhX3II7fQO419',
          cancellations: [
            {
              dateFrom: '2022-05-04 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 979.95,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 979.95,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 979.95,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 979.95,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 988.71,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 417.0,
                    originalCurrency: 'USD',
                    originalAmount: 417.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 417.0,
                    originalCurrency: 'USD',
                    originalAmount: 417.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 62.55,
                    originalCurrency: 'USD',
                    originalAmount: 62.55,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 83.4,
                    originalCurrency: 'USD',
                    originalAmount: 83.4,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 8.76,
                    originalCurrency: 'USD',
                    originalAmount: 8.76,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Honeymoon Room',
            roomTypeServiceId: 2130308,
            mealTypes: [
              {
                otaCode: 1,
                otaName: 'All inclusive',
                standardCode: 'AI',
                standardName: 'All inclusive',
                name: 'All Inclusive',
                id: 2130312,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920193',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Queen Bed',
                quantity: 1,
                alternate: true,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Cable/Satellite television',
            'Air conditioning',
            'Adjoining Room',
            'Linens provided',
            'Mini-refrigerator',
            'In-room safe',
            'Free Wi-Fi',
            'Hairdryer',
            'Daily housekeeping service',
            'Telephone',
            'Tub/shower combination',
            'Towels provided',
            'Television',
            'Ocean view',
            'Non-smoking',
            'Pool view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'BBC',
          hotelSupplierCode: 'company.2130149',
          hotelSupplierId: 2130149,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
      ],
      bookAllowed: false,
    },
    {
      info: {
        name: 'Courtyard by Marriott Bridgetown',
        address: 'The Garrison Historic Area, Hastings, Christ Church',
        category: 3,
        supplierCode: 'company.2127533',
        cityId: 166988,
        cityCode: '166988',
        cityName: 'Hastings',
        countryName: 'Barbados',
        hotelCode: 'CMBB',
        latitude: '13.07662',
        longitude: '-59.60137',
        mainImageUrl: 'hotel-images2/8/4/3/8435947fa3cf4c2ba7d061c77315a83e.jpg',
        hotelType: 'LIVING',
        offerServicesForFilter: [],
        ratings: [
          {
            ratingSource: 'INTERNAL',
            rating: 500.0,
          },
          {
            ratingSource: 'TRIPADVISOR',
            rating: 0.0,
            reviewsURL:
              'https://www.tripadvisor.com/Hotel_Review-g147263-d1966392-Reviews-Courtyard_by_Marriott_Bridgetown_Barbados-Bridgetown_Saint_Michael_Parish_Barbados.html',
          },
        ],
        hotelServices: [
          {
            supplierServiceName: 'Car park',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Car rentals',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Currency exchange',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Doctor on call',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Dry cleaning service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Laundry Service',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Smoke free property',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Tour/Ticket Assistance',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Conference Equipment',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Free Wi-Fi',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Connecting/Adjoining Rooms',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cots/Cribs',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Kids Menu',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Snack/sandwich bar',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'American Express',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Cash',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Discover',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'MasterCard Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Visa Credit/Debit',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Beach Shuttle',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Convenience store',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Ecotours',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Fitness facilities',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Golf Course nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Horseback riding nearby',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Enhanced cleaning measures',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Fire Extinguisher',
            groupName: 'Property Amenities',
          },
          {
            supplierServiceName: 'Meeting Room',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Parking',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Wheelchair Accessibility',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Elevators',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Accessible shower',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Roll-in shower available on request',
            groupName: 'Accessibility',
          },
          {
            supplierServiceName: 'Free Public Space Wi-Fi',
            groupName: 'Property Amenities',
          },
        ],
        hotelClassifications: [],
        type: 'Hotel',
        typeOTAName: 'Hotel',
        recommended: false,
      },
      roomOffers: [
        {
          offerKey: 'NCB59Cx8SN2gpbqB',
          cancellations: [
            {
              dateFrom: '2022-04-11 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 212.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 212.6,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 212.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 212.6,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 370.86,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 154.0,
                    originalCurrency: 'USD',
                    originalAmount: 154.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 139.0,
                    originalCurrency: 'USD',
                    originalAmount: 139.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 29.3,
                    originalCurrency: 'USD',
                    originalAmount: 29.3,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 19.26,
                    originalCurrency: 'USD',
                    originalAmount: 19.26,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 29.3,
                    originalCurrency: 'USD',
                    originalAmount: 29.3,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'King Guest Room',
            roomTypeServiceId: 2127697,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2127698,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920187',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
              {
                type: 'Sofa Bed Double',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Alarm Clock',
            'Cable/Satellite television',
            'Blackout drapes/curtains',
            'Linens provided',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Ironing Board',
            'Iron',
            'Free Wi-Fi',
            'Hairdryer',
            'Cribs available',
            'Coffee/Tea maker',
            'Desk',
            'Telephone',
            'Street view',
            'Towels provided',
            'Television',
            'Sofa bed',
            'Private bathroom',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'CMBB',
          hotelSupplierCode: 'company.2127533',
          hotelSupplierId: 2127533,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'OwpPGsNeu1eraqFN',
          cancellations: [
            {
              dateFrom: '2022-04-11 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 212.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 212.6,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 212.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 212.6,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 370.86,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 154.0,
                    originalCurrency: 'USD',
                    originalAmount: 154.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 139.0,
                    originalCurrency: 'USD',
                    originalAmount: 139.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 29.3,
                    originalCurrency: 'USD',
                    originalAmount: 29.3,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 19.26,
                    originalCurrency: 'USD',
                    originalAmount: 19.26,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 29.3,
                    originalCurrency: 'USD',
                    originalAmount: 29.3,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Queen Guest Room',
            roomTypeServiceId: 2127696,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2127698,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920188',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'Queen Bed',
                quantity: 2,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Alarm Clock',
            'Cable/Satellite television',
            'Blackout drapes/curtains',
            'Linens provided',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Ironing Board',
            'Iron',
            'Free Wi-Fi',
            'Hairdryer',
            'Cribs available',
            'Coffee/Tea maker',
            'Desk',
            'Telephone',
            'Street view',
            'Towels provided',
            'Television',
            'Private bathroom',
            'Non-smoking',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'CMBB',
          hotelSupplierCode: 'company.2127533',
          hotelSupplierId: 2127533,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: 'DbjzMTUYNwXSBAEV',
          cancellations: [
            {
              dateFrom: '2022-04-11 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 242.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 242.6,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 242.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 242.6,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 430.86,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 174.0,
                    originalCurrency: 'USD',
                    originalAmount: 174.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 169.0,
                    originalCurrency: 'USD',
                    originalAmount: 169.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 34.3,
                    originalCurrency: 'USD',
                    originalAmount: 34.3,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 19.26,
                    originalCurrency: 'USD',
                    originalAmount: 19.26,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 34.3,
                    originalCurrency: 'USD',
                    originalAmount: 34.3,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'Executive Guest Room',
            roomTypeServiceId: 2127694,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2127698,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920189',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Cable/Satellite television',
            'Blackout drapes/curtains',
            'Linens provided',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'In-room safe',
            'Ironing Board',
            'Iron',
            'Free Wi-Fi',
            'Hairdryer',
            'Cribs available',
            'Coffee/Tea maker',
            'Desk',
            'Telephone',
            'Towels provided',
            'Television',
            'Private bathroom',
            'Non-smoking',
            'Partial ocean view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'CMBB',
          hotelSupplierCode: 'company.2127533',
          hotelSupplierId: 2127533,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
        {
          offerKey: '8L53kHZDkItKxFiy',
          cancellations: [
            {
              dateFrom: '2022-04-11 00:00',
              dateTo: '2022-05-05 23:59',
              price: {
                currency: 'USD',
                amount: 290.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 290.6,
              },
              noShow: false,
            },
            {
              price: {
                currency: 'USD',
                amount: 290.6,
              },
              convertedPrice: {
                currency: 'USD',
                amount: 290.6,
              },
              noShow: true,
            },
          ],
          taxesAndFeesIncluded: false,
          salesTerms: [
            {
              type: 'CLIENT',
              price: {
                currency: 'USD',
                amount: 508.86,
                taxesAndFees: [],
              },
              originalSupplierCurrency: 'USD',
              promoCodeApplied: false,
              priceBreakDowns: [
                {
                  date: '2022-05-05',
                  weekDayNumber: 4,
                  weekDayName: 'Thursday',
                  price: {
                    currency: 'USD',
                    amount: 209.0,
                    originalCurrency: 'USD',
                    originalAmount: 209.0,
                  },
                },
                {
                  date: '2022-05-06',
                  weekDayNumber: 5,
                  weekDayName: 'Friday',
                  price: {
                    currency: 'USD',
                    amount: 199.0,
                    originalCurrency: 'USD',
                    originalAmount: 199.0,
                  },
                },
                {
                  name: 'Service Charge',
                  price: {
                    currency: 'USD',
                    amount: 40.8,
                    originalCurrency: 'USD',
                    originalAmount: 40.8,
                  },
                },
                {
                  name: 'Room Rate Levy',
                  price: {
                    currency: 'USD',
                    amount: 19.26,
                    originalCurrency: 'USD',
                    originalAmount: 19.26,
                  },
                },
                {
                  name: 'VAT',
                  price: {
                    currency: 'USD',
                    amount: 40.8,
                    originalCurrency: 'USD',
                    originalAmount: 40.8,
                  },
                },
              ],
            },
          ],
          rph: '1',
          guests: {
            adults: 2,
            ages: [],
          },
          available: false,
          info: {
            roomType: 'King Executive Suite',
            roomTypeServiceId: 2127692,
            mealTypes: [
              {
                otaCode: 14,
                otaName: 'Room only\t',
                standardCode: 'RO',
                standardName: 'Room only',
                name: 'Room Only',
                id: 2127698,
              },
            ],
            mealOptionsAvailable: false,
            supplierRoomTypeCode: 'SRTC_1920190',
            bedroomsQuantity: 1,
            bathroomsQuantity: 1,
            bedTypes: [
              {
                type: 'King Bed',
                quantity: 1,
                alternate: false,
              },
            ],
          },
          nonRefundable: true,
          roomServices: [
            'Bathroom Amenities',
            'Balcony/Terrace',
            'Alarm Clock',
            'Cable/Satellite television',
            'Blackout drapes/curtains',
            'Linens provided',
            'Kitchenette',
            'Eco-friendly housekeeping',
            'Mini-refrigerator',
            'Microwave',
            'In-room safe',
            'Ironing Board',
            'Iron',
            'Free Wi-Fi',
            'Hairdryer',
            'Cribs available',
            'Coffee/Tea maker',
            'Desk',
            'Telephone',
            'Towels provided',
            'Television',
            'Seating area with chair',
            'Private bathroom',
            'Non-smoking',
            'Partial ocean view',
          ],
          extraServices: true,
          packageOnly: false,
          hotelCode: 'CMBB',
          hotelSupplierCode: 'company.2127533',
          hotelSupplierId: 2127533,
          promoCode: false,
          hotDeal: false,
          percentDiscount: false,
          specialOffer: false,
          externalDiscount: false,
          freeNightsDiscount: false,
          cardGuarantee: false,
          onExtraBed: [],
          onWithoutPlace: [],
        },
      ],
      bookAllowed: false,
    },
  ],
}; */

export default API;
