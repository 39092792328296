/* eslint-disable import/no-extraneous-dependencies */
import React from 'react';
import classes from './SwitchIcon.scss';

interface ISwitchIcon {
  fill?: string;
  onClick?: (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void;
}

const SwitchIcon: React.FC<ISwitchIcon> = ({ fill, onClick }) => (
  <svg className={classes.switchIcon} fill={fill} viewBox="0 0 100 100" height="2.25rem" onClick={onClick}>
    <path d="M5.964,71.747c-1.285,1.284-1.285,3.367,0,4.652l9.824,9.823c0.16,0.161,0.336,0.305,0.525,0.431  c0.035,0.023,0.074,0.038,0.109,0.059c0.148,0.089,0.298,0.174,0.458,0.24c0.062,0.025,0.127,0.038,0.188,0.058  c0.14,0.048,0.278,0.097,0.426,0.126c0.097,0.019,0.196,0.02,0.295,0.03c0.113,0.012,0.223,0.035,0.339,0.035  c0.106,0,0.206-0.021,0.308-0.031c0.109-0.011,0.22-0.012,0.329-0.033c0.133-0.027,0.259-0.073,0.387-0.115  c0.075-0.025,0.153-0.04,0.228-0.071c0.14-0.058,0.27-0.135,0.4-0.211c0.055-0.032,0.114-0.054,0.167-0.09  c0.185-0.123,0.357-0.263,0.512-0.419l9.833-9.833c1.285-1.284,1.285-3.367,0-4.652c-1.283-1.285-3.367-1.285-4.652,0l-4.223,4.223  V51.012c0-1.817-1.473-3.29-3.29-3.29c-1.817,0-3.29,1.473-3.29,3.29v24.954l-4.222-4.221C9.331,70.46,7.247,70.46,5.964,71.747z" />
    <path d="M94.036,28.253c1.285-1.284,1.285-3.367,0-4.652l-9.824-9.823c-0.16-0.161-0.336-0.305-0.525-0.431  c-0.035-0.023-0.074-0.038-0.109-0.059c-0.148-0.089-0.298-0.174-0.458-0.24c-0.062-0.025-0.127-0.038-0.188-0.058  c-0.14-0.048-0.278-0.097-0.426-0.126c-0.097-0.019-0.196-0.02-0.295-0.03c-0.113-0.012-0.223-0.035-0.339-0.035  c-0.106,0-0.206,0.021-0.308,0.031c-0.109,0.011-0.22,0.012-0.329,0.033c-0.133,0.027-0.259,0.073-0.387,0.115  c-0.075,0.025-0.153,0.04-0.228,0.071c-0.14,0.058-0.27,0.135-0.4,0.211c-0.055,0.032-0.114,0.054-0.167,0.09  c-0.185,0.123-0.357,0.263-0.512,0.419l-9.833,9.833c-1.285,1.284-1.285,3.367,0,4.652c1.283,1.285,3.367,1.285,4.652,0l4.223-4.223  v24.956c0,1.817,1.473,3.29,3.29,3.29s3.29-1.473,3.29-3.29V24.034l4.222,4.221C90.669,29.54,92.753,29.54,94.036,28.253z" />
    <g>
      <path d="M31.074,62.906c0-1.903,1.543-3.446,3.446-3.446h31.01c1.903,0,3.446,1.543,3.446,3.446c0,1.903-1.543,3.446-3.446,3.446   H34.52C32.617,66.351,31.074,64.809,31.074,62.906z" />
    </g>
    <g>
      <path d="M31.074,49.446c0-1.903,1.543-3.446,3.446-3.446h31.01c1.903,0,3.446,1.543,3.446,3.446c0,1.903-1.543,3.446-3.446,3.446   H34.52C32.617,52.892,31.074,51.349,31.074,49.446z" />
    </g>
    <g>
      <path d="M31.074,35.987c0-1.903,1.543-3.446,3.446-3.446h31.01c1.903,0,3.446,1.543,3.446,3.446s-1.543,3.446-3.446,3.446H34.52   C32.617,39.432,31.074,37.89,31.074,35.987z" />
    </g>
  </svg>
);

export default SwitchIcon;
