import Model, { Providers } from "@tripian/model";
import React, { useState, useEffect, useMemo } from "react";
import moment from "moment";
import { TextField, Button, Dropdown, ErrorMessage } from "@tripian/react";
import isoLanguages from "./isoLanguages";
import TravelSummary from "../TravelSummary/TravelSummary";

interface IViatorBookingActivityDetails {
  tourName: string;
  tourImage: string;
  travelDate?: string;
  travelHour?: string;
  travelPrice?: number;
  active: boolean;
  bookingQuestions?: Providers.Viator.BookingQuestion[];
  questionAnswers?: Providers.Viator.BookingQuestionAnswer[];
  travelerCount: number;
  languageGuides: Providers.Viator.LanguageGuide[];
  logistics?: Providers.Viator.Logistics;
  isEditable: boolean;
  handleEditActivityDetails: () => void;
  clicked: ({
    bookingQuestionAnswers,
    languageGuide,
  }: {
    bookingQuestionAnswers: Providers.Viator.BookingQuestionAnswer[];
    languageGuide?: Providers.Viator.LanguageGuide;
  }) => void;
  t: (value: Model.TranslationKey) => string;
}

const ViatorBookingActivityDetails: React.FC<IViatorBookingActivityDetails> = ({
  tourName,
  tourImage,
  travelDate,
  travelHour,
  travelPrice,
  active,
  bookingQuestions,
  questionAnswers,
  travelerCount,
  languageGuides,
  logistics,
  isEditable,
  handleEditActivityDetails,
  clicked,
  t,
}) => {
  moment.locale(window.twindow.langCode);

  const [languageGuide, setLanguageGuide] = useState<Providers.Viator.LanguageGuide>({ type: "", language: "" });
  const [bookingQuestionAnswers, setBookingQuestionAnswers] = useState<Providers.Viator.BookingQuestionAnswer[]>(questionAnswers || []);
  const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

  // Validation function
  const validateInput = (question: Providers.Viator.BookingQuestion, value: string | number): string | null => {
    if (question.required === "MANDATORY" && !value) {
      return `${question.label} ${t("trips.myTrips.localExperiences.tourDetails.isRequired")}.`;
    }
    if (question.type === "STRING" && typeof value === "string" && value.length > question.maxLength) {
      return `${t("trips.myTrips.localExperiences.tourDetails.characterLimitExceeded")}. (${t("trips.myTrips.localExperiences.tourDetails.max")}: ${question.maxLength})`;
    }
    if (question.type === "NUMBER_AND_UNIT" && isNaN(Number(value))) {
      return `${question.label} ${t("trips.myTrips.localExperiences.tourDetails.mustBeAValidNumber")}.`;
    }
    if (question.type === "TIME") {
      if (!moment(value, "HH:mm", true).isValid()) {
        return `${question.label} ${t("trips.myTrips.localExperiences.tourDetails.mustBeAValidTime")}.`;
      }
    }

    if (question.type === "LOCATION_REF_OR_FREE_TEXT") {
      if (value === "") {
        return `${question.label} ${t("trips.myTrips.localExperiences.tourDetails.isRequired")}.`;
      }
      if (typeof value === "string" && value.length > question.maxLength) {
        return `${t("trips.myTrips.localExperiences.tourDetails.characterLimitExceeded")}. (${t("trips.myTrips.localExperiences.tourDetails.max")}: ${question.maxLength})`;
      }
    }
    return null; // No validation error
  };

  // Function to handle form data changes with travelerNum flag
  const handleInputChange = (id: string, value: any, travelerNum?: number, unit?: string) => {
    setBookingQuestionAnswers((prev) => {
      const existingAnswerIndex = prev.findIndex((answer) => answer.question === id && (travelerNum ? answer.travelerNum === travelerNum : true));

      const newAnswer: Providers.Viator.BookingQuestionAnswer = {
        question: id,
        answer: value,
        ...(unit && { unit }), // Add unit if provided
        ...(travelerNum && { travelerNum }), // Add travelerNum if provided
      };

      if (existingAnswerIndex !== -1) {
        const updatedAnswers = [...prev];
        updatedAnswers[existingAnswerIndex] = newAnswer;
        return updatedAnswers;
      }

      return [...prev, newAnswer];
    });

    // Perform validation and set validation error
    const question = bookingQuestions?.find((q) => q.id === id);
    if (question) {
      const validationError = validateInput(question, value);
      setValidationErrors((prevErrors) => ({
        ...prevErrors,
        [id]: validationError || "", // Set validation error for the current input or remove it if valid
      }));
    }
  };

  // Automatically preselect the first allowed answer for questions that have allowed answers for each traveler
  useEffect(() => {
    const newAnswers = [...bookingQuestionAnswers];

    // Loop through each traveler and each question
    if (bookingQuestions && bookingQuestions.length > 0) {
      // PER_TRAVELER
      for (let travelerNum = 1; travelerNum <= travelerCount; travelerNum++) {
        bookingQuestions?.forEach((question) => {
          if (question.type === "STRING" && question.allowedAnswers && question.allowedAnswers.length && question.group === "PER_TRAVELER") {
            const existingAnswerIndex = newAnswers.findIndex((answer) => answer.question === question.id && answer.travelerNum === travelerNum);

            // If the question has no answer for this traveler yet, set the first allowed answer
            if (existingAnswerIndex === -1) {
              newAnswers.push({
                question: question.id,
                answer: question.allowedAnswers[0], // Set the first allowed answer
                travelerNum: travelerNum, // Set the traveler number
              });
            }
          }
        });
      }

      // PER_BOOKING pickup location
      bookingQuestions?.forEach((question) => {
        if (question.type === "LOCATION_REF_OR_FREE_TEXT" && question.group === "PER_BOOKING") {
          const existingAnswerIndex = newAnswers.findIndex((answer) => answer.question === question.id);

          // If no answer exists and allowCustomTravelerPickup is false, select the default location
          if (existingAnswerIndex === -1 && logistics?.travelerPickup && !logistics.travelerPickup.allowCustomTravelerPickup) {
            const defaultPickupLocation = logistics.travelerPickup.locations.find((loc) => loc.pickupType === "OTHER");

            // If a default pickup location is found, preselect it
            if (defaultPickupLocation) {
              newAnswers.push({
                question: question.id,
                answer: defaultPickupLocation.location.ref,
                unit: "LOCATION_REFERENCE",
              });
            }
          }
        }

        if (question.type === "STRING" && question.group === "PER_BOOKING" && question.allowedAnswers && question.allowedAnswers.length) {
          const existingAnswerIndex = newAnswers.findIndex((answer) => answer.question === question.id);

          if (existingAnswerIndex === -1) {
            newAnswers.push({
              question: question.id,
              answer: question.allowedAnswers[0],
            });
          }
        }
      });

      if (newAnswers.length !== bookingQuestionAnswers.length) {
        setBookingQuestionAnswers(newAnswers); // Only update the state if there are changes
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingQuestions, travelerCount]);

  // Set default language guide to the first option
  useEffect(() => {
    if (languageGuides.length > 0 && !languageGuide.language) {
      setLanguageGuide(languageGuides[0]); // Set the first language guide as default
    }
  }, [languageGuides, languageGuide]);

  const perTravelerQuestions = useMemo(() => {
    return bookingQuestions?.filter((question) => question.group === "PER_TRAVELER") || [];
  }, [bookingQuestions]);

  const perBookingQuestions = useMemo(() => {
    return bookingQuestions?.filter((question) => question.group !== "PER_TRAVELER") || [];
  }, [bookingQuestions]);

  // Function to render questions for per booking
  const renderPerBookingQuestions = () => {
    if (perBookingQuestions && perBookingQuestions.length > 0) {
      return perBookingQuestions.map((question) => (
        <div className="col col12 my4" key={question.id}>
          {renderQuestionField(question)}
        </div>
      ));
    }
  };

  // Function to render questions for each traveler
  const renderPerTravelerQuestions = (travelerNum: number) => {
    if (perTravelerQuestions && perTravelerQuestions.length > 0) {
      return perTravelerQuestions.map((question) => (
        <div className="col col12 my4" key={`${question.id}_traveler_${travelerNum}`}>
          {renderQuestionField(question, travelerNum)}
        </div>
      ));
    }
  };

  const handleNextClick = () => {
    // Re-run validation for all questions
    const newValidationErrors: { [key: string]: string } = {};
    bookingQuestions?.forEach((question) => {
      const answer = bookingQuestionAnswers.find((answer) => answer.question === question.id)?.answer;
      const validationError = validateInput(question, answer || "");
      if (validationError) {
        newValidationErrors[question.id] = validationError;
      }
    });

    setValidationErrors(newValidationErrors);

    // If there are no validation errors, proceed with the clicked function
    if (Object.keys(newValidationErrors).length === 0) {
      clicked({
        bookingQuestionAnswers: bookingQuestionAnswers as Providers.Viator.BookingQuestionAnswer[],
        languageGuide: languageGuides.length > 0 ? languageGuide : undefined,
      });
    }
  };

  // Function to render input fields based on question type, including travelerNum
  const renderQuestionField = (question: Providers.Viator.BookingQuestion, travelerNum?: number) => {
    const questionKey = travelerNum ? `${question.id}_traveler_${travelerNum}` : question.id;
    const error = validationErrors[question.id];

    if (question.type === "STRING" && question.allowedAnswers?.length) {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          <Dropdown
            id={questionKey}
            options={question.allowedAnswers.map((answer) => ({ value: answer, label: answer }))}
            defaultValue={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || question.allowedAnswers[0]}
            selectChange={(value) => handleInputChange(question.id, value, travelerNum)}
            skippable={false}
            disabled={question.required === "OPTIONAL"}
          />
          {/* {error && <p style={{ color: "red", fontSize: "12px" }}>{error}</p>} */}

          {error && <ErrorMessage message={error} />}
        </div>
      );
    }

    if (question.type === "NUMBER_AND_UNIT") {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          <div key={questionKey}>
            <TextField
              name={questionKey}
              value={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || ""}
              onChange={(event) => handleInputChange(question.id, event.target.value, travelerNum)}
              max={question.maxLength}
              required={question.required === "MANDATORY"}
            />
            <Dropdown
              id={`${questionKey}_unit`}
              options={question.units?.map((unit) => ({ value: unit, label: unit })) || []}
              defaultValue={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.unit || ""}
              selectChange={(value) =>
                handleInputChange(
                  question.id,
                  bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || "",
                  travelerNum,
                  value.toString()
                )
              }
              skippable={false}
              disabled={question.required === "OPTIONAL"}
            />
            {error && <ErrorMessage message={error} />}
          </div>
        </div>
      );
    }

    if (question.type === "STRING") {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          <TextField
            key={questionKey}
            name={questionKey}
            value={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || ""}
            onChange={(event) => handleInputChange(question.id, event.target.value, travelerNum)}
            placeholder={question.id === "SPECIAL_REQUIREMENTS" ? t("trips.myTrips.localExperiences.tourDetails.specialRequirements") : question.hint}
            max={question.maxLength}
            required={question.required === "MANDATORY"}
          />
          {error && <ErrorMessage message={error} />}
        </div>
      );
    }

    if (question.type === "DATE") {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          <TextField
            key={questionKey}
            type="date"
            name={questionKey}
            value={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || ""}
            onChange={(event) => handleInputChange(question.id, event.target.value, travelerNum)}
            required={question.required === "MANDATORY"}
          />
          {error && <ErrorMessage message={error} />}
        </div>
      );
    }

    if (question.type === "TIME") {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          <TextField
            key={questionKey}
            type="time"
            name={questionKey}
            value={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || ""}
            onChange={(event) => handleInputChange(question.id, event.target.value, travelerNum)}
            required={question.required === "MANDATORY"}
          />
          {error && <ErrorMessage message={error} />}
        </div>
      );
    }

    if (question.type === "LOCATION_REF_OR_FREE_TEXT") {
      return (
        <div className="col col12">
          <h4 className="my2">{question.label}</h4>
          {logistics?.travelerPickup?.allowCustomTravelerPickup ? (
            // Render free text input if custom pickup is allowed
            <TextField
              key={questionKey}
              name={questionKey}
              value={bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer || ""}
              onChange={(event) => handleInputChange(question.id, event.target.value, travelerNum, "FREETEXT")}
              placeholder={question.hint || t("trips.myTrips.localExperiences.tourDetails.enterLocation")}
              max={question.maxLength}
              required
            />
          ) : (
            // Render dropdown for predefined locations if custom pickup is not allowed
            <Dropdown
              id={questionKey}
              options={
                logistics?.travelerPickup?.locations
                  ?.filter((loc) => loc.pickupType === "OTHER")
                  ?.map((loc) => ({
                    value: loc.location.ref,
                    label: `${t("trips.myTrips.localExperiences.tourDetails.pickupLocation")} - ${loc.location.ref}`, // Replace with meaningful label if needed
                  })) || []
              }
              defaultValue={
                bookingQuestionAnswers.find((answer) => answer.question === question.id && answer.travelerNum === travelerNum)?.answer ||
                logistics?.travelerPickup?.locations[0]?.location.ref
              }
              selectChange={(value) => handleInputChange(question.id, value, travelerNum, "LOCATION_REFERENCE")}
              skippable={false}
            />
          )}
          {error && <ErrorMessage message={error} />}
        </div>
      );
    }

    return null;
  };

  // Function to get language name
  const getLanguageName = (code: string) => {
    const language = isoLanguages.find((lang) => lang.code === code);
    return language ? language.name : code;
  };

  const renderPrimaryTravelerAnswers = useMemo(() => {
    const primaryTravelerNum = 1; // We'll use the first traveler only
    if (bookingQuestionAnswers.length > 0 && bookingQuestions && bookingQuestions.length > 0) {
      return (
        <div className="col col12 mb0">
          <h2 className="my2 font-bold text-base">{t("trips.myTrips.localExperiences.tourDetails.primaryTraveler")}</h2>
          {bookingQuestionAnswers
            .filter((answer) => answer.travelerNum === primaryTravelerNum)
            .map((answer, index) => {
              const question = bookingQuestions.find((q) => q.id === answer.question);
              return (
                <div key={index} className="col col12 flex items-center gap-2 mb1 pl0">
                  <h4 className="my2 font-bold text-[#808080]">{question?.label || "Question"}:</h4>
                  <p>{answer.answer}</p>
                </div>
              );
            })}
        </div>
      );
    }
    return null; // No answers to display
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingQuestionAnswers, bookingQuestions]);

  if (active) {
    return (
      <div className="row p5 relative m-0 rounded-lg border-[1px] border-solid border-gray-300 min-h-20">
        <div className="col col12 my4 flex items-center gap-2">
          <div className="rounded-full border-2 w-6 h-6 flex items-center justify-center border-black bg-black text-white text-sm">2</div>
          <h3 className="font-bold text-lg">{t("trips.myTrips.localExperiences.tourDetails.activityDetails")}</h3>
        </div>
        <TravelSummary
          tourName={tourName}
          tourImage={tourImage}
          travelerCount={travelerCount}
          travelDate={travelDate}
          travelHour={travelHour}
          travelPrice={travelPrice}
          showPrice={false}
          t={t}
        />
        <hr className="my-2 w-full" style={{ opacity: 0.6 }} />
        {/* Render PER_TRAVELER questions for each traveler */}
        {perTravelerQuestions &&
          perTravelerQuestions.length > 0 &&
          [...Array(travelerCount)].map((_, index) => (
            <div className="col col12 my4" key={`traveler_${index + 1}`}>
              <h3 className="font-bold text-lg">
                {t("trips.myTrips.localExperiences.tourDetails.traveler")} {index + 1}
              </h3>
              {renderPerTravelerQuestions(index + 1)}
              <hr className="my-4" style={{ opacity: 0.6 }} />
            </div>
          ))}

        {renderPerBookingQuestions()}

        {languageGuides.length > 0 && (
          <div className="col col12 m0">
            <h4 className="pl-2">{t("trips.myTrips.localExperiences.tourDetails.languageGuides")}</h4>
            <div className="col col12 my2">
              <Dropdown
                id="languageGuideDropdown"
                options={languageGuides.map((guide) => ({
                  value: `${guide.language}_${guide.type}`,
                  label: `${getLanguageName(guide.language)} - ${guide.type.charAt(0).toUpperCase() + guide.type.slice(1).toLowerCase()}`,
                }))}
                defaultValue={`${languageGuide.language}_${languageGuide.type}`}
                selectChange={(value) => {
                  const [selectedLanguage, selectedType] = value.toString().split("_");
                  const selectedGuide = languageGuides.find((guide) => guide.language === selectedLanguage && guide.type === selectedType);
                  if (selectedGuide) {
                    setLanguageGuide(selectedGuide);
                  }
                }}
                skippable={false}
                disabled={false}
              />
            </div>
          </div>
        )}

        <div className="col col12 center mt5">
          <Button onClick={handleNextClick} text={t("trips.myTrips.localExperiences.tourDetails.next")} color="primary" />
        </div>
      </div>
    );
  } else {
    return (
      <div className="row px-5 relative m-0 rounded-lg border-[1px] border-solid border-gray-300 min-h-20">
        <div className="col col12 my4 flex items-center gap-2">
          <div className="rounded-full border-[1px] w-5 h-5 flex items-center justify-center border-black text-sm">2</div>
          <h3 className="font-bold text-lg">{t("trips.myTrips.localExperiences.tourDetails.activityDetails")}</h3>
        </div>
        {isEditable && (
          <div className="absolute right-4 top-3 border-none w-[35px] h-[35px] cursor-pointer font-medium">
            <button className="hover:underline" onClick={() => handleEditActivityDetails()}>
              {t("trips.myTrips.localExperiences.tourDetails.edit")}
            </button>
          </div>
        )}
        <TravelSummary
          tourName={tourName}
          tourImage={tourImage}
          travelerCount={travelerCount}
          travelDate={travelDate}
          travelHour={travelHour}
          travelPrice={travelPrice}
          showPrice={false}
          t={t}
        />
        <hr className="my-2 w-full" style={{ opacity: 0.6 }} />
        {renderPrimaryTravelerAnswers}
        {renderPrimaryTravelerAnswers !== null && <hr className="my-2 w-full" style={{ opacity: 0.6 }} />}
        {languageGuide.language !== "" && (
          <div className="col col12 mb0">
            <h2 className="my2 font-bold text-base">{t("trips.myTrips.localExperiences.tourDetails.otherDetails")}</h2>
            <div className="col col12 flex items-center gap-2 mb1 pl0">
              <h4 className="my2 font-bold text-[#808080]">{t("trips.myTrips.localExperiences.tourDetails.tourLanguage")}:</h4>
              <p> {`${getLanguageName(languageGuide.language)} - ${languageGuide.type.charAt(0).toUpperCase() + languageGuide.type.slice(1).toLowerCase()}`}</p>
            </div>
          </div>
        )}
      </div>
    );
  }
};

export default ViatorBookingActivityDetails;
