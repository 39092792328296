// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
import axiosLib, { AxiosInstance } from 'axios';

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  private apiKey: string;

  // private prodUrl = 'https://api.rezdy.com/v1';
  // private prodApiKey = '8ddb913c55ae48949e1945b317f5f148';

  constructor(apiUrl: string, apiToken: string, proxyURL: string) {
    this.proxyURL = proxyURL;
    this.apiUrl = apiUrl;
    this.apiKey = apiToken;
    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    this.axios.defaults.headers.common.apiKey = apiToken;
    this.axios.defaults.timeout = 30000; // 2500;
  }

  searchProducts = async (offset: number = 0, limit: number = 100): Promise<Providers.Rezdy.Product[]> => {
    // /products?offset=0&limit=100
    const url = btoa(`${this.apiUrl}/products?apiKey=${this.apiKey}&offset=${offset}&limit=${limit}`);

    return this.axios
      .get<Providers.Rezdy.ProductsResponse>(`${this.proxyURL}?url=${url}`)
      .then((products) => {
        const { data } = products;
        return data.products;
      })
      .catch((errorResponse) => {
        // eslint-disable-next-line no-console
        console.error('booke-barbados-searchActivitiesResponse', errorResponse);
        throw errorResponse.message;
      });
  };

  productInfo = async (productCode: string): Promise<Providers.Rezdy.Product> => {
    const url = btoa(`${this.apiUrl}/products/${productCode}?apiKey=${this.apiKey}`);
    return this.axios.get<Providers.Rezdy.ProductInfoResponse>(`${this.proxyURL}?url=${url}`).then((res) => res.data.product);
  };
}

export default API;
