/* eslint-disable no-plusplus */
/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import axiosLib, { AxiosInstance } from 'axios';

// eslint-disable-next-line import/no-extraneous-dependencies
import Model, { Providers } from '@tripian/model';

import { uniqueTour, titleCategoryIds } from './catalogCategoryIds';
import { handleHttpResponseErrorForGyg } from './handle';

export type GygTourDataFormPersonsCategory = {
  addon: boolean; // false;
  id: number; // 1,
  max_age?: number; // 99,
  min_age: number; // 0,
  name: string; // "Adults", if("Group") => "Passengers"
  stand_alone: boolean; // true
};

export type GygTourDataOptionAvailability = { availability_id: number } & Providers.Gyg.TourAvailabilityEx & Providers.Gyg.TourOptionPricing;

export type GygTourDataOption = Providers.Gyg.TourOption & {
  availabilities: GygTourDataOptionAvailability[];
};

export type GygTourData = {
  tour: Providers.Gyg.Tour;
  availableDates: string[];
  formPersonsCategories: GygTourDataFormPersonsCategory[];
  options: GygTourDataOption[];
};

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  // private baseURL = `${this.tripianProxyURL}https://api.getyourguide.com/1`;
  // private baseURL_TEST = `${this.tripianProxyURL}https://api.testing1.gygtest.com/1`;
  // private access_token = 'Yb8XauGtHKbBUEj4PGWRfrvzvmwKijdghHwWkjBVYTKmTFeR'; // Cenan
  // private access_token = 'fe7rEIA50VrT6Oxg0TWub1CxtOL4caBxdUkUuAa1tD7wjzZ7'; // CG

  private sandbox: boolean;

  private lang: string;

  private currency: string;

  constructor(apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang: string, currency: string) {
    this.proxyURL = proxyURL;
    this.sandbox = sandbox;
    this.apiUrl = apiUrl;
    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    this.axios.defaults.headers.common['X-ACCESS-TOKEN'] = apiToken;
    // this.axios.defaults.headers.common.origin = 'tripian.com';
    this.axios.defaults.timeout = 30000; // 2500;
    // for jest test.
    // this.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    this.lang = lang;
    this.currency = currency;
  }

  categories = async (limit: number = 10): Promise<Providers.Gyg.Category[]> => {
    const url = btoa(`${this.apiUrl}/categories?cnt_language=${this.lang}&currency=${this.currency}&limit=${limit}`);

    return this.axios
      .get<Providers.Gyg.Categories>(`${this.proxyURL}?url=${url}`)
      .then((categoryList) => {
        return categoryList.data.data.categories;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-categories'));
  };

  category = async (categoryId: number): Promise<Providers.Gyg.Category> => {
    const url = btoa(`${this.apiUrl}/categories/${categoryId}?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.Categories>(`${this.proxyURL}?url=${url}`)
      .then((category) => {
        return category.data.data.categories[0];
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-category', categoryId));
  };

  private defaultTours = async (
    cityName: string,
    cityCoordinate?: Model.Coordinate,
    radius: number = 20,
    date?: Providers.Gyg.TourDateRange,
    categoryIds?: number[],
    limit: number = 200,
    preformatted: string = 'full',
  ) => {
    const url = btoa(
      `${this.apiUrl}/tours?preformatted=${preformatted}&limit=${limit}&cnt_language=${this.lang}&currency=${this.currency}&q=${cityName}${
        cityCoordinate ? `&coordinates[]=${cityCoordinate.lat}&coordinates[]=${cityCoordinate.lng}&coordinates[]=${radius}` : ''
      }${categoryIds ? `&categories[]=${categoryIds?.join(',')}` : ''} ${date ? `&date[]=${date.start}&date[]=${date.end}&duration[]=1440` : ''}`,
    );

    return this.axios.get<Providers.Gyg.Tours>(`${this.proxyURL}?url=${url}`);
  };

  private ticketTours = async (
    cityName: string,
    cityCoordinate?: Model.Coordinate,
    radius: number = 20,
    date?: Providers.Gyg.TourDateRange,
    categoryIds: number[] = [123],
    limit: number = 200,
    preformatted: string = 'full',
  ) => {
    const url = btoa(
      `${this.apiUrl}/tours?preformatted=${preformatted}&limit=${limit}&cnt_language=${this.lang}&currency=${this.currency}&q=${cityName}${
        cityCoordinate ? `&coordinates[]=${cityCoordinate.lat}&coordinates[]=${cityCoordinate.lng}&coordinates[]=${radius}` : ''
      }${categoryIds ? `&categories[]=${categoryIds?.join(',')}` : ''} ${date ? `&date[]=${date.start}&date[]=${date.end}` : ''}`,
    );

    return this.axios.get<Providers.Gyg.Tours>(`${this.proxyURL}?url=${url}`);
  };

  toursAll = async (
    cityName: string,
    cityCoordinate?: Model.Coordinate,
    radius: number = 20,
    date?: Providers.Gyg.TourDateRange,
    categoryIds?: number[],
    unique: boolean = uniqueTour,
    limit: number = 200,
    // preformatted: string = 'teaser',
    preformatted: string = 'full',
  ): Promise<Providers.Gyg.CatalogGroup[]> => {
    return Promise.all([
      this.defaultTours(cityName, cityCoordinate, radius, date, categoryIds, limit, preformatted),
      this.ticketTours(cityName, cityCoordinate, radius, date, categoryIds, limit, preformatted),
    ])
      .then((tAllArray) => {
        const defaultTours = tAllArray[0].data.data.tours;
        const ticketTours = tAllArray[1].data.data.tours;

        const defaultToursIds = defaultTours.map((d) => d.tour_id);
        const gygAllTours: Providers.Gyg.Tour[] = [...defaultTours, ...ticketTours.filter((d) => !defaultToursIds.includes(d.tour_id))];

        const catalogGroups: Providers.Gyg.CatalogGroup[] = [];

        titleCategoryIds.forEach((t) => {
          catalogGroups.push({ title: t.title, items: [] });
        });

        gygAllTours.forEach((t) => {
          let tourCategorised = false;

          titleCategoryIds.forEach((tc, tcIndex) => {
            if (!tourCategorised || !unique)
              if (t.categories.findIndex((category) => tc.categoryIds.includes(category.category_id)) > -1) {
                catalogGroups[tcIndex].items.push(t);
                tourCategorised = true;
              }
          });
        });
        const copyCatalogGroupsNoneEmpty = catalogGroups.filter((cg) => cg.items.length !== 0);
        const copyCatalogGroupsNoneEmptySorted = copyCatalogGroupsNoneEmpty.map((cg) => ({
          title: cg.title,
          items: cg.items.sort((a, b) => b.number_of_ratings - a.number_of_ratings),
        }));
        return copyCatalogGroupsNoneEmptySorted;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tours', cityName));
  };

  tours = async (
    cityName: string,
    cityCoordinate?: Model.Coordinate,
    radius: number = 20,
    date?: Providers.Gyg.TourDateRange,
    categoryIds?: number[],
    unique: boolean = uniqueTour,
    limit: number = 200,
    // preformatted: string = 'teaser',
    preformatted: string = 'full',
  ): Promise<Providers.Gyg.CatalogGroup[]> => {
    // https://api.getyourguide.com/1/tours?
    // cnt_language=en&currency=usd&limit=10&q=Istanbul&categories[]=103&preformatted=teaser&coordinates[]=41.019744&coordinates[]=28.990887&coordinates[]=10
    // TODO
    // &date[]=2017-10-01T00:00:00&date[]=2017-10-01T23:59:59
    // &sortfield=rating
    // Defines the sort field.
    //      popularity: list the result by popularity, default
    //      price: list the result by price
    //      rating: list the result by rating
    //      duration: list the result by duration

    const url = btoa(`${this.apiUrl}/tours?preformatted=${preformatted}&limit=${limit}&cnt_language=${this.lang}&currency=${
      this.currency
    }&q=${cityName}
    ${cityCoordinate ? `&coordinates[]=${cityCoordinate.lat}&coordinates[]=${cityCoordinate.lng}&coordinates[]=${radius}` : ''}${
      categoryIds ? `&categories[]=${categoryIds?.join(',')}` : ''
    } ${date ? `&date[]=${date.start}&date[]=${date.end}&duration[]=1440` : ''}`);

    return this.axios
      .get<Providers.Gyg.Tours>(`${this.proxyURL}?url=${url}`)
      .then((tourList) => {
        const catalogGroups: Providers.Gyg.CatalogGroup[] = [];
        const gygTours: Providers.Gyg.Tour[] = tourList.data.data.tours;
        /* const singleDayTours = gygTours.filter(
          (tour: Providers.Gyg.Tour) => tour.durations.find((duration) => duration.unit === 'day' && duration.duration > 1) === undefined,
        ); */

        titleCategoryIds.forEach((t) => {
          catalogGroups.push({ title: t.title, items: [] });
        });

        gygTours.forEach((t) => {
          let tourCategorised = false;

          titleCategoryIds.forEach((tc, tcIndex) => {
            if (!tourCategorised || !unique)
              if (t.categories.findIndex((category) => tc.categoryIds.includes(category.category_id)) > -1) {
                catalogGroups[tcIndex].items.push(t);
                tourCategorised = true;
              }
          });
        });

        // const topRatedTours: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.tourIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );
        // const advantureNature: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.advantureNatureIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );
        // const cultureHistory: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.cultureHistoryIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );
        // const cruiseSailingAndBoatTours: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.cruiseSailingAndBoatToursIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );
        // const foodAndDrinks: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.foodAndDrinksIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );
        // const luxuryAndSpecialOccasions: Providers.Gyg.Tour[] = gygTours.filter((tour) =>
        //   catalogCategoryIds.luxuryAndSpecialOccasionsIds.includes(tour.categories.length > 0 ? tour.categories[0].category_id : 0),
        // );

        // if (topRatedTours.length > 0) catalogGroups.push({ title: 'Top Rated', items: topRatedTours });

        // if (advantureNature.length > 0) catalogGroups.push({ title: 'Adventure and Nature', items: advantureNature });

        // if (cultureHistory.length > 0) catalogGroups.push({ title: 'Culture & History', items: cultureHistory });

        // if (cruiseSailingAndBoatTours.length > 0) catalogGroups.push({ title: 'Cruise, Sailing and Boat items', items: cruiseSailingAndBoatTours });

        // if (foodAndDrinks.length > 0) catalogGroups.push({ title: 'Food and Drinks', items: foodAndDrinks });

        // if (luxuryAndSpecialOccasions.length > 0) catalogGroups.push({ title: 'Luxury and Special Occasions', items: luxuryAndSpecialOccasions });

        // return catalogGroups;

        const copyCatalogGroupsNoneEmpty = catalogGroups.filter((cg) => cg.items.length !== 0);
        const copyCatalogGroupsNoneEmptySorted = copyCatalogGroupsNoneEmpty.map((cg) => ({
          title: cg.title,
          items: cg.items.sort((a, b) => b.number_of_ratings - a.number_of_ratings),
        }));
        return copyCatalogGroupsNoneEmptySorted;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tours', cityName));
  };

  tour = async (tourId: number, preformatted: string = 'full'): Promise<Providers.Gyg.Tour> => {
    const url = btoa(`${this.apiUrl}/tours/${tourId}?cnt_language=${this.lang}&currency=${this.currency}&preformatted=${preformatted}`);

    return this.axios
      .get<Providers.Gyg.Tours>(`${this.proxyURL}?url=${url}`)
      .then((tourList) => {
        return tourList.data.data.tours[0];
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour', tourId));
  };

  /* tourAvailabilities = async (
    tourId: number,
    startDate: string,
    endDate?: string,
    
    currency: string = 'usd',
  ): Promise<Providers.Gyg.TourAvailability[]> => {
    return this.axios
      .get<Providers.Gyg.TourAvailabilities>(
        `/tours/${tourId}/availabilities?cnt_language=${language}&currency=${this.currency}&date[]=${startDate}&date[]=${endDate}`,
      )
      .then((response) => {
        return response.data.data.availabilities;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-availabilities', tourId));
  }; */

  private tourOptions_1 = async (
    tourId: number,
    /* , flags: string = undefined  eklenecek */
  ): Promise<Providers.Gyg.TourOption[]> => {
    const url = btoa(`${this.apiUrl}/tours/${tourId}/options?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourOptionsEx>(`${this.proxyURL}?url=${url}`)
      .then((tourOptions) => {
        if (tourOptions.data.data && tourOptions.data.data.tour_options) return tourOptions.data.data.tour_options;
        // eslint-disable-next-line no-throw-literal
        throw 'Tour is not available for now';
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-options', tourId));
  };

  private optionAvailabilities_2 = async (optionId: number, startDate: string, endDate?: string): Promise<Providers.Gyg.TourAvailabilityEx[]> => {
    const url = btoa(
      `${this.apiUrl}/options/${optionId}/availabilities?cnt_language=${this.lang}&currency=${this.currency}&date[]=${startDate}${
        endDate ? `&date[]=${endDate}` : ''
      }`,
    );

    return this.axios
      .get<Providers.Gyg.TourAvailabilities>(`${this.proxyURL}?url=${url}`)
      .then((tourOptionAvailability) => {
        return tourOptionAvailability.data.data.availabilities;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-option-availabilities', optionId));
  };

  private optionPricings_3 = async (optionId: number): Promise<Providers.Gyg.TourOptionPricing[]> => {
    const url = btoa(`${this.apiUrl}/options/${optionId}/pricings?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourOptionPricings>(`${this.proxyURL}?url=${url}`)
      .then((tourOptionPricing) => {
        return tourOptionPricing.data.data.pricing;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-option-pricings', optionId));
  };

  tourDataEx = async (tourId: number, startDate: string, endDate: string): Promise<GygTourData> => {
    const tour = await this.tour(tourId);

    const result: GygTourData = {
      tour,
      availableDates: [],
      formPersonsCategories: [],
      options: [],
    };

    const tourOptions = await this.tourOptions_1(tourId);
    /**
     * Tour options yok boş dön
     */
    if (tourOptions.length === 0) {
      return result;
    }

    // Fill options' availabilities and prices
    for (let i = 0; i < tourOptions.length; i++) {
      const tourOption = tourOptions[i];
      const optionAvailabilities = await this.optionAvailabilities_2(tourOption.option_id, startDate, endDate);
      /**
       * Option için availabilities boş geç
       */
      if (optionAvailabilities.length === 0) continue;

      const optionPricings = await this.optionPricings_3(tourOption.option_id);
      const availabilities: GygTourDataOptionAvailability[] = [];
      optionAvailabilities.forEach((optionAvailability, aindex) => {
        const ps = optionPricings.find((x) => x.pricing_id === optionAvailability.pricing_id);
        if (ps) {
          const availability: GygTourDataOptionAvailability = {
            availability_id: tourOption.option_id * 10 + aindex,
            ...optionAvailability,
            ...ps,
          };
          availabilities.push(availability);

          // Fill availableDates
          const adate = availability.start_time.split('T')[0];
          if (!result.availableDates.includes(adate)) result.availableDates.push(adate);
          // Fill availableDates
        } else {
          /**
           * Availabilitiy price_id ile optionPricing.price_id eşleşmedi, geç
           */
        }
      });

      const option: Providers.Gyg.TourOption & {
        availabilities: GygTourDataOptionAvailability[];
      } = {
        ...tourOption,
        availabilities,
      };
      result.options.push(option);
    }

    /**
     * Fill formPersonsCategories
     */
    if (result.options.length > 0 && result.options[0].availabilities.length > 0) {
      result.formPersonsCategories = result.options[0].availabilities[0].categories.map((x) => {
        const gygTourDataFormPersonsCategory: GygTourDataFormPersonsCategory = {
          id: x.id,
          name: x.name,
          min_age: x.min_age,
          max_age: x.max_age,
          addon: x.addon,
          stand_alone: x.stand_alone,
        };

        if (gygTourDataFormPersonsCategory.name === 'Group') gygTourDataFormPersonsCategory.name = 'Participants';
        if (gygTourDataFormPersonsCategory.min_age === undefined) gygTourDataFormPersonsCategory.min_age = 0;
        if (gygTourDataFormPersonsCategory.max_age === undefined) gygTourDataFormPersonsCategory.max_age = 99;

        return gygTourDataFormPersonsCategory;
      });
    }
    /**
     * Fill formPersonsCategories
     */

    /* const optionAvailabilitiesPromises: Promise<Providers.Gyg.TourAvailability[]>[] = [];
    tourOptions.forEach((tourOption) => {
      const optionAvailabilitiesPromise = this.optionAvailabilities_2(tourOption.option_id, startDate, endDate, language, currency);
      optionAvailabilitiesPromises.push(optionAvailabilitiesPromise);
    });

    const optionPricingsPromises: Promise<Providers.Gyg.TourOptionPricing>[] = [];
    tourOptions.forEach((tourOption) => {
      const optionPricingsPromise = this.optionPricings_3(tourOption.option_id, language, currency);
      optionPricingsPromises.push(optionPricingsPromise);
    }); */

    return result;
  };

  /**
   *
   *  REMOVE
   *
   */

  /* tour = async (tourId: number,  currency: string = 'usd', preformatted: string = 'full'): Promise<Providers.Gyg.Tour> => {
    return this.axios
      .get<Providers.Gyg.Tours>(`/tours/${tourId}?cnt_language=${language}&currency=${this.currency}&preformatted=${preformatted}`)
      .then((tourList) => {
        return tourList.data.data.tours[0];
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour', tourId));
  }; */

  tourAvailabilities = async (tourId: number, startDate: string, endDate?: string): Promise<Providers.Gyg.TourAvailabilityEx[]> => {
    const url = btoa(
      `${this.apiUrl}/tours/${tourId}/availabilities?cnt_language=${this.lang}&currency=${this.currency}&date[]=${startDate}&date[]=${endDate}`,
    );

    return this.axios
      .get<Providers.Gyg.TourAvailabilities>(`${this.proxyURL}?url=${url}`)
      .then((response) => {
        return response.data.data.availabilities;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-availabilities', tourId));
  };

  options = async (tourId: number): Promise<Providers.Gyg.TourOption[]> => {
    const url = btoa(`${this.apiUrl}/tours/${tourId}/options?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourOptionsEx>(`${this.proxyURL}?url=${url}`)
      .then((tourOptions) => {
        return tourOptions.data.data.tour_options;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-options', tourId));
  };

  optionAvailabilities = async (optionId: number, startDate: string, endDate?: string): Promise<Providers.Gyg.TourAvailabilityEx[]> => {
    const url = btoa(
      `${this.apiUrl}/options/${optionId}/availabilities?cnt_language=${this.lang}&currency=${this.currency}&date[]=${startDate}${
        endDate ? `&date[]=${endDate}` : ''
      }`,
    );

    return this.axios
      .get<Providers.Gyg.TourAvailabilities>(`${this.proxyURL}?url=${url}`)
      .then((tourOptionAvailability) => {
        return tourOptionAvailability.data.data.availabilities;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-option-availabilities', optionId));
  };

  optionPricings = async (optionId: number): Promise<Providers.Gyg.TourOptionPricing> => {
    const url = btoa(`${this.apiUrl}/options/${optionId}/pricings?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourOptionPricings>(`${this.proxyURL}?url=${url}`)
      .then((tourOptionPricing) => {
        return tourOptionPricing.data.data.pricing[0];
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-option-pricings', optionId));
  };

  tourAvailabilitiy = async (tourId: number): Promise<Providers.Gyg.TourAvailability> => {
    const url = btoa(`${this.apiUrl}/tours/${tourId}/availability?cnt-language=${this.lang}`);

    return this.axios
      .get<Providers.Gyg.TourAvailability>(`${this.proxyURL}?url=${url}`)
      .then((response) => {
        return response.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-availabilities', tourId));
  };

  tourOptions = async (tourId: number, startDate: string, endDate?: string, limit: number = 10): Promise<Providers.Gyg.TourOptions> => {
    const url = btoa(
      `${this.apiUrl}/tours/${tourId}/options?cnt_language=${this.lang}&currency=${this.currency}&date[]=${startDate}${
        endDate ? `&date[]=${endDate}` : ''
      }&limit=${limit}`,
    );

    return this.axios
      .get<Providers.Gyg.TourOptions>(`${this.proxyURL}?url=${url}`)
      .then((response) => {
        return response.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-availabilities', tourId));
  };

  tourPriceBreakdown = async (
    tourId: number,
    tourOptionsRequest: Providers.Gyg.TourPriceBreakdownRequest,
  ): Promise<Providers.Gyg.TourPriceBreakdown> => {
    const url = btoa(`${this.apiUrl}/tours/${tourId}/price-breakdown`);

    return this.axios
      .post<Providers.Gyg.TourPriceBreakdown>(`${this.proxyURL}?url=${url}`, tourOptionsRequest)
      .then((booking) => {
        return booking.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-options', tourId));
  };

  tourData = async (tourId: number): Promise<Providers.Gyg.TourData> => {
    const tour = await this.tour(tourId);
    const availability = await this.tourAvailabilitiy(tourId);

    return {
      tour,
      availability,
    };
  };

  bookingAdd = async (
    tourBookingRequest: Providers.Gyg.TourBookingRequest,
    // external_reference_id?: string,
  ): Promise<Providers.Gyg.TourBookingMetaData | Providers.Gyg.TourErrorResponse> => {
    const url = btoa(`${this.apiUrl}/bookings`);

    return this.axios
      .post(`${this.proxyURL}?url=${url}`, tourBookingRequest)
      .then((booking) => {
        return booking.data;
      })
      .catch((errorResponse) =>
        handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-booking-add', tourBookingRequest.data.booking.bookable.option_id),
      );
  };

  booking = async (bookingHash: string): Promise<Providers.Gyg.TourBookingInfo[]> => {
    const url = btoa(`${this.apiUrl}/bookings/${bookingHash}?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourBookingInfos>(`${this.proxyURL}?url=${url}`)
      .then((booking) => {
        return booking.data.data.booking;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-bookings', bookingHash));
  };

  bookingDelete = async (bookingHash: string): Promise<Providers.Gyg.TourBooking[]> => {
    const url = btoa(`${this.apiUrl}/bookings/${bookingHash}?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .delete<Providers.Gyg.TourBookings>(`${this.proxyURL}?url=${url}`)
      .then((bookings) => {
        return bookings.data.data.bookings;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-booking-delete', bookingHash));
  };

  cartAdd = async (
    tourBookingCartRequest: Providers.Gyg.TourBookingCartRequest,
    // external_reference_id?: string,
  ): Promise<Providers.Gyg.TourBookingCart> => {
    const url = btoa(`${this.apiUrl}/carts`);

    return this.axios
      .post<Providers.Gyg.TourBookingCarts>(`${this.proxyURL}?url=${url}`, tourBookingCartRequest, {
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      })
      .then((cart) => {
        return cart.data.data;
      })
      .catch((errorResponse) =>
        handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-booking-cart', tourBookingCartRequest.data.shopping_cart.shopping_cart_id),
      );
  };

  cart = async (shoppingCartHash: string): Promise<Providers.Gyg.TourBookingCart> => {
    const url = btoa(`${this.apiUrl}/carts/${shoppingCartHash}?cnt_language=${this.lang}&currency=${this.currency}`);

    return this.axios
      .get<Providers.Gyg.TourBookingCarts>(`${this.proxyURL}?url=${url}`)
      .then((cart) => {
        return cart.data.data;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-cart', shoppingCartHash));
  };

  paymentConfiguration = async (country: string = 'US'): Promise<Providers.Gyg.PaymentConfiguration[]> => {
    const url = btoa(`${this.apiUrl}/configuration/payment?cnt_language=${this.lang}&currency=${this.currency}&country=${country}`);

    return this.axios
      .get<Providers.Gyg.PaymentConfigurations>(`${this.proxyURL}?url=${url}`)
      .then((response) => {
        return response.data.data.payment_methods;
      })
      .catch((errorResponse) => handleHttpResponseErrorForGyg(errorResponse, 'gyg-tour-payment-configuration'));
  };
}

export default API;
