import React, { useEffect, useMemo, useState } from "react";
import { googleAnalyticsDimension } from "../../../gtag";
import { BackButton, Modal, PreLoading, RatingStars, ViatorTourInfoImage } from "@tripian/react";
import useViatorApi from "../../../hooks/useViatorApi";
import { LOCAL_EXPERIENCES, TOUR_INFO, TOURS_AND_TICKETS, TRIPS } from "../../../constants/ROUTER_PATH_TITLE";
import AppNav from "../../../App/AppNav/AppNav";
import { useHistory } from "react-router";
import useTranslate from "../../../hooks/useTranslate";
import ViatorTourInfoForm from "./ViatorTourInfoForm/ViatorTourInfoForm";
import Model, { Providers } from "@tripian/model";
import ViatorTourOption from "./ViatorTourOption/ViatorTourOption";
import useUser from "../../../hooks/useUser";
import Checkout from "./Checkout/Checkout";
import ViatorBookingDetails from "./ViatorBookingDetails/ViatorBookingDetails";
import { useDispatch } from "react-redux";
import { saveNotification } from "../../../redux/action/user";
import classes from "./ViatorTourInfo.module.scss";

interface IViatorTourInfo {
  tourId: string;
  cityId: number;
  cityName?: string;
  startDate?: string;
  endDate?: string;
  adults?: number;
  children?: number;
  previousRoute?: string;
}

const ViatorTourInfo: React.FC<IViatorTourInfo> = ({ tourId, cityId, cityName, startDate, endDate, adults, children, previousRoute }) => {
  const {
    fetchProductInfo,
    viatorProductInfo,
    fetchProductAvailability,
    viatorProductAvailability,
    successfullBooking,
    fetchProductBookingHold,
    paymentSessionToken,
    loadings,
    bookingConfirmRequest,
    setBookingConfirm,
    setBookingConfirmLoading,
    showInfoModal,
    setBookingInfoModal,
    confirmPayment,
    viatorBookingInfo,
    selectedHour,
    changeSelectedHour,
    availableDate,
    changeAvailableDate,
  } = useViatorApi(Number(cityId), cityName, startDate, endDate);
  const [personsCategories, setPersonsCategories] = useState<(Providers.Viator.AgeBand & { count: number })[]>([]);

  const [showCheckout, setShowCheckout] = useState(false);
  const [selectedTourOption, setSelectedTourOption] = useState<Providers.Viator.AvailabilityBookableItem>();

  const { user } = useUser();

  const history = useHistory();

  const { t } = useTranslate();

  const dispatch = useDispatch();

  useEffect(() => {
    fetchProductInfo(tourId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (viatorProductInfo) {
      googleAnalyticsDimension("viewedProductUrl", viatorProductInfo.info.productUrl);
    }
  }, [viatorProductInfo]);

  useEffect(() => {
    if (viatorProductInfo && viatorProductInfo.info.pricingInfo.ageBands.length > 0) {
      const newPersonsCategories = viatorProductInfo.info.pricingInfo.ageBands.map((x) => ({ ...x, count: 0 }));

      const adultsCount = adults ? Number(adults) : 0;
      const childrenCount = children ? Number(children) : 0;

      // ADULT
      const newAdultsCategories = newPersonsCategories.find((x) => x.ageBand === "ADULT");
      if (newAdultsCategories) {
        newAdultsCategories.count = adultsCount;
      }

      // CHILD
      const newChildrenCategories = newPersonsCategories.find((x) => x.ageBand === "CHILD");
      if (newChildrenCategories) {
        newChildrenCategories.count = childrenCount;
      }

      // TRAVELER
      const travelerCategory = newPersonsCategories.find((x) => x.ageBand === "TRAVELER");
      if (travelerCategory) {
        travelerCategory.count = adultsCount + childrenCount;
      }

      setPersonsCategories(newPersonsCategories);
    }
  }, [adults, children, viatorProductInfo]);

  const duration = useMemo(() => {
    if (viatorProductInfo) {
      if (viatorProductInfo.info.itinerary.duration.fixedDurationInMinutes) {
        return (
          <span className={classes.numberOfRatings}>
            {viatorProductInfo.info.itinerary.duration.fixedDurationInMinutes} {t("trips.myTrips.localExperiences.tourDetails.mins")}
          </span>
        );
      }
      if (viatorProductInfo.info.itinerary.duration.variableDurationFromMinutes && viatorProductInfo.info.itinerary.duration.variableDurationToMinutes) {
        return (
          <span className={classes.numberOfRatings}>
            {viatorProductInfo.info.itinerary.duration.variableDurationFromMinutes} - {viatorProductInfo.info.itinerary.duration.variableDurationToMinutes}
            {t("trips.myTrips.localExperiences.tourDetails.mins")}
          </span>
        );
      }
    }
    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viatorProductInfo]);

  const onHoldBooking = async (productOptionCode: string) => {
    const request: Providers.Viator.BookingCardHoldRequest = {
      currency: "USD",
      partnerCartRef: `partnerCartRef${Math.random().toString(36).substring(4)}`,
      items: [
        {
          partnerBookingRef: `partnerBookingRef${Math.random().toString(36).substring(4)}`,
          productCode: viatorProductInfo?.availability.productCode || "",
          productOptionCode: productOptionCode,
          startTime: selectedHour !== "" ? selectedHour : undefined,
          travelDate: availableDate,
          paxMix: personsCategories
            .filter((item) => item.count > 0)
            .map((item) => ({
              ageBand: item.ageBand,
              numberOfTravelers: item.count,
            })),
        },
      ],
      paymentDataSubmissionMode: "VIATOR_FORM",
      hostingUrl: window.location.origin,
    };
    const bookingHold = await fetchProductBookingHold(request);

    if (bookingHold === true) {
      setShowCheckout(true);
    } else {
      setShowCheckout(false);
    }
  };

  const onConfirmBooking = async (paymentToken: string) => {
    const bookingConfirm = {
      ...bookingConfirmRequest,
      paymentToken,
    };
    confirmPayment(bookingConfirm);
  };

  const forms = (viatorTourDataParam: Providers.Viator.TourData) => {
    if (successfullBooking) {
      googleAnalyticsDimension("bookedProductUrl", viatorTourDataParam.info.productUrl);
      return null;
    } else {
      return (
        <>
          <div className="row px-6">
            <div className="col col12">
              <ViatorTourInfoForm
                bookableItems={viatorTourDataParam.availability.bookableItems}
                productCode={viatorTourDataParam.availability.productCode}
                availableDate={availableDate}
                setAvailableDate={changeAvailableDate}
                selectedHour={selectedHour}
                setSelectedHour={changeSelectedHour}
                formPersonsCategories={viatorTourDataParam.info.pricingInfo.ageBands}
                personsCategories={personsCategories}
                setPersonsCategories={setPersonsCategories}
                checkAvailabilityClick={(availabilityRequest: Providers.Viator.AvailabilityCheckRequest) => {
                  fetchProductAvailability(availabilityRequest);
                }}
                maxTravelerCount={viatorProductInfo?.info.bookingRequirements.maxTravelersPerBooking}
                timeZone={viatorProductInfo?.info.timeZone}
                t={t}
              />
            </div>
          </div>

          {loadings === "loadingViatorProductAvailability" ? (
            <div className="w-full relative h-20">
              <div className={classes.viatorTourDetailsLoader}>
                <PreLoading />
              </div>
            </div>
          ) : (
            <div className="row mb0 px-6">
              {viatorProductAvailability && viatorProductAvailability.bookableItems.length > 0 && (
                <div className="col col12 col8-m mb0">
                  {viatorProductAvailability.bookableItems.map((tourDataOption) => (
                    <ViatorTourOption
                      key={tourDataOption.productOptionCode}
                      tourOption={tourDataOption}
                      travelDate={viatorProductAvailability.travelDate}
                      currency={viatorProductAvailability.currency}
                      bookingRequestCallback={(productOption: Providers.Viator.AvailabilityBookableItem) => {
                        setSelectedTourOption(productOption);
                        onHoldBooking(productOption.productOptionCode);
                      }}
                      t={t}
                    />
                  ))}
                </div>
              )}
            </div>
          )}
        </>
      );
    }
  };

  if (loadings === "loadingViatorProductInfo" || loadings === "loadingBookingHold" || loadings === "loadingBookingConfirm" || viatorProductInfo === undefined) {
    return (
      <>
        <AppNav header={TOUR_INFO.HEADER?.(t("tourInfo.header"))} />
        <PreLoading />
      </>
    );
  }

  if (viatorProductInfo === undefined) {
    return (
      <>
        <AppNav header={TOUR_INFO.HEADER?.(t("tourInfo.header"))} />
        <div className="mt-12 mx-auto p-4 text-center">
          {t("trips.myTrips.localExperiences.tourDetails.experience.doesNotSupport")} "Viator" {t("trips.myTrips.localExperiences.tourDetails.experience.tours")}.
        </div>
      </>
    );
  }

  return (
    <div className={classes.tourInfo}>
      <AppNav header={TOUR_INFO.HEADER?.(t("tourInfo.header"))} />

      {showCheckout ? (
        <Checkout
          user={user}
          tourName={viatorProductInfo.info.title}
          tourImage={viatorProductInfo.info.images[0].variants[viatorProductInfo.info.images[0].variants.length - 1].url}
          travelHour={selectedHour}
          travelDate={availableDate}
          travelPrice={selectedTourOption?.totalPrice.price.partnerTotalPrice}
          bookerInfo={bookingConfirmRequest.bookerInfo}
          communication={bookingConfirmRequest.communication}
          bookingQuestions={viatorProductInfo?.bookingQuestions}
          bookingQuestionAnswers={bookingConfirmRequest.items.find((item) => item.bookingQuestionAnswers)?.bookingQuestionAnswers}
          languageGuides={viatorProductInfo?.info.languageGuides || []}
          logistics={viatorProductInfo.info.logistics}
          paymentSessionToken={paymentSessionToken}
          clickedBookingContactDetails={({ bookerInfo, communication }) => {
            const bookingConfirm = {
              ...bookingConfirmRequest,
              communication,
              bookerInfo,
            };
            setBookingConfirm(bookingConfirm);
          }}
          clickedBookingActivityDetails={({ bookingQuestionAnswers, languageGuide }) => {
            const updatedItems = bookingConfirmRequest.items.map((item) => {
              return {
                ...item,
                bookingQuestionAnswers: bookingQuestionAnswers || item.bookingQuestionAnswers,
                languageGuide: languageGuide || item.languageGuide,
              };
            });

            const updatedBookingConfirmRequest = {
              ...bookingConfirmRequest,
              items: updatedItems,
            };

            setBookingConfirm(updatedBookingConfirmRequest);
          }}
          setPaymentLoading={(loading: boolean) => setBookingConfirmLoading(loading)}
          onPaymentSuccess={(paymentToken: string) => onConfirmBooking(paymentToken)}
          onPaymentError={(error: string) => {
            dispatch(saveNotification(Model.NOTIFICATION_TYPE.ERROR, "ViatorFetchPaymentError", "Submitted card details were invalid or unsupported for this purchase"));
          }}
          travelerCount={personsCategories.reduce((total, traveler) => total + traveler.count, 0)}
          t={t}
        />
      ) : (
        <>
          <div className="row mb0 px-6 pt-6">
            <div className="col col12">
              <>
                {history.location.key && (
                  <div className={classes.gygTourInfoBack}>
                    <BackButton
                      fill="#000"
                      clicked={() => {
                        history.goBack();
                      }}
                    />
                  </div>
                )}
              </>
              <h1>{viatorProductInfo.info.title}</h1>
              <div className={classes.gygTourInfoRatingStars}>
                <RatingStars rating={(viatorProductInfo.info.reviews.combinedAverageRating * 20).toString()} />
                <span className={classes.gygTourInfoOverralRating}>{viatorProductInfo.info.reviews.combinedAverageRating.toFixed(1)}</span>
                <span className={classes.gygTourInfoReviews}>
                  {viatorProductInfo.info.reviews.totalReviews} {t("trips.myTrips.localExperiences.tourDetails.reviews")}
                </span>
              </div>
            </div>

            <div className="col col12">
              <ViatorTourInfoImage tourImage={viatorProductInfo.info.images} />
            </div>
            <div className="row">
              <div className="col col12">{viatorProductInfo.info.description}</div>
            </div>

            <div className="row mb0">
              <div className="col col12">
                <h2 className="pb8">{t("trips.myTrips.localExperiences.tourDetails.aboutThisActivity.title")}</h2>

                <div className="row mb2">
                  <div className="col col12 col2-m">
                    <h4>{t("trips.myTrips.localExperiences.tourDetails.aboutThisActivity.duration")}</h4>
                  </div>
                  <div className="col col12 col10-m">
                    <span>{duration}</span>
                  </div>
                </div>

                <div className="row mb2">
                  <div className="col col12 col2-m">
                    <h4>{t("trips.myTrips.localExperiences.tourDetails.aboutThisActivity.cancelation")}</h4>
                  </div>
                  <div className="col col12 col10-m">{viatorProductInfo.info.cancellationPolicy.description}</div>
                </div>
              </div>
            </div>
          </div>

          {forms(viatorProductInfo)}

          {/* <div className={classes.mainDiv}>
          <div className="center">
            <Button color="primary" className={classes.addToChartButton} onClick={() => window.open(viatorProductInfo.info.productUrl)} text="BOOK NOW" />
          </div>
        </div> */}

          <div className="row mb0 px-6">
            <div className="col col12">
              <h2 className="pb8 font-bold text-base">{t("trips.myTrips.localExperiences.tourDetails.experience.title")}</h2>
              {viatorProductInfo.info.inclusions && viatorProductInfo.info.inclusions.length > 0 && (
                <>
                  <div className="row mb2">
                    <div className="col col12 col2-m">
                      <h4>{t("trips.myTrips.localExperiences.tourDetails.experience.includes")}</h4>
                    </div>
                    <div className="col col12 col10-m">
                      {viatorProductInfo.info.inclusions.map((inclusion) => (
                        <div key={`inclusions-${Math.random()}`}>
                          <li>{inclusion.description || inclusion.otherDescription || inclusion.typeDescription}</li>
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="mb6" style={{ opacity: 0.2 }} />
                </>
              )}

              {viatorProductInfo.info.exclusions && viatorProductInfo.info.exclusions.length > 0 && (
                <>
                  <div className="row mb2">
                    <div className="col col12 col2-m">
                      <h4>{t("trips.myTrips.localExperiences.tourDetails.experience.excludes")}</h4>
                    </div>
                    <div className="col col12 col10-m">
                      {viatorProductInfo.info.exclusions.map((exclusion) => (
                        <div key={`exclusions-${Math.random()}`}>
                          <li>{exclusion.description || exclusion.otherDescription || exclusion.typeDescription}</li>
                          <br />
                        </div>
                      ))}
                    </div>
                  </div>
                  <hr className="mb6" style={{ opacity: 0.2 }} />
                </>
              )}

              {viatorProductInfo.info.additionalInfo && (
                <div className="row mb2">
                  <div className="col col12 col2-m">
                    <h4>{t("trips.myTrips.localExperiences.tourDetails.experience.additionalInformation")}</h4>
                  </div>
                  <div className="col col12 col10-m">
                    {viatorProductInfo.info.additionalInfo.map((additional_info) => (
                      <div key={`additional_information-${Math.random()}`}>
                        <li>{additional_info.description}</li>
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {showInfoModal && (
        <Modal show={showInfoModal} className="!min-w-[90%] !w-[90%] bg-background-color md:!min-w-[50%] md:!w-32 shadow-2xl">
          <div className="row m0 p-4">
            <ViatorBookingDetails
              items={viatorBookingInfo?.items}
              totalConfirmedPrice={viatorBookingInfo?.totalConfirmedPrice}
              voucherInfo={viatorBookingInfo?.voucherInfo}
              tourName={viatorProductInfo.info.title}
              tourImage={viatorProductInfo.info.images[0].variants[viatorProductInfo.info.images[0].variants.length - 1].url}
              travelHour={selectedHour}
              travelDate={availableDate}
              buttonText={t("trips.myTrips.localExperiences.tourDetails.close")}
              clicked={() => {
                setBookingInfoModal(false);
                if (previousRoute) {
                  if (previousRoute === TOURS_AND_TICKETS.PATH) {
                    history.push(
                      `${LOCAL_EXPERIENCES.PATH}?city_id=${cityId}&city_name=${cityName}&start_date=${startDate}&end_date=${endDate}&adult=${adults}${
                        children ? `&children=${children}` : ""
                      }`
                    );
                  } else {
                    history.push(previousRoute);
                  }
                } else {
                  history.push(TRIPS.PATH);
                }
              }}
              t={t}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ViatorTourInfo;
