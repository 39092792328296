// eslint-disable-next-line import/no-extraneous-dependencies
import Model from '@tripian/model';
import moment from 'moment';

class TDate {
  private tripianDates: Model.TripianDates;

  private startDate: moment.Moment = moment();

  private endDate: moment.Moment = moment();

  // private recurrent: (0 | 1 | 2 | 3 | 4 | 5 | 6)[] = [];
  private recurrent: number[] = [];

  // private blackouts: moment.Moment[] = [];
  private blackouts: string[] = [];

  private availableDays: string[] = [];

  constructor(tripianDates: Model.TripianDates) {
    this.tripianDates = tripianDates;
    this.set(tripianDates);
  }

  private calc = () => {
    if (this.endDate.isBefore(this.startDate)) return;

    const newAvailableDays = [];
    const cursorDate = this.startDate.clone();

    while (cursorDate.isSameOrBefore(this.endDate)) {
      if (this.recurrent.includes(cursorDate.day())) {
        const cursorDateString = cursorDate.format('YYYY-MM-DD');
        if (!this.blackouts.includes(cursorDateString)) {
          newAvailableDays.push(cursorDateString);
        }
      }
      cursorDate.add(1, 'days');
    }

    this.availableDays = newAvailableDays;
  };

  include = (dateString: string) => this.availableDays.includes(dateString);

  toList = () => this.availableDays;

  get = () => this.tripianDates;

  set = (tripianDates: Model.TripianDates) => {
    this.tripianDates = tripianDates;
    this.startDate = moment(tripianDates.startDate);
    this.endDate = moment(tripianDates.endDate);
    this.recurrent = tripianDates.recurrent;
    // this.blackouts = tripianDates.blackouts.map((x) => moment(x));
    this.blackouts = tripianDates.blackouts;
    this.calc();
  };
}

export default TDate;
