import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const Retail: React.FC<IIconSvg> = ({ fill, bgColor, size }) => (
  <Svg
    fill={fill}
    bgColor={bgColor}
    size={size}
    viewBox="0 0 40 40"
    path="M13.3271 29.9999C11.4938 29.9999 10.0105 31.4999 10.0105 33.3333C10.0105 35.1666 11.4938 36.6666 13.3271 36.6666C15.1605 36.6666 16.6605 35.1666 16.6605 33.3333C16.6605 31.4999 15.1605 29.9999 13.3271 29.9999ZM29.9938 29.9999C28.1605 29.9999 26.6771 31.4999 26.6771 33.3333C26.6771 35.1666 28.1605 36.6666 29.9938 36.6666C31.8271 36.6666 33.3271 35.1666 33.3271 33.3333C33.3271 31.4999 31.8271 29.9999 29.9938 29.9999ZM27.5771 21.6666C28.8271 21.6666 29.9271 20.9833 30.4938 19.9499L36.4605 9.13325C37.0771 8.03325 36.2771 6.66659 35.0105 6.66659H10.3438L8.77715 3.33325H3.32715V6.66659H6.66048L12.6605 19.3166L10.4105 23.3833C9.19381 25.6166 10.7938 28.3333 13.3271 28.3333H33.3271V24.9999H13.3271L15.1605 21.6666H27.5771ZM11.9271 9.99992H32.1771L27.5771 18.3333H15.8771L11.9271 9.99992Z"
  />
);

export default Retail;
