import React from 'react';
import IIconSvg from './IIconSvg';
import Svg from '../Svg';

const MustTry: React.FC<IIconSvg> = ({ fill, bgColor, size, className }) => (
  <Svg fill={fill} bgColor={bgColor} size={size} className={className} viewBox="0 0 24 24" path="">
    <mask id="mask0_1577_9" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
      <rect width="24" height="24" fill={fill} />
    </mask>
    <g mask="url(#mask0_1577_9)">
      <path
        d="M12 16C12.8 16 13.5125 15.7625 14.1375 15.2875C14.7625 14.8125 15.2167 14.2 15.5 13.45H8.5C8.78333 14.2 9.2375 14.8125 9.8625 15.2875C10.4875 15.7625 11.2 16 12 16ZM9.5 12C9.91667 12 10.2708 11.8542 10.5625 11.5625C10.8542 11.2708 11 10.9167 11 10.5C11 10.0833 10.8542 9.72917 10.5625 9.4375C10.2708 9.14583 9.91667 9 9.5 9C9.08333 9 8.72917 9.14583 8.4375 9.4375C8.14583 9.72917 8 10.0833 8 10.5C8 10.9167 8.14583 11.2708 8.4375 11.5625C8.72917 11.8542 9.08333 12 9.5 12ZM14.5 12C14.9167 12 15.2708 11.8542 15.5625 11.5625C15.8542 11.2708 16 10.9167 16 10.5C16 10.0833 15.8542 9.72917 15.5625 9.4375C15.2708 9.14583 14.9167 9 14.5 9C14.0833 9 13.7292 9.14583 13.4375 9.4375C13.1458 9.72917 13 10.0833 13 10.5C13 10.9167 13.1458 11.2708 13.4375 11.5625C13.7292 11.8542 14.0833 12 14.5 12ZM7.625 6.4L10.425 2.775C10.625 2.50833 10.8625 2.3125 11.1375 2.1875C11.4125 2.0625 11.7 2 12 2C12.3 2 12.5875 2.0625 12.8625 2.1875C13.1375 2.3125 13.375 2.50833 13.575 2.775L16.375 6.4L20.625 7.825C21.0583 7.95833 21.4 8.20417 21.65 8.5625C21.9 8.92083 22.025 9.31667 22.025 9.75C22.025 9.95 21.9958 10.15 21.9375 10.35C21.8792 10.55 21.7833 10.7417 21.65 10.925L18.9 14.825L19 18.925C19.0167 19.5083 18.825 20 18.425 20.4C18.025 20.8 17.5583 21 17.025 21C16.9917 21 16.8083 20.975 16.475 20.925L12 19.675L7.525 20.925C7.44167 20.9583 7.35 20.9792 7.25 20.9875C7.15 20.9958 7.05833 21 6.975 21C6.44167 21 5.975 20.8 5.575 20.4C5.175 20 4.98333 19.5083 5 18.925L5.1 14.8L2.375 10.925C2.24167 10.7417 2.14583 10.55 2.0875 10.35C2.02917 10.15 2 9.95 2 9.75C2 9.33333 2.12083 8.94583 2.3625 8.5875C2.60417 8.22917 2.94167 7.975 3.375 7.825L7.625 6.4ZM8.85 8.125L4 9.725L7.1 14.2L7 18.975L12 17.6L17 19L16.9 14.2L20 9.775L15.15 8.125L12 4L8.85 8.125Z"
        fill={fill}
      />
    </g>
  </Svg>
);

export default MustTry;
