// const tourIds = [1, 2, 221];
// const advantureNatureIds = [35, 41, 238, 36, 40, 255, 37, 39, 309, 43, 195, 49, 38, 308, 42, 310, 312, 307, 136];
// const cultureHistoryIds = [27, 33, 29, 34, 28, 180, 266, 229, 32, 31, 24, 527, 236, 272, 220, 160, 231, 477, 254, 301, 237, 242, 467, 294, 522];
// const cruiseSailingAndBoatToursIds = [48, 51, 258, 50, 53, 270, 264, 164, 363, 52];
// const foodAndDrinksIds = [103, 105, 104, 532, 248, 167, 107, 263, 204, 246, 261, 297, 305, 106, 108, 279, 351, 441, 353, 292, 289];
// const luxuryAndSpecialOccasionsIds = [287];

// export { tourIds, advantureNatureIds, cultureHistoryIds, cruiseSailingAndBoatToursIds, foodAndDrinksIds, luxuryAndSpecialOccasionsIds };

const uniqueTour = false;
const titleCategoryIds = [
  { title: 'Adventure', categoryIds: [35] },
  { title: 'Food', categoryIds: [103] },
  { title: 'Culture and History', categoryIds: [27] },
  { title: 'Sightseeing', categoryIds: [2] },
  { title: 'Art and Museums', categoryIds: [28] },
  { title: 'Local and Neighborhood', categoryIds: [21] },
];

export { uniqueTour, titleCategoryIds };
