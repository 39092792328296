import axiosLib, { AxiosInstance } from 'axios';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Providers } from '@tripian/model';
// import business_test_data from './data/business_test';
import { handleHttpResponseError } from '../../easy/handle/handle';
// import openings_test_data from './data/openings_test';
// import hold_test_data from './data/hold_test';
// import reservation_test_data from './data/reservation_test';

const yelpLangMapping: { [key: string]: string } = {
  en: 'en_US',
  fr: 'fr_FR',
  es: 'es_ES',
  pt: 'pt_PT',
  de: 'de_DE',
  nl: 'nl_NL',
};

function getYelpLangCode(appLangCode: string): string {
  return yelpLangMapping[appLangCode] || 'en_US';
}

class API {
  private axios: AxiosInstance;

  private proxyURL: string;

  private apiUrl: string;

  private lang: string;

  // private sandboxToken = 'lKSyNooZ4m6EnK7530z9Enx2GAuym6UJxCwLVv82pjhB67LU_l89iQtfj-5pMasL7kt4AnjF_oW_gHAXiz84IQXcMLJVNFhc2aMRyd9YUAb3zv0K63voptIgbItlXXYx';
  // private prodToken = 'SyqU9E_sGpBMUoViM6DBQkpLpRu5sCEvlqxs0-xAuTREuDoiIjf1TsPC-0PoWeK6O2_TSaDOdCoLMeoj5khI16DDMLqhvHSsFeTi9UHWwtTsu5kZBNOiHkBGxnVmXXYx';

  private sandbox: boolean;

  constructor(apiUrl: string, apiToken: string, sandbox: boolean, proxyURL: string, lang: string) {
    this.proxyURL = proxyURL;
    this.apiUrl = apiUrl;
    this.sandbox = sandbox;

    this.axios = axiosLib.create();
    this.axios.defaults.baseURL = `${this.proxyURL}?url=${apiUrl}`;
    this.axios.defaults.headers.common.authorization = `Bearer ${apiToken}`;
    // this.axios.defaults.headers.common.origin = 'tripian.com';
    this.axios.defaults.timeout = 30000; // 2500;
    this.lang = lang;
  }

  private urlBuilder = (endpoint: string, queries: { [key: string]: any }): string => {
    const base = `${this.proxyURL}?url=`;
    let url = `${this.apiUrl}${endpoint}`;

    Object.entries(queries).forEach(([key, value], index) => {
      url += index === 0 ? '?' : '&';
      url += `${key}=${value}`;
    });

    const base64url = btoa(url);
    return base + base64url;
  };

  business = async (id: string): Promise<Providers.Yelp.Business> => {
    // if (this.sandbox) return Promise.resolve(business_test_data);
    const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : id; // sandbox business error
    const url = this.urlBuilder(`/businesses/${businessId}`, {});
    return this.axios
      .get<Providers.Yelp.Business>(url, {
        headers: {
          'Accept-Language': getYelpLangCode(this.lang),
        },
      })
      .then((businessResponse) => businessResponse.data)
      .catch((errorResponse) => handleHttpResponseError(errorResponse, 'yelp-business', id));
  };

  openings = async (openings: Providers.Yelp.OpeningsRequest): Promise<Providers.Yelp.Openings> => {
    const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : openings.businessId;
    const url = this.urlBuilder(`/bookings/${businessId}/openings`, { date: openings.date, time: openings.time, covers: openings.covers });
    return this.axios
      .get<Providers.Yelp.Openings>(url, {
        headers: {
          'Accept-Language': getYelpLangCode(this.lang),
        },
      })
      .then((responseData) => responseData.data)
      .catch((errorResponse) => handleHttpResponseError(errorResponse, 'yelp-opening', openings));
  };

  hold = async (hold: Providers.Yelp.HoldRequest): Promise<Providers.Yelp.Hold> => {
    const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : hold.businessId;
    const url = `${this.proxyURL}?url=${btoa(`${this.apiUrl}/bookings/${businessId}/holds`)}`;

    const data = new URLSearchParams({
      covers: hold.covers.toString(),
      date: hold.date,
      time: hold.time,
      unique_id: hold.uniqueId,
    });

    return this.axios
      .post<Providers.Yelp.Hold>(url, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': getYelpLangCode(this.lang), // Assuming this function exists
        },
      })
      .then((responseData) => {
        return responseData.data;
      })
      .catch((errorResponse) => {
        return handleHttpResponseError(errorResponse, 'yelp-hold', hold);
      });
  };

  reservationStatus = async (reservationId: string): Promise<Providers.Yelp.ReservationStatusResponse> => {
    // const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : hold.businessId;
    return this.axios
      .get<Providers.Yelp.ReservationStatusResponse>(`/bookings/reservation/${reservationId}/status`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': getYelpLangCode(this.lang) },
      })
      .then((responseData) => responseData.data)
      .catch((errorResponse) => {
        if (
          errorResponse.response &&
          errorResponse.response?.data &&
          errorResponse.response?.data?.error &&
          errorResponse.response?.data?.error?.code === 'RESERVATION_CANCELED'
        ) {
          return {
            active: false,
            covers: 0,
            date: '',
            time: '',
          };
        }
        return handleHttpResponseError(errorResponse, 'yelp-reservationStatus', reservationId);
      });
  };

  reservationCancel = async (reservationId: string): Promise<any> => {
    // const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : hold.businessId;
    return this.axios
      .post<Providers.Yelp.ReservationStatusResponse>(`/bookings/reservation/${reservationId}/cancel`, {
        headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': getYelpLangCode(this.lang) },
      })
      .then((responseData) => responseData.data)
      .catch((errorResponse) => handleHttpResponseError(errorResponse, 'yelp-reservationCancel', reservationId));
  };

  reservation = async (reservation: Providers.Yelp.ReservationRequest): Promise<Providers.Yelp.Reservation> => {
    const businessId = this.sandbox ? 'rC5mIHMNF5C1Jtpb2obSkA' : reservation.businessId;
    const url = `${this.proxyURL}?url=${btoa(`${this.apiUrl}/bookings/${businessId}/reservations`)}`;

    const data = new URLSearchParams({
      time: reservation.time,
      date: reservation.date,
      covers: reservation.covers.toString(),
      first_name: reservation.firstName,
      last_name: reservation.lastName,
      phone: reservation.phone,
      email: reservation.email,
      unique_id: reservation.uniqueId,
      hold_id: reservation.holdId,
    });

    return this.axios
      .post<Providers.Yelp.Reservation>(url, data, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Accept-Language': getYelpLangCode(this.lang),
        },
      })
      .then((responseData) => responseData.data)
      .catch((errorResponse) => handleHttpResponseError(errorResponse, 'yelp-reservation', reservation));
  };

  /* testBusiness = () => this.business('gR9DTbKCvezQlqvD7_FzPw');

  testOpening = () => this.opening('rC5mIHMNF5C1Jtpb2obSkA', '2020-06-30', '12:00', 4);

  testHold = () => this.hold('rC5mIHMNF5C1Jtpb2obSkA', '2020-06-30', '12:00', 4, 'Umut Bey');

  testReservation = () =>
    this.reservation(
      'rC5mIHMNF5C1Jtpb2obSkA',
      '2020-06-30',
      '10:00',
      4,
      'Umut Bey',
      'gg5R0O7N-etXlyQrpTWLxg',
      'Cenan',
      'Yunusoglu',
      'cenan@tripian.com',
      '+1-555-66-777',
    );
    
  fakeBusiness = () => new Promise((resolve) => setTimeout(() => resolve(business_test_data), 1000));

  fakeOpenings = () => new Promise((resolve) => setTimeout(() => resolve(openings_test_data), 1000));

  fakeHold = () => new Promise((resolve) => setTimeout(() => resolve(hold_test_data), 1000));

  fakeReservation = () => new Promise((resolve) => setTimeout(() => resolve(reservation_test_data), 1000)); */
}

export default API;
