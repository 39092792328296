import React from 'react';
import Svg from '../Svg';
import IIconSvg from './IIconSvg';

const ArrowUp: React.FC<IIconSvg> = ({ fill, bgColor, size = '16px', className }) => (
  <Svg className={className} fill={fill} color={bgColor} size={size} path="" viewBox="0 0 18 18">
    <path d="M3.885 7.17746C3.885 7.03496 3.9375 6.89246 4.05 6.77996L8.6025 2.22746C8.82 2.00996 9.18 2.00996 9.3975 2.22746L13.95 6.77996C14.1675 6.99746 14.1675 7.35746 13.95 7.57496C13.7325 7.79246 13.3725 7.79246 13.155 7.57496L9 3.41996L4.845 7.57496C4.6275 7.79246 4.2675 7.79246 4.05 7.57496C3.9375 7.46996 3.885 7.31996 3.885 7.17746Z" />
    <path d="M8.4375 15.3749L8.4375 2.75244C8.4375 2.44494 8.6925 2.18994 9 2.18994C9.3075 2.18994 9.5625 2.44494 9.5625 2.75244L9.5625 15.3749C9.5625 15.6824 9.3075 15.9374 9 15.9374C8.6925 15.9374 8.4375 15.6824 8.4375 15.3749Z" />
  </Svg>
);

export default ArrowUp;
